import './ClickerHeader.scss';
import { app } from '../../../../data/AppController';
import { TeamButton } from './TeamButton/TeamButton';
import { League } from '../../../../replicant/features/game/ruleset/league';

export const ClickerHeader = () => {
  const season1League = app.state.seasonLeagues[0] as League;

  return (
    <div className="clicker-header">
      <TeamButton fullWidth={!season1League} />
      {season1League && (
        <div
          className="btn button-s1"
          onClick={() => app.ui.drawer.show({ id: 'drawerS1' })}
        />
      )}
    </div>
  );
};
