import { AppController } from '../AppController';
import { EventListener } from '../EventListener';
import IAPs from '../../replicant/features/powerups/airtable/offchainTradingIAP';
import { IAPConfig } from '../../replicant/features/offchainTrading/types';
import { getRemoteVersion } from '../api';
import { SEC_IN_MS } from '../../replicant/utils/time';

const iap: Record<string, IAPConfig> = IAPs.reduce((res, cur) => {
  return {
    ...res,
    [cur.ab_test]: cur,
  };
}, {} as Record<string, IAPConfig>);

const CHECK_TIME_LIMIT = 20 * SEC_IN_MS;

const MAX_VERSION_UPDATE_RETRY = 3;

const PersistentSessionKey = 'PersistentSessionKey';

const initialPersistentState = {
  versionUpdateRetryCount: 0,
};
type PersistantState = typeof initialPersistentState;

export enum SessionEvents {
  OnUpdate = 'OnUpdate',
}
/**
 * A place to store and manipulate session data
 */
interface SessionState {
  questsChecked: {
    timestamp: number;
    questId: string;
  }[];
  questCheckComplete: string[];
  isOutdated: boolean;
}

export class SessionController extends EventListener {
  private _state: SessionState = {
    questsChecked: [],
    questCheckComplete: [],
    isOutdated: false,
  };

  private sessionStartTimestamp: number;

  private persistentState: PersistantState = localStorage.getItem(
    PersistentSessionKey,
  )
    ? JSON.parse(localStorage.getItem(PersistentSessionKey)!)
    : initialPersistentState;

  get state() {
    return this._state;
  }

  constructor(private app: AppController) {
    super();
    this.sessionStartTimestamp = Date.now();
  }

  private updatePersistantState = (update: Partial<PersistantState>) => {
    this.persistentState = {
      ...this.persistentState,
      ...update,
    };
    localStorage.setItem(
      PersistentSessionKey,
      JSON.stringify(this.persistentState),
    );
  };

  updateState = (update: Partial<SessionState>) => {
    this._state = {
      ...this._state,
      ...update,
    };
    this.sendEvents(SessionEvents.OnUpdate);
  };

  // Quest
  isQuestVerifying = (questId: string) => {
    return this._state.questsChecked.find((q) => q.questId === questId);
  };

  hasQuestBeenChecked = (questId: string) => {
    return this._state.questCheckComplete.includes(questId);
  };

  onQuestChecked = (questId: string) => {
    const isNotCheckedYet = !this.hasQuestBeenChecked(questId);
    if (isNotCheckedYet) {
      this._state.questsChecked.push({
        questId,
        timestamp: Date.now(),
      });
      setTimeout(() => {
        this.verifyQuestsCheckComplete();
      }, CHECK_TIME_LIMIT);
      this.sendEvents(SessionEvents.OnUpdate);
    }
  };

  onQuestRewarded = (questId: string) => {
    if (this.app.state.quests[questId].state === 'complete') {
      this._state.questCheckComplete = this._state.questCheckComplete.filter(
        (q) => q !== questId,
      );
    }
  };

  private verifyQuestsCheckComplete = async () => {
    for (const q of this._state.questsChecked) {
      const timeDiff = Date.now() - q.timestamp;
      const isReady = timeDiff >= CHECK_TIME_LIMIT;
      if (isReady) {
        this.app.invoke.updateQuest({ questId: q.questId });
        this._state.questCheckComplete.push(q.questId);
      }
    }

    this._state.questsChecked = this._state.questsChecked.filter(
      (q) => !this._state.questCheckComplete.includes(q.questId),
    );
    this.sendEvents(SessionEvents.OnUpdate);
  };

  // IAP
  getIAPConfig = () => {
    const resolvedBucketId = 'sees_offchainTrading_5_dollar';
    const cfg = iap[resolvedBucketId];
    if (!cfg) {
      return undefined;
    }

    return {
      ...cfg,
      productId: this.app.getIAPId(cfg),
    };
  };

  checkForUpdates = async () => {
    const localVersion = process.env.REACT_APP_APP_VERSION;
    const remoteVersion = await getRemoteVersion();
    const needsUpdate = localVersion !== remoteVersion;

    if (
      this.persistentState.versionUpdateRetryCount >= MAX_VERSION_UPDATE_RETRY
    ) {
      this.updateState({ isOutdated: true });
      // @TODO: Should we show some UI or something? can we do anything?
      // For now just ignore and let the user play on outdated version
      return;
    }

    if (needsUpdate) {
      this.updatePersistantState({
        versionUpdateRetryCount:
          (this.persistentState.versionUpdateRetryCount += 1),
      });
      // @ts-ignore (param 'true' is only accepted in some browsers)
      return location.reload(true);
    } else {
      this.updatePersistantState({
        versionUpdateRetryCount: 0,
      });
    }
  };
}
