import { app } from '../../../../data/AppController';
import { MemeFilters } from '../../../../data/Controllers/Memes/types';
import { FeedItemFilterButton } from '../TiktokFeedItem/FeedItemFilterBtn';
import { getTMGFarmingIsShowing } from '../../../../replicant/features/offchainTrading/offchainTrading.getters';

interface TiktokTabsProps {
  selectedFilter: MemeFilters;
  onTapFilter: (cat: MemeFilters) => Promise<void>;
}

export const TiktokFilterPicker = ({
  selectedFilter,
  onTapFilter,
}: TiktokTabsProps) => {
  const isShowingFarming = getTMGFarmingIsShowing(app.state);
  // profile tabs mode (Created / Owned)
  if (app.memes.myMemes.getIsMyFilter(selectedFilter)) {
    return (
      <div className="feed-item-ui-categories">
        <FeedItemFilterButton
          filter={'Created'}
          selectedFilter={selectedFilter}
          onTap={onTapFilter}
        />
        <FeedItemFilterButton
          filter={'Owned'}
          selectedFilter={selectedFilter}
          onTap={onTapFilter}
        />
        {app.profile.current?.isSelf && isShowingFarming && (
          <FeedItemFilterButton
            filter={'Farming'}
            selectedFilter={selectedFilter}
            onTap={onTapFilter}
          />
        )}
      </div>
    );
  }

  // root tabs mode (Hot / Top / New)
  return (
    <div className="feed-item-ui-categories">
      <FeedItemFilterButton
        filter={'Hot'}
        selectedFilter={selectedFilter}
        onTap={onTapFilter}
      />
      <FeedItemFilterButton
        filter={'Top'}
        selectedFilter={selectedFilter}
        onTap={onTapFilter}
      />
      <FeedItemFilterButton
        filter={'New'}
        selectedFilter={selectedFilter}
        onTap={onTapFilter}
      />
    </div>
  );
};
