import { SB } from '@play-co/replicant';
import {
  createScheduledActions,
  extractScheduledActionHandlers,
} from '../../createScheduledActions';
import { incrementScore } from '../game/game.modifiers';
import {
  addFriend,
  createFriendsSubState,
  migrateFriendIdsToSubstate,
  scheduleFriendJoinedMessage,
} from './friends.modifiers';
import { getFriendsSubStateId } from './friends.getters';
import { tmgInitState } from '../offchainTrading/offchainTrading.modifiers';
import { fixFriendSubState } from '../game/migration.modifiers';

const schema = {
  addFriend: SB.object({
    friendId: SB.string(),
    friendName: SB.string(),
    bonus: SB.int(),
    referredTokenId: SB.string().optional(),
  }),
};

const actions = createScheduledActions(schema)({
  addFriend: async (
    state,
    { friendId, friendName, bonus, referredTokenId },
    api,
  ) => {
    const friendsSubStateId = getFriendsSubStateId(state);

    let friendIds: string[] | undefined;

    // this is to ensure the player's friend state is correctly initialized
    await fixFriendSubState(state, api);

    if (state.friendIds) {
      friendIds = state.friendIds;

      await migrateFriendIdsToSubstate(state, api, true);
    } else {
      const friendsSubState = await api.sharedStates.friends.fetch(
        friendsSubStateId,
      );

      if (friendsSubState) {
        friendIds = friendsSubState?.global.friendIds;

        // make sure the friendsSubStateId is set along with friendCount
        state.friendsSubStateId = friendsSubStateId;
        state.friendCount = friendsSubState.global.friendIds.length;
      } else {
        await createFriendsSubState(state, api);
        friendIds = [];
      }
    }

    if (friendIds.includes(friendId)) {
      // friend already present
      return;
    }

    if (referredTokenId) {
      tmgInitState(state, referredTokenId);
      // Grant the referrer a ticket for the referred token
      state.trading.miniGames.state[referredTokenId].tickets += 1;
    }

    addFriend(state, friendId, api);

    incrementScore(state, bonus);

    scheduleFriendJoinedMessage(state, api, friendName, bonus);
  },
});

export const friendsScheduledActions = {
  schema,
  actions: extractScheduledActionHandlers(actions),
};
