import { TradingSearchResult } from '../../../replicant/features/offchainTrading/offchainTrading.properties';
import { Optional } from '../../types';
import { MemeList } from './MemeList';

export class UserMemeList extends MemeList {
  override search = async (searchString: string) => {
    const tokens = this.list.filter((t) => {
      const l = this.getItem(t.id);
      return (
        l.name.includes(searchString) || l.creatorName.includes(searchString)
      );
    });

    this.updateListing(tokens, true);
  };

  override paginate = async () => {
    // there's no pagination; We always fetch all tokens (maybe one day we will need)
  };

  override requestUpdate = async (refresh: Optional<'refresh'> = undefined) => {
    if (refresh) {
      await this.requestListing(Boolean(refresh));
    }
  };

  private getMyTokens = async (): Promise<TradingSearchResult[]> => {
    await this.app.profile.isReady;

    if (!this.app.profile.current) {
      return [];
    }

    const key =
      this.filter === 'Owned'
        ? 'tokensOwned'
        : this.filter === 'Created'
        ? 'tokensCreated'
        : this.filter === 'Farming'
        ? 'tokensFarming'
        : undefined;

    if (!key) {
      return [];
    }

    return this.app.profile.current[key];
  };

  override requestListing = async (reset = false) => {
    const myTokens = await this.getMyTokens();

    const ids = myTokens.map((t) => t.id);

    const tokens =
      ids.length > 0
        ? await this.app.asyncGetters.getOffchainTokensFromOpenSearch({
            offchainTokenIds: ids,
          })
        : [];

    return this.updateListing(tokens, reset);
  };
}
