import { assets } from '../../assets/assets';
import './Tooltip.scss';
import { app, useAppUpdates } from '../../data/AppController';
import { Animated } from '../shared/Animated/Animated';
import { CSSProperties } from 'react';

const d = '6px';

const alignExtraStyle = {
  top: {
    transform: 'rotate(180deg)',
    top: `calc(-100% - ${d}`,
    left: 'initial',
  },
  bottom: {
    transform: 'rotate(0deg)',
    top: `calc(100% + ${d}`,
    left: 'initial',
  },
  right: {
    transform: 'rotate(270deg) scaleX(-1)',
    top: 'initial',
    left: `calc(100% + ${d}`,
  },
  left: {
    transform: 'rotate(90deg)',
    top: 'initial',
    left: `calc(-32px - ${d}`,
  },
};

const ensureCss = (value: string | undefined) =>
  Number.isNaN(Number(value)) ? value : `${value}px`;

const getCalc = (origin: string | undefined, offset: string | undefined) => {
  return `calc(${ensureCss(origin)} + ${ensureCss(offset)})`;
};

export const Tooltip = () => {
  useAppUpdates({
    id: 'Popup',
    listener: app.ui.tooltip.attachEventListener(),
  });

  const { visible, data } = app.ui.tooltip;

  const finalHandStyle: CSSProperties = {
    ...alignExtraStyle[data?.align || 'top'],
  };

  return (
    <Animated
      anim={{ in: 'fade-in', out: 'fade-out' }}
      visible={visible}
      clickthrough
    >
      <div className="tooltip">
        <div
          style={{
            position: 'absolute',
            top: getCalc(data?.origin.y, data?.offset.y),
            left: getCalc(data?.origin.x, data?.offset.x),
          }}
        >
          <div
            style={{
              transform: 'translate(-50%, -50%)',
              pointerEvents: 'auto',
            }}
          >
            <div className="tooltip-hand" style={finalHandStyle}>
              <img className="hand-move" src={assets.tutorial_hand} />
            </div>
            <div className="tooltip-box">
              <div> {data?.text}</div>
            </div>
          </div>
        </div>
      </div>
    </Animated>
  );
};
