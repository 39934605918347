import { BADGE_VALUES } from '../../Controllers/UIController/UIController';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class MineCardFriends extends TutorialModule {
  id = 'MineCardFriends' as 'MineCardFriends';

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });
    this.app.nav.goTo('MinePage');

    this.app.ui.popup.show({
      title: t('tut_popup_minecard_title'), // 'Earn with your friends',
      description: t('tut_popup_minecard_description'), // 'Want to earn even more? Let’s learn about the power of friendship.',
      cta: t('tut_popup_minecard_cta'), // 'Check it out',
      onClick: () => {
        this.onComplete();
      },
    });

    return super.execute().then(() => {
      this.app.ui.popup.close();
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_mine_friends_prompt_dialog',
    };
  }
}
