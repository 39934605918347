import { analytics, AnalyticsProperties } from '@play-co/gcinstant';
import { PayloadProperties } from './analytics/properties';
import { app, gcinstant, payloadEncoder, Route } from './AppController';
import {
  getMiniAppName,
  getReferralUrl,
} from '../replicant/features/chatbot/chatbot.private';
import { getSourceRealtimeStats } from '../replicant/features/game/game.getters';
import { tests } from '../replicant/ruleset';
import config from '../replicant/features/game/game.config';
import { getDayMidnightInUTC } from '../replicant/utils/time';
import { isIOS } from './device';
import { t } from 'i18next';

function getInviteMessage() {
  return t('friendstab_invite_text');
}

function makePayload(payload: {
  [key: string]: unknown;
  $channel: AnalyticsProperties.Channel;
  feature: string;
  $subFeature?: string;
  payload?: Record<string, any>;
}): PayloadProperties {
  const sourceRealtimeData = getSourceRealtimeStats(app.state, app.now());
  return {
    $realtimeIsPurchaser: false,
    $realtimeLTV: 0,
    ...payload,
    payload: {
      ...sourceRealtimeData,
      ...payload?.payload,
    },
    ...gcinstant.getPlatformPayloadData(),
  };
}

// TODO move into GCInstant
export async function invite(args: {
  screen_location: string;
  url?: string;
  data: {
    feature: string;
    $subFeature: string;
    originFeature?: string;
    originSubFeature?: string;
  };
}) {
  const appName = getMiniAppName(app.replicant.state);
  const payload = makePayload({
    ...args.data,
    $channel: 'INVITE',
    url: appName,
  });

  analytics.pushEvent('TrackInviteAsync', payload);

  const { $key: encodedPayloadKey } = payloadEncoder.encode(payload);

  if (!encodedPayloadKey) {
    throw Error('Missing $key property in encoded payload');
  }

  const isSharingToTelegram = !isIOS();
  const inviteText = getInviteMessage();

  // TG share api
  if (isSharingToTelegram) {
    const shareUrl = args.url ?? app.getShareUrl(encodedPayloadKey, inviteText);
    // At this point the web app closes and we can't tell if the invite was a success or not
    // GCInstant natively uses `postEvent` instead of `openTelegramLink`
    Telegram.WebApp.openTelegramLink(shareUrl);
    return;
  }

  // ios share api
  // use native navigator.share feature
  const referralUrl = getReferralUrl(encodedPayloadKey, app.state);
  try {
    await navigator.share({
      url: referralUrl,
      title: t('friendstab_invite_title'),
      text: inviteText,
    });
  } catch (error) {
    if ((error as DOMException).name === 'AbortError') {
      return;
    }

    console.log({ referralUrl });
    console.error(
      '>>> invite - cannot use navigator.share',
      (error as DOMException).message,
    );
  }
}

export function generateInviteUrlWithPayload(args: {
  screen_location: string;
  data: {
    feature: string;
    $subFeature: string;
  };
}) {
  const appName = getMiniAppName(app.state);
  const payload = makePayload({
    screen_location: args.screen_location,
    ...args.data,
    $channel: 'LINK',
    url: appName,
  });
  analytics.pushEvent('TrackInviteCopyAsync', payload);
  const { $key: encodedPayloadKey } = payloadEncoder.encode(payload);
  const url = getReferralUrl(encodedPayloadKey, app.state);

  // return `${url}\n\n${getInviteMessage()}`;

  return url;
}

export async function sendMineCardGift({
  card,
  originFeature,
  originSubFeature,
}: {
  card: string;
  originFeature?: string;
  originSubFeature?: string;
}) {
  const payload = makePayload({
    feature: 'mine',
    $subFeature: 'gift',
    $channel: 'INVITE',
    card,
  });

  analytics.pushEvent('TrackMineGiftAsync', {
    ...payload,
    card,
    gift_name: card,
    originFeature,
    originSubFeature,
  });

  const { $key: encodedPayloadKey } = payloadEncoder.encode({
    ...payload,
    payload: {
      ...(payload as any).payload,
      card,
      createdAt: getDayMidnightInUTC(app.now()),
    },
  });

  // console.log({encodedPayloadKey});

  if (!encodedPayloadKey) {
    throw Error('Missing $key property in encoded payload');
  }

  const shareUrl = app.getMineGiftUrl(encodedPayloadKey, card);

  // At this point the web app closes and we can't tell if the invite was a success or not
  // GCInstant natively uses `postEvent` instead of `openTelegramLink`
  Telegram.WebApp.openTelegramLink(shareUrl);
}

export function generatePromoLink(card: string, origin: string = 'default') {
  const payload = makePayload({
    feature: 'mine',
    $subFeature: 'promo',
    $channel: 'LINK',
    card: card,
    origin: origin,
  });

  const { $key: encodedPayloadKey } = payloadEncoder.encode({
    ...payload,
    payload: { card },
  });

  return `https://t.me/${config.botName}/tap?startapp=${encodedPayloadKey}`;
}

// Returns share url
export async function shareDeeplink(
  route: Route,
  {
    messageOpts,
    deeplinkOpts,
    skipShare,
  }: {
    messageOpts?: { title: string; text: string };
    deeplinkOpts?: Record<string, any>;
    skipShare?: boolean;
  },
) {
  const payload = makePayload({
    feature: 'deeplink',
    $subFeature: route,
    $channel: 'LINK',
  });

  const customPayload = {
    dlRoute: route,
    dlOpts: deeplinkOpts,
  };

  const { $key: encodedPayloadKey } = payloadEncoder.encode({
    ...payload,
    payload: {
      ...(payload as any).payload,
      ...customPayload,
    },
  });

  let shareURL = getReferralUrl(encodedPayloadKey);

  if (skipShare) {
    return shareURL;
  }

  const isSharingToTelegram = !isIOS();

  // navigator.share only works in ios webview. android webview is broken for this feature
  // so default to telegram share for both desktop and android devices
  if (isSharingToTelegram) {
    const url = encodeURIComponent(shareURL);

    if (messageOpts) {
      const text = encodeURIComponent(
        `${messageOpts.title}\n\n${messageOpts.text}`,
      );
      shareURL = `https://t.me/share/url?url=${url}&text=${text}`;
      // console.warn('>>>', messageOpts, text, shareURL);
    } else {
      shareURL = `https://t.me/share/url?url=${url}`;
    }

    Telegram.WebApp.openTelegramLink(shareURL);
    return;
  }

  // otherwise, use native navigator.share feature
  try {
    await navigator.share({
      url: shareURL,
      title: messageOpts?.title,
      text: messageOpts?.text,
    });
  } catch (error) {
    if ((error as DOMException).name === 'AbortError') {
      return;
    }

    console.log({ shareURL });
    console.error(
      '>>> shareDeeplink - cannot use navigator.share',
      (error as DOMException).message,
    );
  }
}
