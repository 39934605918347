import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UIController';
import { waitFor } from '../../utils';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class EarnPage extends TutorialModule {
  id = 'EarnPage' as 'EarnPage';

  private timeout?: NodeJS.Timeout;

  onBack = () => {
    this.onComplete();
  };

  onAction = (id?: string) => {
    if (id === 'clear-tooltip') {
      this.app.ui.tooltip.hide();
    } else {
      clearInterval(this.timeout);
      this.timeout = this.addBackTooltip();
    }
  };

  execute = async () => {
    this.app.ui.setEarnUIState({
      questButtonsNonTutorial: ElementUIState.Disabled,
    });

    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });

    // navigate to earn page
    this.app.nav.goTo('EarnPage');

    await waitFor(300);

    this.app.tutorial.showTooltip({
      text: t('tut_tooltip_earntapquest'),
      origin: { x: '50%', y: '0%' },
      offset: { x: '0px', y: '450px' },
      align: 'top',
    });

    return super.execute().then(() => {
      this.app.ui.setEarnUIState({
        questButtonsNonTutorial: ElementUIState.Normal,
      });
      clearInterval(this.timeout);
      this.app.ui.popup.close();
      this.app.ui.tooltip.hide();
      this.app.nav.goToHomePage();
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_earn_intro_view_dialog',
    };
  }
}
