import './ClickerFooter.scss';
import { assets } from '../../../../assets/assets';
import { useAppUpdates, app, AppEvents } from '../../../../data/AppController';
import {
  ElementUIState,
  UIEvents,
} from '../../../../data/Controllers/UIController/UIController';
import { TutorialEvents } from '../../../../data/tutorial/TutorialController';
import { getUIStateClassName, isTiktokEnabled } from '../../../../data/utils';
import { FooterButton } from './FooterButton';
import { useTranslation } from 'react-i18next';
import { isAndroid, isIOS, isTelegramWebview } from '../../../../data/device';
import { getTMGFarmingIsShowing } from '../../../../replicant/features/offchainTrading/offchainTrading.getters';
import { Tutorials } from '../../../../data/tutorial/types';

export const ClickerFooter = () => {
  useAppUpdates({
    id: 'ClickerFooter/Tutorial',
    listener: app.tutorial.attachEventListener(TutorialEvents.onUpdate),
  });
  useAppUpdates({
    id: 'ClickerFooter/UI',
    listener: app.ui.attachEventListener(UIEvents.ClickerUpdate),
  });

  const { t } = useTranslation();

  const { views: components } = app;

  const { energyLimit, energy } = app.ui;

  const pct = `${(100 * energy) / energyLimit}%`;

  const onFriendsClick = () => {
    app.tutorial.step?.onAction && app.tutorial.step?.onAction('friends');
    app.nav.goTo('Friends');
  };

  const onMineClick = () => {
    app.tutorial.step?.onAction && app.tutorial.step?.onAction('mine');
    app.nav.goTo('MinePage');
    // @TODO: Not sure if this needs to be called here (think tutorial module should take care of it on execure resolve or onAction)
    app.ui.tooltip.hide();
  };

  const onEarnClick = async () => {
    app.tutorial.step?.onAction && app.tutorial.step?.onAction('earn');
    app.nav.goTo('EarnPage');
  };

  const onTradingClick = async () => {
    app.tutorial.step?.onAction && app.tutorial.step?.onAction('trading');

    // start tiktok tutorial slideshow
    if (isTiktokEnabled()) {
      const isShowingFarming = getTMGFarmingIsShowing(app.state);
      const slideShowTutorial = isShowingFarming
        ? Tutorials.SlideshowTiktok
        : Tutorials.SlideshowTiktok2;
      await app.tutorial.startTutorial(slideShowTutorial);
    }

    // navigate to either TradingPage or tiktok feed depending on tiktok abtest
    if (isTiktokEnabled()) {
      app.nav.goToTiktokFeed();
    } else {
      app.nav.goTo('TradingPage');
    }
  };

  const onBoosterClick = () => {
    app.tutorial.step?.onAction && app.tutorial.step?.onAction('booster');
    app.nav.goTo('Shop');
    // @TODO: Not sure if this needs to be called here (think tutorial module should take care of it on execure resolve or onAction)
    app.ui.tooltip.hide();
  };

  return (
    <footer>
      {/* footer progress */}

      <div className="progress-area">
        <div
          className={`progress-info ${getUIStateClassName(
            app.ui.state.clicker.energyGauge,
          )}`}
        >
          {/* top energy counter */}
          <div className="progress-info-top">
            <div className="progress-info-icon">
              <img src={assets.energy_white} />
            </div>
            <div className="progress-info-label">
              {/* <span className="p-1">Energy </span> */}
              <span className="p-2">{energy}</span>
              <span className="p-1"> / </span>
              <span className="p-3">{energyLimit}</span>
            </div>
          </div>
          {/* progress bar */}
          <div className="progress-bar">
            <div className="progress-bar-bg"></div>
            <div className="progress-bar-value" style={{ width: pct }}></div>
          </div>
        </div>
        {/* boosts button */}
        <FooterButton
          type="booster"
          onClick={onBoosterClick}
          uiState={app.ui.state.clicker.btnBooster}
          label="clicker_footer_boosts"
          badges={app.ui.badges.boosters}
        />
      </div>
      {/* footer buttons */}
      <div
        className={`clicker-footer-buttons 
          ${isTelegramWebview() && isIOS() ? 'telegram-ios' : ''}
          ${isTelegramWebview() && isAndroid() ? 'telegram-android' : ''}
         ${getUIStateClassName(app.ui.state.clicker.footer)}`}
      >
        <div className="clicker-footer-buttons-centered-container">
          <FooterButton
            type="friends"
            uiState={app.ui.state.clicker.btnFriends}
            onClick={onFriendsClick}
            label="clicker_footer_friends"
            badges={app.ui.badges.friends}
          />
          <FooterButton
            type="mine"
            uiState={app.ui.state.clicker.btnMine}
            onClick={onMineClick}
            label="collect_button"
            badges={app.ui.badges.mine}
          />
          <FooterButton
            type="earn"
            uiState={app.ui.state.clicker.btnEarn}
            onClick={onEarnClick}
            label="clicker_footer_earn"
            badges={app.ui.badges.earn}
          />
          <FooterButton
            type="trading"
            uiState={app.ui.state.clicker.btnEarn}
            onClick={onTradingClick}
            label="clicker_footer_trading"
            badges={app.ui.badges.trading}
          />
        </div>
      </div>
    </footer>
  );
};
