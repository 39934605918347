import '../../TradingTokenPage/TradingTokenFooter/TradingTokenFooter.scss';
import { app } from '../../../../data/AppController';
import { Currency } from '../../../../replicant/features/offchainTrading/types';
import { TradingTokenFooterContent } from '../../TradingTokenPage/TradingTokenFooter/TradingTokenFooter';

// Note:
// This drawer is used exclusively from TiktokPage, but references components and css classes from TradingTokenPage/TradingTokenFooter
// TradingTokenPage we use a baked in component

export const DrawerTiktokBuySell = () => {
  const onTabClick = (tab: Currency) => {};

  return (
    <TradingTokenFooterContent
      onTabClick={onTabClick}
      onMinimize={() => app.ui.drawer.close()}
    />
  );
};
