import './DrawerGenericOutcome.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/AppController';
import { useEffect } from 'react';

export const DrawerGenericSuccess = () => {
  const { t } = useTranslation();

  const opts = app.ui.drawer.view.data?.opts;

  // get error message
  const reward = opts?.subtitle ?? '0 Pts';
  const title = opts?.title ?? 'Success';
  const btn = opts?.buttons?.[0];
  const cta = btn?.cta ?? 'Okay';
  const onClick = () => {
    btn?.onClick && btn?.onClick();
    app.ui.drawer.close();
  };
  const icon = opts?.image || assets.coin;

  useEffect(() => {
    app.ui.drawer.setStyle({
      border: '1px solid #09d0a8',
      borderBottom: 'unset',
    });
    return () => {
      app.ui.drawer.setStyle(undefined);
    };
  }, []);

  return (
    <div className="drawer-trading-transaction">
      <div className="drawer-trading-transaction-header">
        <img
          className="drawer-trading-transaction-icon"
          src={assets.trading_transaction_success}
        />

        <div className="drawer-trading-transaction-title">{title}</div>
        <div className="drawer-trading-transaction-subtitle">{`You received`}</div>
        <div className="drawer-trading-transaction-reward">
          <img className="icon" src={icon} />
          <div
            className="value"
            dangerouslySetInnerHTML={{
              __html: reward,
            }}
          ></div>
        </div>
      </div>

      <div className="footer-buttons">
        {/* share */}
        <div className={`btn btn-normal`} onClick={onClick}>
          {cta}
        </div>
      </div>
    </div>
  );
};
