import './TradingProfileUser.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app, useAppUpdates } from '../../../../data/AppController';
import {
  formatPrice,
  largeIntegerToLetter,
} from '../../../../replicant/utils/numbers';
import { useEffect } from 'react';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';

export const TradingProfileUser = () => {
  useAppUpdates({
    id: 'TradingProfileUser',
    listener: app.memes.attachEventListener(
      MemesEvents.TradingOnPortfolioUpdate,
    ),
  });

  useEffect(() => {
    app.memes.trading.refetchPortfolio();
  }, []);

  const { t } = useTranslation();

  // app.profile.current will change to whatever was selected last
  // this needs to be the 'user' profile, not the last visited one
  const photo = app.state.profile.photo;
  const portfolioValue = app.memes.trading.portfolioValue;
  const portfolioChange = app.memes.trading.getPortfolioRoi();
  const color = portfolioChange >= 0 ? 'green' : 'red';

  const onTap = () => {
    app.nav.goToProfile(app.state.id);
  };

  return (
    <div className={`btn trading-profile-user-container`}>
      <div className={`btn trading-profile-user`} onClick={onTap}>
        <div className="trading-profile-user-left">
          <div className="image">
            <img src={photo} />
          </div>
        </div>

        <div className="trading-profile-user-right">
          <div className="user-coins">
            <div className="icon">
              <img src={assets.coin} />
            </div>
            <div className="label">{largeIntegerToLetter(portfolioValue)}</div>
          </div>

          <div className="user-percent">
            <div className={`arrow ${color}`}>
              <img
                src={
                  portfolioChange > 0
                    ? assets.arrow_up_green
                    : assets.arrow_down_red_bright
                }
              />
            </div>

            <div className={`percent ${color}`}>
              {formatPrice(Math.round(portfolioChange))}%{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
