import { Badge } from '../../../shared/Badge/Badge';
import { assets } from '../../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { getUIStateClassName } from '../../../../data/utils';
import { ElementUIState } from '../../../../data/Controllers/UIController/UIController';

type BtnType = 'friends' | 'booster' | 'mine' | 'earn' | 'trading';

export const FooterButton = ({
  type,
  onClick,
  uiState = ElementUIState.Normal,
  label,
  badges,
}: {
  type: BtnType;
  onClick: () => void;
  uiState?: ElementUIState;
  label: string;
  badges: number | string;
}) => {
  const { t } = useTranslation();

  const yellowIcon = uiState === ElementUIState.Highlight ? '_yellow' : '';
  const isMystery = uiState === ElementUIState.Mystery;

  const icon = isMystery ? assets.mystery : assets[`${type}${yellowIcon}`];

  return (
    <div
      className={`btn btn-clicker footer ${getUIStateClassName(
        uiState,
      )} ${type}`}
      onClick={onClick}
    >
      <img src={icon} />
      <div className="label">{t(label)}</div>
      <Badge visible={Boolean(badges)} amount={badges} />
    </div>
  );
};
