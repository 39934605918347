import './TradingTokenFooter.scss';
import { useTranslation } from 'react-i18next';
import { app, useAppUpdates } from '../../../../data/AppController';
import { Tabs, TabStyle } from '../../../shared/Tabs/Tabs';
import {
  Currency,
  TxType,
} from '../../../../replicant/features/offchainTrading/types';
import { getPct } from '../../../../data/utils';

import { assets } from '../../../../assets/assets';
import {
  formatPrice,
  largeIntegerToLetter,
  prettyDecimals,
  displayTokenAmount,
  roundDecimals,
} from '../../../../replicant/utils/numbers';
import { TradingInputNumeric } from '../../TradingPage/TradingInputNumeric/TradingInputNumeric';
import { useEffect, useState } from 'react';
import Big from 'big.js';
import { isIOS } from '../../../../data/device';
import { DottedSlider } from '../../../shared/DottedSlider/DottedSlider';
import { offchainTokenDisplayMultiplier } from '../../../../replicant/features/offchainTrading/offchainTrading.ruleset';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';
import { t } from 'i18next';

interface FooterProps {
  footerMode: 'normal' | 'create';
  collapse?: number;
}

export const TradingTokenFooter = ({ footerMode, collapse }: FooterProps) => {
  const { t } = useTranslation();

  const { trading } = app.memes;

  useAppUpdates({
    id: 'OffchainTokenFooter',
    listener: app.memes.attachEventListener(MemesEvents.TradingOnTxUpdate),
  });

  const onTabClick = async (tab: Currency) => {
    setFooterExpanded(true);
  };

  // start with minimez footer whenever we re-enter the page
  const { visible } = app.views.TradingTokenPage;
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (visible) setFooterExpanded(false);
  }, [visible, collapse]);

  const setFooterExpanded = (expanded: boolean) => {
    setExpanded(expanded);
    app.ui.setFooterExpanded(expanded);
  };

  // #region footer types
  // --------------------------------
  // render create

  if (footerMode === 'create') {
    return (
      <div className={`trading-token-footer-container noTabs`}>
        <TradingTokenFooterForm
          footerMode={footerMode}
          expanded={true}
          txType="buy"
        />
      </div>
    );
  }

  // --------------------------------
  //  render minimized buy/sell footer

  if (!expanded) {
    const h = isIOS() ? '105px' : '88px';
    return (
      <div className={`trading-token-footer-container`} style={{ height: h }}>
        <div className="trading-token-footer-buttons">
          <div
            className="btn btn-normal trading-token-footer-button buy"
            onClick={() => {
              trading.setPurchaseMode('buy');
              setFooterExpanded(true);
            }}
          >
            {t('trading_token_footer_button_buy')}
          </div>
          <div
            className={`btn btn-normal trading-token-footer-button sell`}
            onClick={() => {
              trading.setPurchaseMode('sell');
              setFooterExpanded(true);
            }}
          >
            {t('trading_token_footer_button_sell')}
          </div>
        </div>
      </div>
    );
  }

  // --------------------------------
  //  render expanded buy/sell footer

  return (
    <TradingTokenFooterContent
      onTabClick={onTabClick}
      onMinimize={() => setFooterExpanded(false)}
    />
  );
};

// ==============================================================================
// #region Content

interface PropsFooterContent {
  onTabClick: (tab: Currency) => void;
  onMinimize: () => void;
}

export const TradingTokenFooterContent = ({
  onTabClick,
  onMinimize,
}: PropsFooterContent) => {
  const { t } = useTranslation();
  const h = isIOS() ? '344px' : '330px';
  const isBuy = app.memes.trading.tx?.txType.includes('buy');

  return (
    <div className="trading-token-footer-container" style={{ height: h }}>
      {isBuy ? (
        <div className="trading-token-footer-header">
          <div className="tabs-wrapper">
            <Tabs<Currency>
              tabStyle={TabStyle.Large}
              height={40}
              initial={'coins'}
              autoSelected={'coins'}
              onTabClick={onTabClick}
              tabs={[
                {
                  id: 'coins',
                  name: t('tiktok_buywithpoints'), // 'Buy with Coins',
                  component: (
                    <TradingTokenFooterForm
                      footerMode={'normal'}
                      expanded={true}
                      txType={'buy'}
                      currency={'coins'}
                    />
                  ),
                },
              ]}
            />
            <div
              className="btn trading-token-footer-close"
              onClick={onMinimize}
            >
              <img src={assets.button_arrow_down} />
            </div>
          </div>
        </div>
      ) : (
        <div className="trading-token-footer-header sell-header">
          <div className="trading-token-footer-title">{'Sell Tokens'}</div>
          <div className="btn trading-token-footer-close" onClick={onMinimize}>
            <img src={assets.button_arrow_down} />
          </div>
          <TradingTokenFooterForm
            footerMode={'normal'}
            expanded={true}
            txType={'sell'}
          />
        </div>
      )}
    </div>
  );
};

// ==============================================================================
// #region Form

interface PropsFooterForm {
  footerMode: 'normal' | 'create';
  expanded: boolean;
  txType: TxType;
  currency?: Currency;
}

export const TradingTokenFooterForm = ({
  footerMode,
  expanded,
  txType,
  currency = 'coins',
}: PropsFooterForm) => {
  useAppUpdates({
    id: 'OffchainTokenFooterForm',
    listener: app.memes.attachEventListener(MemesEvents.TradingOnTxUpdate),
  });

  const { t } = useTranslation();
  const [sliderValue, setSliderValue] = useState(0);

  const { trading, currentMeme } = app.memes;

  const points = Number(app.ttg.points);

  const tokenAmount = currentMeme.myTokenState?.tokenAmount ?? 0;

  const maxTokenAmount = tokenAmount ? Big(tokenAmount).toNumber() : 0;

  const useCoins = currency === 'coins';
  const maxAmount = trading.isBuy
    ? useCoins
      ? app.state.balance
      : points
    : maxTokenAmount;

  const setSpendAmount = (spendAmount: Big) => {
    const percentage =
      maxAmount > 0 ? spendAmount.div(maxAmount).toNumber() : 0;

    setSliderValue(Math.max(0, Math.min(percentage, 1)));
  };

  useEffect(() => {
    const amount = trading.tx?.send ?? Big(0);
    setSpendAmount(amount);
  }, [
    trading.tx?.send,
    trading.isBuy,
    app.state.balance,
    points,
    tokenAmount,
    txType,
  ]);

  if (!expanded) {
    return null;
  }

  if (!app.memes.trading.tx) {
    return null;
  }

  const balance = useCoins ? app.state.balance : points;
  const prettyBalance = largeIntegerToLetter(balance);

  const onSliderChange = (percentage: number) => {
    setSliderValue(percentage);

    if (app.memes.trading.isBuy) {
      const maxAmount = useCoins ? app.state.balance : points;

      app.memes.trading.updateAmount(Big(Math.round(maxAmount * percentage)));
      return;
    }

    // selling
    const token = app.memes.currentMeme.myTokenState;
    if (!token) {
      app.memes.trading.updateAmount(Big(0));
      return;
    }

    if (percentage >= 1.0) {
      app.memes.trading.updateAmount(Big(token.tokenAmount));
      return;
    }

    const maxTokenAmount = Big(token.tokenAmount).toNumber();
    const amount = Big(roundDecimals(maxTokenAmount * percentage));
    const max = Big(token.tokenAmount);

    if (amount.gt(max)) {
      app.memes.trading.updateAmount(max);
    } else {
      app.memes.trading.updateAmount(amount);
    }
  };

  // execute buy/sell -> close current drawer, refresh token and display condirmation drawer
  const onTapPurchase = () => {
    app.ui.drawer.close();
    app.memes.trading.goToTxConfirmation();
  };

  const isBuy = trading.tx?.txType === 'buy';
  const spendAmount = trading.tx?.send.toNumber() ?? 0;
  const noCoinsClass = app.state.balance <= 0 ? 'invisible squashed' : '';

  const tickerName = currentMeme.token?.ticker ?? '#';
  const onInputUpdate = (value: number) => {
    if (trading.tx?.txType === 'buy') {
      trading.updateAmount(Big(value));
    } else {
      trading.updateAmount(Big(value / offchainTokenDisplayMultiplier));
    }

    // when changin input value, we must update slider bar too
    setSpendAmount(Big(value));
  };

  return (
    <div className="trading-token-footer-form-area">
      {/* ticker or coin icon */}
      <div className={`trading-token-footer-form ${noCoinsClass}`}>
        {trading.isBuy ? (
          trading.txCurrency === 'coins' ? (
            <img className="icon-coin-left" src={assets.coin} />
          ) : (
            <div className="ticker-name-left">
              {t('tmg_farming_just_points')}
            </div>
          )
        ) : (
          <div className="ticker-name-left">{tickerName}</div>
        )}

        {/* numeric input */}
        <TradingInputNumeric
          type={isBuy ? 'numeric' : 'decimal'}
          value={
            isBuy ? spendAmount : spendAmount * offchainTokenDisplayMultiplier
          }
          maxValue={
            isBuy ? maxAmount : maxAmount * offchainTokenDisplayMultiplier
          }
          icon={assets.button_x}
          pushContentUp={280}
          onAmountChange={onInputUpdate}
          integerOnly={app.memes.trading.isBuy}
          // onInputFocus={(focused: boolean) => setSelectedAmount(0)}
        />

        {/* points */}
        <div className="user-balance">
          <div className="user-balance-title">
            {useCoins
              ? t('trading_token_footer_balance')
              : t('trading_token_footer_points')}
          </div>
          <div className="user-balance-points">
            {useCoins && (
              <img className="user-balance-points-icon" src={assets.coin} />
            )}
            <div className="user-balance-points-label">{prettyBalance}</div>
          </div>
        </div>
      </div>

      <DottedSlider
        initialValue={sliderValue}
        labels={['0%', '25%', '50%', '75%', '100%']}
        onSliderChange={onSliderChange}
      />

      {footerMode === 'normal' ? (
        <div className={`trading-token-footer-fees ${noCoinsClass}`}>
          <TradingTokenFooterFeeMessage />
          <div
            className="btn trading-token-footer-slippage"
            onClick={() => {
              app.ui.drawer.show({
                id: 'drawerTradingSlippage',
                hideClose: true,
              });
            }}
          >
            {t('trading_token_footer_set_max_slippage')}
          </div>
        </div>
      ) : (
        <div className={`trading-token-footer-fees centered ${noCoinsClass}`}>
          <TradingTokenFooterFeeMessage />
        </div>
      )}

      {footerMode === 'normal' ? (
        <TradingTokenFooterButton
          footerMode={footerMode}
          txType={txType}
          onClick={onTapPurchase}
        />
      ) : (
        <TradingTokenFooterCreateButton
          noCoinsClass={noCoinsClass && 'no-coins'}
        />
      )}
    </div>
  );
};

// ==============================================================================
// #region Fee

// note: this is used also from DrawerTradingTransactionConfirm
export const TradingTokenFooterFeeMessage = () => {
  const { t } = useTranslation();
  const fee = app.memes.trading.getTxFee();

  // get the fee pct relative to 100% with no decimal places
  const feePct = getPct(100 * fee.feePercentage, 100, 0);

  return (
    <div className="trading-token-footer-fee-message-container">
      <div className="percent">~{feePct}</div>
      <div className="transaction-fee">
        {t('trading_token_footer_transaction_fee')}:
      </div>
      <div className="coin">
        <img src={assets.coin} />
      </div>
      <div className="value">{formatPrice(fee.feeAmount)}</div>
    </div>
  );
};

// ==============================================================================
// #region Button

export interface PropsFooterBuySellButton {
  footerMode: 'normal' | 'create';
  txType: TxType;
  onClick: () => void;
}

// note: this is used also from DrawerTradingTransactionConfirm
export const TradingTokenFooterButton = ({
  txType,
  onClick,
}: PropsFooterBuySellButton) => {
  const { t } = useTranslation();

  const { tx } = app.memes.trading;
  if (!tx) {
    return null;
  }

  const { receive, isValid } = tx;

  const tickerName = app.memes.currentMeme.token?.ticker ?? '#';

  const receiveNum = receive.toNumber();
  const amount = receive.gte(0)
    ? txType === 'buy'
      ? displayTokenAmount(receiveNum, 4, true)
      : largeIntegerToLetter(receiveNum, 4)
    : '-';

  return (
    <div
      className={`btn btn-normal button-buy ${!isValid && 'disabled'}`}
      onClick={onClick}
    >
      <div className="content-buy">
        {txType.includes('buy') && (
          <>
            <img src={assets.icon_arrow_buy} className="icon-arrow" />
            <div className="label">{`${t('trade_token_button_buy')} ~`}</div>
            <div
              className="value"
              dangerouslySetInnerHTML={{ __html: amount }}
            ></div>
            <div className="label">{tickerName}</div>
          </>
        )}

        {txType === 'sell' && (
          <>
            <img src={assets.icon_arrow_sell} className="icon-arrow" />
            <div className="label">{`${t(
              'trade_token_button_sell_for',
            )} ~`}</div>
            <div className="icon">
              <img src={assets.coin} />
            </div>
            <div className="value">{amount} </div>
            <div className="label">{`coins`}</div>
          </>
        )}
      </div>
    </div>
  );
};

// ==============================================================================
// #region CreateButton

interface CreateBtnProps {
  noCoinsClass?: string;
}

const TradingTokenFooterCreateButton = ({ noCoinsClass }: CreateBtnProps) => {
  const { t } = useTranslation();

  const { tx } = app.memes.trading;

  const [isLoading, setIsLoading] = useState(false);

  if (!tx) return null;

  const { receive, isValid, send } = tx;
  const isValidOrNotInvesting = isValid || send.eq(0);

  const createOffchainTokenConfirm = async () => {
    if (!isValidOrNotInvesting) {
      return;
    }
    setIsLoading(true);
    app.memes.factory.createOffchainToken().finally(() => {
      setIsLoading(false);
    });
  };

  const isListingAndInvesting = receive.gt(0);
  const btnLabelKey = isListingAndInvesting
    ? 'trade_token_button_list_invest'
    : 'trade_token_button_list';
  const btnLabel = t(btnLabelKey);
  const freeLabel = t('free');
  const disableBtn = !isValidOrNotInvesting || isLoading;
  const useFreeLabel = app.state.tokenCreationCredits > 0;
  // Update this line to use the ticker of the token being created
  const tickerName = app.memes.factory.newTokenForm.data?.ticker ?? '#';
  const tokenAmount = displayTokenAmount(receive.toNumber(), 4, true);

  return (
    <div
      className={`btn btn-normal button-buy ${
        disableBtn && 'disabled'
      } ${noCoinsClass}`}
      onClick={createOffchainTokenConfirm}
    >
      <div className="content-buy">
        <div className="label">
          {useFreeLabel && freeLabel} {btnLabel}
        </div>
        {isListingAndInvesting && (
          <>
            <div
              className="value"
              dangerouslySetInnerHTML={{ __html: tokenAmount }}
            ></div>
            <div className="label">{tickerName}</div>
          </>
        )}
      </div>
    </div>
  );
};

// ==============================================================================
