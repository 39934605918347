import type { Ruleset } from '@play-co/replicant';
import { stage } from './features/game/game.config';

export const tests = {
  ACCESS_WHITELIST: 'access_whitelist',
  TEST_EXAMPLE: '0001_example',
  // TEST_INVITE_DIALOG: '0002_invite_drawer',
  // TEST_INVITE_URL: '0003_invite_url',
  // TEST_INVITE_COPY: '0004_invite_copy',
  // TEST_INVITE_MESSAGE: '0005_invite_message',
  // TEST_DAILY_REWARD: '0006_daily_reward',
  // TEST_GAME_PREVIEW: '0007_game_preview',
  // TEST_RETENTION_WITH_POINTS: '0008_retention_with_points',
  // TEST_LOCALIZATION: '0010_localization',
  // TEST_EARN_PAGE_BUTTONS: '0011_earn_page_buttons',
  // TEST_REWARDS_SUMMARY: '0012_rewards_summary',
  // TEST_GIFT_AIRDROP: '0014_gift_airdrop',
  // TEST_DAO_CARD: '0015_dao_offchainToken',
  // TEST_THROTTLING: '0016_throttling',
  // TEST_THROTTLING: '0016_throttling_v2',
  // TEST_EARN_PAGE_BUTTON_2_FRIENDS: '0018_earn_page_button_2_friends',
  // TEST_MINING_PAGE_BADGE: '0020_mining_page_badge',
  // TEST_LOCALIZATION: '0022_localization',
  // TEST_EARN_PAGE_BUTTON_CONNECT_WALLET: '0023_earn_page_button_connect_wallet',
  // TEST_REWARDS_SUMMARY2: '0024_rewards_summary2',
  // TEST_INVITE_BTN_LOCKED_CARDS: '0025_invite_btn_locked_cards',
  // TEST_CHATBOT_BROADCAST: '0026_reengagement_broadcast',
  // TEST_MINING_PAGE_ROUTE: '0028_mining_page_route',
  // TEST_MINING_SPECIAL_INTIAL_TAB: '0029_mining_special_initial_tab',
  // TEST_EARN_PAGE2: '0030_earn_page2',
  // TEST_FREE_GIFT_MODAL: '0031_free_gift_modal',
  // TEST_EARLY_INVITE_PROMPT: '0032_early_invite_prompt',
  // TEST_FTUE: '0033_ftue_1',
  // TEST_SLOWDOWN_PROGRESSION: '0034_slowdown_progress',
  // TEST_ELIM_REENGAGE_MSGS: '0035_elim_reengage_msgs',
  // TEST_FTUE_1: '0036_ftue_1',
  // TEST_WORKER_CARDS: '0037_assigned_worker_cards',
  // TEST_DAILY_TASK: '0038_daily_task',
  // TEST_MULTIGIFT: '0039_multigift',
  // TEST_FTUE_2: '0040_ftue_2',
  // TEST_BOT_NOTIF_MANAGER: '0041_bot_notif_manager',
  // TEST_GIFT_TG_MSG: '0042_gift_tg_msg',
  // TEST_TEAM_SEARCH: '0043_team_search',
  // TEST_REENGAGEMENT_VS_NO_MSGS: '0044_reengagement_vs_no_msgs',
  // TEST_PUMP_CARDS: '0046_offchainTrading_offchainTokens',
  // TEST_FTUE_SLIDESHOW: '0047_ftue_slideshow',
  // TEST_WORKER_CARDS_V2: '0049_assigned_worker_cards_2',
  // TEST_REFERRAL_REWARD: '0051_referral_reward',
  // TEST_OUT_OF_ENERGY_INVITE: '0053_out_of_energy_invites',
  // TEST_LIFETIME_REFERRAL_CASHBACK: '0054_lifetime_referral_cashback',
  // TEST_LOADSCREEN_ROADMAP: '0055_loadscreen_roadmap',
  // TEST_LOWER_ENERGY: '0056_lower_energy',
  // TEST_TRANSACTION_FEE: '0057_transaction_fee',
  // TEST_LEAGUE_BG_COLORS: '0061_league_bg_colors',
  // TEST_CONTRACT_CHECKIN: '0064_contract_checkin',
  // TEST_DAILY_CONTRACT_CHECKIN: '0065_contract_checkin', // single bucket
  // TEST_TG_SHARE: '0066_tg_share',
  TEST_TIKTOK_FEED: '0067_tiktok_feed',
  TEST_FOMO_MSG_IMPACT: '0068_fomo_messages_impact',
  TEST_START_GAME_MEMES_PAGE: '0070_start_game_on_memes_page',
  TEST_TIKTOK_TAPGAME_AUTOSTART: '0071_tiktok_tapgame_autostart',
  TEST_START_MSG_VARIANTS: '0072_start_msg_variants',
  // TEST_INVITE_MSG_VARIANTS: '0073_invite_msg_variants',
  // TEST_GIFT_MEME: '0074_conversion_to_trade',
  TEST_REENGAGEMENT_VARIANTS: '0075_reengagement_variants',
  // TEST_MINING_CURVE_TWEAK: '0078_mining_curve_tweak',
  TEST_REENGAGEMENT_BROADCAST: '0079_reengagement_broadcast',
  TEST_TIKTOK_ONLY: '0080_tiktok_only',
  TEST_TIKTOK_GAME_SESSION_DURATION: '0081_tiktok_game_session_duration',
  TEST_TIKTOK_AUTOPLAY_DURATION: '0082_tiktok_autoplay_duration',
  TEST_MFA: '0085_meme_feed_ads',
  TEST_LIMITED_MEME_TICKETS: '0087_limited_meme_tickets',
  TEST_HIDE_FARMING: '0089_hide_farming',
} as const;

type Tests = typeof tests;

type ABTestsType = {
  [K in Tests[keyof Tests]]?: {
    buckets: { id: string }[];
    assignManually?: boolean;
    newUsersOnly?: boolean;
  };
};

const abTests: ABTestsType = {
  [tests.TEST_EXAMPLE]: { buckets: [{ id: 'a' }, { id: 'b' }] },

  [tests.TEST_TIKTOK_FEED]: {
    buckets: [{ id: 'control' }, { id: 'enabled' }],
  },

  [tests.TEST_START_GAME_MEMES_PAGE]: {
    buckets: [{ id: 'control' }, { id: 'enabled' }],
  },

  [tests.TEST_FOMO_MSG_IMPACT]: {
    buckets: [
      { id: 'control' },
      { id: 'no_fomo' },
      { id: '50_fomo' },
      { id: 'fallback_to_fomo' },
    ],
  },

  [tests.TEST_TIKTOK_TAPGAME_AUTOSTART]: {
    buckets: [{ id: 'control' }, { id: 'enabled' }],
  },

  [tests.TEST_START_MSG_VARIANTS]: {
    assignManually: true,
    buckets: [
      { id: 'control' },
      { id: 'start_A1' },
      { id: 'start_A2' },
      { id: 'start_A3' },
      { id: 'start_B1' },
      { id: 'start_B2' },
      { id: 'start_B3' },
      { id: 'start_C1' },
      { id: 'start_C2' },
      { id: 'start_C3' },
    ],
  },

  [tests.TEST_REENGAGEMENT_VARIANTS]: {
    buckets: [
      { id: 'control' },
      { id: 'dont_miss_out' },
      { id: 'times_ticking' },
      { id: 'get_ready' },
      { id: 'miss_you' },
      { id: 'insider_tip' },
      { id: 'losing_ground' },
    ],
  },

  [tests.TEST_REENGAGEMENT_BROADCAST]: {
    assignManually: true,
    buckets: [
      { id: 'control' },
      { id: 'MissYou1' },
      { id: 'MissYou2' },
      { id: 'MissYou3' },
      { id: 'MissYou4' },
      { id: 'MissYou5' },
      { id: 'Changes1' },
      { id: 'Changes2' },
      { id: 'Changes3' },
      { id: 'Changes4' },
      { id: 'Changes5' },
    ],
  },

  [tests.TEST_TIKTOK_ONLY]: {
    newUsersOnly: true,
    buckets: [{ id: 'control' }, { id: 'tiktok_only_experience' }],
  },

  [tests.TEST_TIKTOK_GAME_SESSION_DURATION]: {
    buckets: [
      { id: 'control' }, // 30 seconds
      { id: '15_sec_playsession' },
    ],
  },

  [tests.TEST_TIKTOK_AUTOPLAY_DURATION]: {
    buckets: [
      { id: 'control' }, // 5 seconds
      { id: '3_second_autoplay_delay' },
      { id: '10_second_autoplay_delay' },
    ],
  },

  [tests.TEST_MFA]: {
    buckets: [{ id: 'control' }, { id: 'enabled' }],
  },
  [tests.TEST_LIMITED_MEME_TICKETS]: {
    buckets: [
      { id: 'control' }, // three tickets
      { id: 'one_max_tickets' },
      { id: 'two_max_tickets' },
    ],
  },

  [tests.TEST_HIDE_FARMING]: {
    buckets: [{ id: 'control' }, { id: 'hide_farming' }],
  },
} satisfies Ruleset['abTests'];

if (stage !== 'prod') {
  abTests[tests.ACCESS_WHITELIST] = {
    assignManually: true,
    buckets: [{ id: 'control' }, { id: 'granted' }],
  };
}

export const ruleset = { abTests };
export default ruleset;

export type ABTests = typeof abTests;
export type ABTestID = keyof ABTests;

export type ABTestBucket<T extends ABTestID> = NonNullable<
  ABTests[T]
>['buckets'][number]['id'];

export enum ModalLabels {
  TUTORIAL_TRACKING = 'TUTORIAL_TRACKING',
  SHOW_MEME_TOKEN_GIFT_MODAL = 'SHOW_MEME_TOKEN_GIFT_MODAL',
  SHOW_TIKTOK_TEASER_MODAL = 'SHOW_TIKTOK_TEASER_MODAL',
  SHOW_TIKTOK_TEASER_MODAL_2 = 'SHOW_TIKTOK_TEASER_MODAL_2',
}
