import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UIController';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

/**
 * Don't forget to call `Grant15kState` module as next step
 */
export class MoreCoins15k extends TutorialModule {
  id = 'MoreCoins15k' as 'MoreCoins15k';

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });
    this.app.ui.setTutorialUIState({ league: '1' });
    this.app.ui.setClickerUIState({ btnLeague: ElementUIState.Inactive });
    this.app.ui.setClickerUIState({ btnBooster: ElementUIState.Hidden });
    this.app.ui.setClickerUIState({ btnTeam: ElementUIState.Hidden });
    this.app.ui.setClickerUIState({ btnClicker: ElementUIState.Inactive });
    this.app.ui.setClickerUIState({ btnFriends: ElementUIState.Inactive });
    this.app.ui.setClickerUIState({ btnMine: ElementUIState.Inactive });
    this.app.ui.setClickerUIState({ btnEarn: ElementUIState.Mystery });

    this.app.nav.goToHomePage();
    setTimeout(() => {
      this.app.ui.popup.show({
        title: t('tut_popup_morecoins15k_title'), // 'More coins = more rewards',
        description: t('tut_popup_morecoins15k_description'), // 'Players will receive more rewards based on how many coins they’ve earned . Let’s give you a head start with some more coins',
        score: '1,500',
        cta: t('tut_popup_morecoins15k_cta'), // 'Thanks',
        onClick: () => {
          this.onComplete();
        },
      });
    }, 300);

    return super.execute().then(() => {
      this.app.ui.popup.close();
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_teams_coins_dialog',
      data: {
        gift_amount: 1_500,
      },
    };
  }
}
