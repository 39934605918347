import { app } from '../../../data/AppController';
import { assets } from '../../../assets/assets';
import { Rank } from '../../shared/Rank/Rank';
import { useTranslation } from 'react-i18next';
import { largeIntegerToLetter } from '../../../replicant/utils/numbers';

interface Props {
  teamId: string;
  name: string;
  members: number;
  score: number;
  image?: string;
  rank: number;
  fromSearch?: boolean;
}

export const LeaderboardCard = ({
  teamId,
  name,
  members,
  score,
  image,
  rank,
  fromSearch,
}: Props) => {
  const { t } = useTranslation();

  const onCardClick = () => {
    app.tutorial.step?.onAction && app.tutorial.step?.onAction('reset-timeout');
    app.views.TeamPage.fetch(teamId);
    app.nav.goTo('TeamPage', {
      eventProps: { searchResult: fromSearch },
    });
    // app.views.TeamPage.show(undefined, {
    //   eventProps: { searchResult: fromSearch },
    // });
    // app.onBackNavigateTo('Leaderboard');
  };

  return (
    <div className="card leaderboard" onClick={onCardClick}>
      <div className="card-left">
        <Rank rank={rank} />
        <div className="image">
          <img src={image || assets.friends} />
        </div>
        <div className="info">
          <div className="name">{name}</div>
          <div className="users">
            {t('team_members_count', { count: members })}
          </div>
        </div>
      </div>

      <div className="score">
        <div className="text">{largeIntegerToLetter(score)}</div>
        <div className="icon">
          <img src={assets.coin} />
        </div>
      </div>
    </div>
  );
};
