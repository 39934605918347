import { assets } from '../../../assets/assets';
import { League } from '../../../replicant/features/game/ruleset/league';
import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UIController';
import { waitFor } from '../../utils';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class LeagueLevel2 extends TutorialModule {
  id = 'LeagueLevel2' as 'LeagueLevel2';
  stateUpdateId = 'rechargeRocketman';

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
      league: undefined,
    });

    this.app.ui.setClickerUIState({
      btnClicker: ElementUIState.Normal,
      btnLeague: ElementUIState.Highlight,

      footer: ElementUIState.Normal,

      btnFriends: ElementUIState.Normal,
      btnMine: ElementUIState.Normal,
      btnEarn: ElementUIState.Normal,
      btnBooster: ElementUIState.Normal,
      btnTeam: ElementUIState.Normal,
    });

    await waitFor(100);

    // Welcome to LEAGUE 2
    this.app.ui.drawer.show({
      id: 'generic',
      opts: {
        image: assets.league[League.league2],
        title: t('tut_drawer_league2_title'), //`Welcome to League 2`,
        subtitle: t('tut_drawer_league2_description'), //`You’ve learned the basics and are ready to enter League 2. Higher leagues = mroe coins per tap!`,
        onClose: () => this.closeDrawerAndContinue(),
        buttons: [
          {
            cta: t('tut_drawer_league2_cta'), //`Let's Go!`,
            onClick: () => {
              // todo: league needs to change to league2 (silver)
              this.closeDrawerAndContinue();
            },
          },
        ],
      },
    });

    return super.execute().then(() => {
      this.app.ui.setClickerUIState({ btnLeague: ElementUIState.Normal });
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_farewell_league2_dialog',
    };
  }
}
