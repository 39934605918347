import { AppController } from '../../../../data/AppController';
import { invite } from '../../../../data/sharing';
import {
  Quest,
  QuestActionCTA,
} from '../../../../replicant/features/quests/types';

const customQuestActions: Partial<
  Record<QuestActionCTA, (app: AppController, quest: Quest) => void>
> = {
  team_invite_button: () => {
    invite({
      screen_location: 'earn_page', // quest_config
      data: { feature: 'invite_100_friends', $subFeature: 'earn_page' },
    });
  },
  connect_wallet: (app) => {
    app.tonConnectUI.openModal();
  },
  join_word: (_, q) => {
    if (q.url) {
      Telegram.WebApp.openTelegramLink(q.url);
    }
  },
  buy_meme: (app) => {
    app.ui.drawer.close();
    app.nav.goTo('TradingPage');
  },
  create_meme: (app) => {
    app.ui.drawer.close();
    app.nav.goTo('TradingPage');
    app.memes.factory.createNewToken();
  },
};

export const executeQuestAction = (app: AppController, quest: Quest) => {
  const action = customQuestActions[quest.actionCTA];
  if (action) {
    return action(app, quest);
  }
  // Default to opening a url if we don't have a mapping
  if (quest.url) {
    window.open(quest.url, '_blank')?.focus();
  }
};

export const getHasCustomAction = (quest: Quest) =>
  Boolean(customQuestActions[quest.actionCTA]);
