import { assets } from '../../../assets/assets';
import { ElementUIState } from '../../Controllers/UIController/UIController';
import { waitFor } from '../../utils';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

// todo: remove this step completely? ask Ian.

export class EndOfTutorial extends TutorialModule {
  id = 'EndOfTutorial' as 'EndOfTutorial';

  execute = async () => {
    this.app.ui.setClickerUIState({
      ...Object.keys(this.app.ui.state.clicker).reduce(
        (res, cur) => ({
          ...res,
          [cur]: ElementUIState.Normal,
        }),
        {},
      ),
    });

    this.app.ui.setTutorialUIState({
      energyLimit: undefined,
      league: undefined,
      badges: {
        mine: undefined,
        earn: undefined,
        friends: undefined,
        boosters: undefined,
        trading: undefined,
      },
    });

    await waitFor(300);

    this.app.ui.confetti.show();

    this.app.ui.drawer.show({
      id: 'generic',
      opts: {
        image: assets.tutorial_end,
        title: t('tut_drawer_endtutorial_title'),
        subtitle: t('tut_drawer_endtutorial_description'),
        score: '1,500',
        onClose: () => {
          this.closeDrawerAndContinue();
          setTimeout(() => {
            this.app.ui.confetti.hide();
          }, 1500);
        },
        buttons: [
          {
            cta: t('tut_drawer_endtutorial_cta'),
            onClick: async () => {
              // todo: what do do here? open share api drawer?
              // todo: a rocket has to be given to the user and he needs some feedback about it
              this.closeDrawerAndContinue();

              setTimeout(() => {
                this.app.ui.confetti.hide();
              }, 1500);
            },
          },
        ],
      },
    });

    return super.execute().then(() => {
      this.onComplete();
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_congrats_end_gift',
    };
  }
}
