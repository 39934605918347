import './DrawerTiktokInvite.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/AppController';
import { useEffect, useState } from 'react';
import { insertIconIntoText } from '../../../../data/utils';
import { copyToClipboard } from '../../../../data/utils';

export const DrawerTiktokInvite = () => {
  const { t } = useTranslation();

  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    // this will make back button close this modal
    // instead of navigating back
    app.nav.showingBlockingModal();
  }, []);

  const token = app.memes.currentMeme.token;

  const onTapInviteButton = () => {
    console.warn('>>> onTapInviteButton (Send');
    app.ttg.tap?.share();
  };

  const onTapCopyLinkButton = async () => {
    console.warn('>>> onTapCopyLinkButton');

    app.track('CopyInviteLink', { screen_location: 'modal_tiktok_invite' });

    // apply copy-link functionality.
    const url = (await app.ttg.tap?.share('copyUrlOnly')) || '';

    copyToClipboard(`${t('session_end_send_text')}\n\n${url}`);

    // diplay 'copied' tooltip for a second
    setTimeout(() => {
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 900);
    }, 250);
  };

  const onTapCloseButton = () => {
    app.ui.drawer.close();
  };

  return (
    <div className="drawer-tiktok-invite">
      {/* copied! - success message */}
      <div className={`copy-success ${showSuccess ? 'in' : 'out'}`}>
        <div className="copy-success-icon">
          <img src={assets.copy_success} />
        </div>
        <div className="copy-success-text">
          {t('tapgame_invite_message_copied')}
        </div>
      </div>

      {/* top header - invite title */}
      <div className="drawer-tiktok-invite-header">
        {t('tapgame_invite_title')}
      </div>

      {/* centered content - invite roadmap */}
      <div className="drawer-tiktok-invite-content">
        {/* how it works */}
        <div className="invite-items-title">
          {' '}
          {t('tapgame_invite_howitworks')}
        </div>

        {/* invite items */}
        <div className="invite-items">
          <div className="invite-items-roadmap-line" />

          <ItemInvite
            title={t('tapgame_invite_item_1_title')}
            subtitle={t('tapgame_invite_item_1_subtitle')}
          />
          <ItemInvite
            title={t('tapgame_invite_item_2_title')}
            subtitle={t('tapgame_invite_item_2_subtitle')}
          />
          <ItemInvite
            title={t('tapgame_invite_item_3_title')}
            subtitle={t('tapgame_invite_item_3_subtitle')}
          />
        </div>
      </div>

      {/* bottom buttons  - send & copy-link */}
      <div className="drawer-tiktok-invite-buttons">
        <div className="btn gameover-button light" onClick={onTapInviteButton}>
          {t('tapgame_invite_button_send')}
        </div>

        <div
          className="btn gameover-button light"
          onClick={onTapCopyLinkButton}
        >
          {t('tapgame_invite_button_copy')}
        </div>

        {/* Back link disabled by latest spces, we need to tap TE back to close this modal */}
        {/* <div
          className="btn gameover-button underline"
          onClick={onTapCloseButton}
        >
          {t('tapgame_invite_button_back')}
        </div> */}
      </div>
    </div>
  );
};

interface ItemInviteProps {
  title: string;
  subtitle: string;
}

const ItemInvite = ({ title, subtitle }: ItemInviteProps) => {
  const titleHtml = insertIconIntoText(title);
  const subtitleHtml = insertIconIntoText(subtitle);
  return (
    <div className="item-invite">
      <div className="bullet-point">•</div>
      <div className="item-invite-info">
        <div
          className="item-invite-title"
          dangerouslySetInnerHTML={{ __html: titleHtml }}
        />
        <div
          className="item-invite-subtitle"
          dangerouslySetInnerHTML={{ __html: subtitleHtml }}
        />
      </div>
    </div>
  );
};
