import { useTranslation } from 'react-i18next';
import { app } from '../../../data/AppController';

export const LeagueTitle = ({ league }: { league?: string }) => {
  const { t } = useTranslation();

  return (
    <span>
      {t(`league_title`)} {app.ui.getLeagueTitle(league)}
    </span>
  );
};
