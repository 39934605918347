import './Astronaut.scss';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/AppController';

export const Astronaut = () => {
  const { bonus } = app;

  if (!bonus) {
    return null;
  }

  return (
    <div className="astronaut-c">
      <div className="astronaut">
        <img className="char" src={assets.sloth_rocket} />
        <div className="flame-c">
          <span className="flame" />
        </div>
      </div>
    </div>
  );
};
