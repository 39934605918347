import { ReplicantError, ReplicantErrorSubCode } from '@play-co/replicant';
import { ReplicantClient, app } from './AppController';
import { captureGenericError, captureReplicantError } from './sentry';

// set online to true by default if API not supported
let online = navigator.onLine === undefined ? true : navigator.onLine;

let resolveBackOnline: () => void = () => {};
let waitUntilOnline = new Promise((resolve) => {
  resolveBackOnline = resolve as () => void;
});

if (window.ononline !== undefined) {
  // online/offline events supported
  // @note: waitUntilOnline promise never resolves if events not supported
  if (online) {
    resolveBackOnline();
  }

  window.addEventListener('offline', () => {
    online = false;
    waitUntilOnline = new Promise((resolve) => {
      resolveBackOnline = resolve as () => void;
    });
  });

  window.addEventListener('online', () => {
    online = true;
    resolveBackOnline();
  });
}

export type CriticalErrors =
  | 'session_expired'
  | 'desync'
  | 'network'
  | 'bad_version'
  | 'unidentified';

export type ErrorResolution = 'close' | 'reload';

export const errorResolution: Record<CriticalErrors, ErrorResolution> = {
  session_expired: 'close',
  desync: 'reload',
  network: 'reload',
  bad_version: 'reload',
  unidentified: 'reload',
};

export interface UserFacingErrorMessage {
  title: string;
  message: string;
  button: string;
}

export const userFacingErrorMessages: Record<
  CriticalErrors,
  UserFacingErrorMessage
> = {
  session_expired: {
    title: 'drawer_error_auth_title',
    message: 'drawer_error_auth_subtitle',
    button: 'drawer_error_auth_button',
  },
  bad_version: {
    title: 'drawer_error_version_title',
    message: 'drawer_error_version_subtitle',
    button: 'drawer_error_version_button',
  },
  desync: {
    title: 'drawer_error_desync_title',
    message: 'drawer_error_desync_subtitle',
    button: 'drawer_error_desync_button',
  },
  network: {
    title: 'drawer_error_network_title',
    message: 'drawer_error_network_subtitle',
    button: 'drawer_error_network_button',
  },
  unidentified: {
    title: 'drawer_error_unidentified_title',
    message: 'drawer_error_unidentified_subtitle',
    button: 'drawer_error_unidentified_button',
  },
};

export async function onReplicationError() {
  app.criticalError = 'unidentified';
}

async function onSessionDesyncError() {
  app.criticalError = 'desync';
}

async function onVersionError() {
  app.criticalError = 'bad_version';
}

async function onAuthError(subCode?: string) {
  if (subCode === 'token_expired') {
    app.criticalError = 'session_expired';
  } else {
    app.criticalError = 'unidentified';
  }
}

const MAX_RETRIES = 3;

async function retryLastRequest(
  replicant: ReplicantClient,
  onlineRetries: number = 0,
) {
  try {
    await replicant.retryLastRequest();
  } catch (error) {
    if (onlineRetries >= MAX_RETRIES) {
      throw error;
    }

    if (navigator.onLine === false || online === false) {
      // no cap on offline retries

      // waiting for the user to come online
      await new Promise((resolve) => {
        // wait until whatever comes first:

        // 1 - either event handler notified as online
        waitUntilOnline.then(resolve);

        // 2 - or timeout elapsed (in case online/offline events not supported/unreliable)
        const waitTime = 150;
        setTimeout(resolve, waitTime);
      });

      return retryLastRequest(replicant, onlineRetries);
    }

    return retryLastRequest(replicant, onlineRetries + 1);
  }
}

export async function onNetworkError(
  error: string,
  replicant: ReplicantClient,
) {
  try {
    await retryLastRequest(replicant);
  } catch (e) {
    app.criticalError = 'network';
  }
}

export function onError(error: ReplicantError, replicant: ReplicantClient) {
  switch (error.code) {
    case 'unknown_error':
    case 'replication_error':
    case 'server_error':
      // sentry tracking
      captureReplicantError(error);
      return onReplicationError();
    case 'authorization_error':
      return onAuthError(error.subCode);
    case 'network_error':
      return onNetworkError(error.message, replicant);
    case 'session_desync_error':
      return onSessionDesyncError();
    case 'version_error':
      captureReplicantError(error);
      return onVersionError();
    default: {
      // Produce a compilation error when a new type of error is added.
      const _: never = error.code;

      // This indicates a replicant bug. Log it in sentry.
      captureGenericError('Unknown replicant error!', error);

      // Treat this as a replication error.
      return onReplicationError();
    }
  }
}
