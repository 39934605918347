import './ProfilePage.scss';
import { Page } from '../Page';
import { useEffect, useState } from 'react';
import { app, useAppUpdates } from '../../../data/AppController';
import {
  FarmingSearchResult,
  ProfileEvents,
} from '../../../data/Controllers/ProfileController';
import { assets } from '../../../assets/assets';
import {
  formatPrice,
  largeIntegerToLetter,
} from '../../../replicant/utils/numbers';
import { Tabs, TabStyle } from '../../shared/Tabs/Tabs';
import { useTranslation } from 'react-i18next';
import { PortfolioChart } from '../TradingTokenPage/Charts/PortfolioChart';
import { TradingTokenItemProfile } from '../TradingPage/TradingTokenItemProfile/TradingTokenItemProfile';
import { SliceConfig } from '../../../replicant/features/offchainTrading/offchainTrading.ruleset';
import { getTMGFarmingIsShowing } from '../../../replicant/features/offchainTrading/offchainTrading.getters';
import { TradingSearchResult } from '../../../replicant/features/offchainTrading/offchainTrading.properties';
import { TradingSortingHeader } from '../TradingPage/TradingSortingHeader/TradingSortingHeader';
import Dots from '../../shared/Dots/Dots';
import { TradingGemzCoinItem } from '../TradingPage/TradingGemzCoinItem/TradingGemzCoinItem';
import { TradingTokenItemProfileFarming } from '../TradingPage/TradingTokenItemProfileFarming/TradingTokenItemProfileFarming';
import { ProfileTab } from '../../../data/Controllers/UIController/UIController';
import { isTiktokEnabled } from '../../../data/utils';

const followFeatureEanbled = false;

export const ProfilePage = () => {
  const { t } = useTranslation();

  useAppUpdates({
    id: 'ProfilePage',
    listener: app.views.ProfilePage.attachEventListener(),
  });

  const { visible, hide } = app.views.ProfilePage;

  useAppUpdates({
    id: 'ProfilePage/ProfileEvents.OnUpdate',
    listener: app.profile.attachEventListener(ProfileEvents.OnUpdate),
    dep: visible,
  });

  const tab = app.ui.state.profile.ownedOrCreatedOrFarming;
  const isShowingFarming = getTMGFarmingIsShowing(app.state);

  useEffect(() => {
    // When we leave the page reset the tab to owned
    if (!visible) {
      app.ui.setProfileState({ ownedOrCreatedOrFarming: 'Owned' });
    }
  }, [visible]);

  const selectTab = (cat: ProfileTab) => {
    // record profile tab state in ui controller
    app.ui.setProfileState({ ownedOrCreatedOrFarming: cat });

    app.track('profile_tab_switch', {
      tab_name: cat,
    });
  };

  if (!visible) {
    return null;
  }

  const { share, toggleFollow, current, updatingFollow } = app.profile;

  const onConnectWallet = async () => {
    if (app.tonConnectUI.connected) {
      return;
    }
    await app.tonConnectUI.openModal();
    // rerender here and if the value from tonConnectUi is updated the button state will reflect it
    app.views.ProfilePage.rerender();
  };

  if (!current) {
    hide();
    return null;
  }

  // console.warn('>>> current profile', current);

  // get portfolio value and percent diff from profile controller
  const portfolioValue = current.portfolio.value;
  const portfolioChange = current.portfolio.diff;
  const profitLoss = current.portfolio.profitLoss;
  const tradingVolume = current.portfolio.tradingVolume;

  // phase 2 (ui)
  // - if this is the app user (current.isSelf), display connect wallet button
  // - if is not, display follow button and info
  // - in both cases, display following / followers info labels on the third row

  // todo: we need to implement sortBy functionality both in profile and roi summary

  const onFollowClick = (type: 'following' | 'followers') => () => {
    app.ui.drawer.show({
      id: 'follow',
      hideClose: true,
      props: { followView: type },
    });
  };

  const getTabs = () => {
    const baseTabs = [
      {
        id: 'Created' as ProfileTab,
        name: t('profile_tab_created'), // 'Created',
        component: (
          <ProfileTokenList tab="Created" list={current.tokensCreated} />
        ),
      },
      {
        id: 'Owned' as ProfileTab,
        name: t('profile_tab_owned'), // 'Owned',
        component: (
          <ProfileTokenList
            tab="Owned"
            list={current.tokensOwned}
            balance={current.balance}
          />
        ),
      },
    ];

    if (app.profile.current?.isSelf && isTiktokEnabled() && isShowingFarming) {
      baseTabs.push({
        id: 'Farming' as ProfileTab,
        name: t('profile_tab_farming'), // 'Farming',
        component: (
          <ProfileTokenList
            tab="Farming"
            list={current.tokensFarming} // todo(Cai): hook this with a farming list data
            isFarming={true}
          />
        ),
      });
    }

    return baseTabs;
  };

  return (
    <Page id="profile" visible={visible}>
      {/* header */}
      <div className="profile-header">
        <div className="profile-header-left">
          <div className="profile-user-image">
            <img src={current.picture} />
          </div>
        </div>
        <div className="profile-header-right">
          <div className="profile-user-name">{current.name}</div>

          {!followFeatureEanbled && (
            <div className="profile-buttons">
              <div
                className="btn btn-normal profile-button share"
                onClick={share}
              >
                Share
              </div>
            </div>
          )}

          {/* following / followers info */}

          {followFeatureEanbled && (
            <>
              <div className="profile-follow-info">
                <div className="section" onClick={onFollowClick('following')}>
                  <div className="value">
                    {largeIntegerToLetter(current.following)}
                  </div>
                  <div className="label">following</div>
                </div>

                <div className="section" onClick={onFollowClick('followers')}>
                  <div className="value">
                    {largeIntegerToLetter(current.followers)}
                  </div>
                  <div className="label">followers</div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* buttons - follow | share */}
      {followFeatureEanbled && (
        <div className="profile-buttons">
          {current.isSelf ? (
            <div
              className="btn btn-normal profile-button connect-wallet"
              onClick={onConnectWallet}
            >
              {app.tonConnectUI.connected
                ? 'Wallet Connected'
                : 'Connect Wallet'}
            </div>
          ) : (
            <div
              className="btn btn-normal profile-button follow"
              onClick={toggleFollow}
            >
              {updatingFollow ? (
                <Dots />
              ) : current.isFollowing ? (
                'Unfollow'
              ) : (
                'Follow'
              )}
            </div>
          )}
          <div className="btn btn-inverse profile-button share" onClick={share}>
            Share
          </div>
        </div>
      )}

      {/* header boxes */}
      <div className="profile-header-boxes">
        <ProfileHeaderBox
          title={t('trade_roi_portfolio_value')}
          value={portfolioValue}
          percent={portfolioChange}
        />
        <ProfileHeaderBox title={'Profit/loss'} value={profitLoss} />
        <ProfileHeaderBox title={'Volume traded'} value={tradingVolume} />
      </div>

      {/* insert chart here */}
      <div className="chart-container-wrapper">
        {Boolean(portfolioValue) && (
          <PortfolioChart
            portfolioValue={portfolioValue}
            getPortfolioPricePoints={async (sliceConfig: SliceConfig | null) =>
              app.profile.getPortfolioPricePoints(sliceConfig)
            }
            height={160} // 250
            showPresets={false}
          />
        )}
      </div>

      {/* tabs */}
      <Tabs<ProfileTab>
        tabStyle={TabStyle.Squared}
        height={40}
        initial={'Created'}
        autoSelected={tab}
        onTabClick={selectTab}
        tabs={getTabs()}
      />
    </Page>
  );
};

// ====================================================================
// Profile header boxes

interface ProfileHEaderProps {
  title: string;
  value: number;
  percent?: number;
}
export const ProfileHeaderBox = ({
  title,
  value,
  percent,
}: // timeIntervalMode?: TimeIntervalMode;
ProfileHEaderProps) => {
  const color = percent ? (percent > 0 ? 'green' : 'red') : 'white';

  return (
    <div className={`profile-header-box`}>
      <div className="title">{title}</div>

      <div className="value-area">
        <div className="value">
          {largeIntegerToLetter(Math.round(value), 3)}
        </div>

        {percent && (
          <div className="profile-header-box-percent">
            {Boolean(percent) && (
              <div className={`arrow ${color}`}>
                <img
                  src={
                    percent > 0
                      ? assets.arrow_up_green
                      : assets.arrow_down_red_bright
                  }
                />
              </div>
            )}
            <div className={`percent ${color}`}>
              {formatPrice(Math.round(percent))}%{' '}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// ====================================================================
// Profile token list

interface ProfileTokenListProps {
  tab: ProfileTab;
  list: (FarmingSearchResult | TradingSearchResult)[];
  balance?: number;
  isFarming?: boolean;
}

export const ProfileTokenList = ({
  tab,
  list,
  balance,
  isFarming,
}: ProfileTokenListProps) => {
  const assetCount = list.length;

  const displayBalance = balance !== undefined;

  return (
    <div className="profile-items-list">
      <TradingSortingHeader
        totalLabel={tab}
        totalValue={displayBalance ? assetCount + 1 : assetCount}
        list={list}
      />

      {displayBalance && <TradingGemzCoinItem balance={balance} />}

      {/* {list.map((item, index) => (
        <TradingTokenItemProfile
          key={index + '_' + item.id}
          offchainTokenData={item}
        />
      ))} */}

      {list.map((item, index) => {
        if (isFarming) {
          return (
            <TradingTokenItemProfileFarming
              key={index + '_' + item.id}
              offchainTokenData={item as FarmingSearchResult}
            />
          );
        }

        return (
          <TradingTokenItemProfile
            key={index + '_' + item.id}
            offchainTokenData={item}
          />
        );
      })}
    </div>
  );
};
