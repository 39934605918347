import './DrawerS1.scss';
import { useTranslation } from 'react-i18next';
import { app } from '../../../../data/AppController';
import { useState } from 'react';
import { assets } from '../../../../assets/assets';
import {
  formatPrice,
  largeIntegerToLetter,
} from '../../../../replicant/utils/numbers';

export const DrawerS1 = () => {
  const { t } = useTranslation();

  return (
    <div className="drawer-s1">
      <div className="drawer-s1-header">
        <div className="drawer-s1-icon" />

        <div className="drawer-s1-title">
          <div className="drawer-s1-title-label">
            {t('season1_drawer_title')}
          </div>

          <div className="drawer-s1-title-coins">
            <div className="icon">
              <img src={assets.coin} />
            </div>
            <div className="label">
              {formatPrice(
                app.state.seasonScores[app.state.seasonScores.length - 1] || 0,
              )}
            </div>
          </div>
        </div>

        {/* <div className="drawer-s1-rows">
          <Row title="Tapbot earnings" value={1_500} />
          <Row title="Mining earnings" value={12_347_500} />
          <Row title="Referal bonuses" value={8_400} />
        </div> */}

        <div className="drawer-s1-footer">
          {t('season1_drawer_description')}
        </div>
      </div>
    </div>
  );
};

const Row = ({ title, value }: { title: string; value: number }) => {
  return (
    <div className="drawer-s1-row">
      <div className="drawer-s1-row-title">{title}</div>
      <div className="drawer-s1-row-coins">
        <div className="icon">
          <img src={assets.coin} />
        </div>
        <div className="label">+ {largeIntegerToLetter(value, 3)}</div>
      </div>
    </div>
  );
};
