import { useAppUpdates, app } from '../../data/AppController';
import { Page } from './Page';

const style = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const LoadingPage = () => {
  useAppUpdates({
    id: 'app',
    listener: app.views.LoadingPage.attachEventListener(),
  });

  const { visible } = app.views.LoadingPage;

  return (
    <Page id="loading" visible={visible}>
      <div style={style}></div>
    </Page>
  );
};
