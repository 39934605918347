import './DrawerFriendsDetails.scss';
import { app } from '../../../data/AppController';
import { assets } from '../../../assets/assets';
import {
  League,
  getLeagueValues,
} from '../../../replicant/features/game/ruleset/league';
import { getFriendReferralRewards } from '../../../replicant/features/game/game.getters';
import { ButtonInvite } from '../../shared/ButtonInvite/ButtonInvite';
import { useTranslation } from 'react-i18next';
import { LeagueTitle } from '../../shared/LeagueTitle/LeagueTitle';
import { formatPrice } from '../../../replicant/utils/numbers';

export const DrawerFriendsDetails = () => {
  const { t } = useTranslation();
  const leagues = getLeagueValues();
  const rewards = leagues.map((league) =>
    getFriendReferralRewards(app.state, app.now(), league),
  );

  return (
    <div className="drawer-layout friends-details">
      <div className="friends-details-header">
        <div className="friends-details-icon">
          <img src={assets.friends} />
        </div>
        <div className="friends-details-title">
          {t('drawer_friends_details_title')}
        </div>
      </div>

      <>
        <div className="friends-bonus-list-header">
          <div className="friends-bonus-list-header-label levelup">
            {t('level_up')}
          </div>
          <div className="friends-bonus-list-header-label">{t('regular')}</div>
          <div className="friends-bonus-list-header-label">{t('premium')}</div>
        </div>

        <div className="friends-bonus-list-container">
          <div className="friends-bonus-list">
            {rewards.map((reward, index) => (
              <FriendsBonusItem
                key={leagues[index]}
                league={leagues[index]}
                regular={reward.regular}
                premium={reward.premium}
              />
            ))}
          </div>
        </div>
        <div className="footer-buttons">
          <ButtonInvite
            caption={'Invite Friend'}
            feature={'friend'}
            subFeature="friend_details"
          />
        </div>
      </>
    </div>
  );
};

export const FriendsBonusItem = (opts: {
  league: League;
  regular: number;
  premium: number;
}) => {
  return (
    <div className="friends-bonus-item">
      <div className="league-icon">
        <img src={assets.league[opts.league]} />
      </div>
      <div className="league-rank">
        <LeagueTitle league={opts.league} />
      </div>
      <div className="bonus-yellow">+{formatPrice(opts.regular)}</div>
      <div className="bonus-yellow">+{formatPrice(opts.premium)}</div>
    </div>
  );
};

export const FriendsReferralItem = (opts: {
  index: number;
  text: string;
  regular: number;
  premium: number;
}) => {
  const league = `league${opts.index + 1}` as League;
  return (
    <div className="friends-bonus-item">
      <div className="league-icon">
        <img src={assets.league[league]} />
      </div>
      <div className="referrer-level">
        <span>{opts.text}</span>
      </div>
      <div className="bonus-yellow">{formatPrice(opts.regular)}%</div>
      <div className="bonus-yellow">{formatPrice(opts.premium)}%</div>
    </div>
  );
};
