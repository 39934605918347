import { AppController } from '../AppController';
import { TutorialModule } from './modules/TutorialModule';

export enum Tutorials {
  InitialTutorial = 'InitialTutorial',
  EnergyTutorial = 'EnergyTutorial',
  SlideshowTutorial = 'SlideshowTutorial',
  SlideshowTradingCreateToken = 'SlideshowTradingCreateToken',
  SlideshowSeasonKickOff = 'SlideshowSeasonKickOff',
  SlideshowTiktok = 'SlideshowTiktok',
  SlideshowTiktok2 = 'SlideshowTiktok2',
  SlideshowTiktokOnlyExperience = 'SlideshowTiktokOnlyExperience',
}

export interface TutorialCfg {
  id: Tutorials;
  steps: TutorialModule[];
  onComplete: (app: AppController) => void;
  lastStep: number;
  repeateable?: boolean;
  transparentBg?: boolean;
}
