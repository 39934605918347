import './DrawerEarnCheck.scss';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/AppController';
import { useState } from 'react';
import { invite } from '../../../data/sharing';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../replicant/utils/numbers';
import { isExpectedError } from '../../../replicant/types';

type EarningKey = Parameters<typeof app.invoke.claimEarning>[0];
const config = {
  tutorial_rocket_quest: {
    title: 'earn_tutorial_rocket_quest',
    subtitle: formatPrice(1000),
    earningKey: { earningKey: 'tutorialRocketQuest' } as EarningKey,
    quest_name: 'tutorial_rocket_quest',
    onPostErrorClick: () => {},
  },
};
export type EarnCheckerKey = keyof typeof config;
/**
 * @deprecated This component is only used by the tutorial and will be removed next refactor. Use questCheck instead
 */
export const DrawerEarnCheck = () => {
  const { t } = useTranslation();

  const { invoke, track } = app;

  const id =
    app.ui.drawer.view.data?.props?.earnCheckerKey || 'tutorial_rocket_quest';

  const image = app.ui.drawer.view.data?.opts?.image || assets.friends;

  const { title, subtitle, earningKey, quest_name, onPostErrorClick } =
    config[id];

  const [isChecking, setIsChecking] = useState(false);

  const [error, setError] = useState('');

  const close = () => {
    setError('');
    setIsChecking(false);
    app.ui.drawer.close();
  };

  const onButtonCheck = async () => {
    if (isChecking) {
      return;
    }
    if (error) {
      onPostErrorClick?.();
      return;
    }
    setIsChecking(true);
    invoke
      .claimEarning(earningKey)
      .then((maybeError) => {
        if (isExpectedError(maybeError)) {
          setError(maybeError.errorMessage);
          track('quest_error', {
            quest_name,
            error_message: maybeError.errorMessage,
          });
          return;
        }
        track('quest_complete', {
          quest_name,
          feature: 'earn',
          $subFeature: `earn_${quest_name}`,
        });
        app.ui.confetti.setData({ duration: 3000 }).show(false);
        app.tutorial.step?.onAction && app.tutorial.step?.onAction();
        // auto close in half second
        setTimeout(() => {
          app.views.EarnPage.rerender();
          close();
        }, 500);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setIsChecking(false);
      });
  };

  return (
    <div className="drawer-earncheck">
      <div className="drawer-earncheck-header">
        <div className="drawer-earncheck-icon">
          <img src={image} />
        </div>
        <div className="drawer-earncheck-title">{t(title)}</div>

        <div className="score">
          <div className="icon">
            <img src={assets.coin} />
          </div>
          <div className="text">{subtitle}</div>
        </div>
      </div>

      <div className="footer-buttons">
        <div className={`btn btn-normal`} onClick={onButtonCheck}>
          {isChecking ? '...' : error || 'Check'}
        </div>
      </div>
    </div>
  );
};
