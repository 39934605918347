import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/AppController';
import { PowerUp } from '../../../../replicant/features/powerups/types';

export const DailyComboSlot = (opts: {
  index: number;
  onClick: (powerup: PowerUp) => void;
}) => {
  const { index } = opts;
  const { state } = app;
  const { data } = app.views.MinePage;

  const slotId = state.powerUps.daily.power_ups[index];
  const slotData = data?.find((pu) => pu.id === slotId) as PowerUp;
  const active = !!slotData;

  return (
    <div
      className={`btn daily-combo-slot ${active ? 'active' : ''}`}
      onClick={() => opts.onClick(slotData)}
    >
      <div className="slot-content">
        <img
          src={active ? slotData.details.image : assets.daily_item_unknown}
        />

        <div className="label">{slotData?.details?.name || ''}</div>
      </div>
    </div>
  );
};
