import './DrawerLeaderboard.scss';
import { assets } from '../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { app, useAppUpdates } from '../../../data/AppController';
import { useEffect } from 'react';
import { LeaderboardCard } from '../../pages/TeamPages/LeaderboardCard';

export const DrawerLeaderboard = () => {
  useAppUpdates({
    id: 'LeaderboardDrawer',
    listener: app.views.LeaderboardDrawer.attachEventListener(),
  });

  const { t } = useTranslation();

  let { data, loading, fetch } = app.views.LeaderboardDrawer;

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="leaderboard">
      <div className="drawer-layout">
        <div className="drawer-header">
          {/* image */}
          <div className="drawer-header-icon">
            <img src={assets.leaderboard} />
          </div>
          {/* title */}
          <div className="drawer-header-title">
            <div className="text">{t(`leaderboard_title`)}</div>
          </div>

          {/* scrollable leaderboard list of teams */}
          <div className="team-list-container">
            {loading ? (
              <p className="loading-label fixed">{t('loading')}</p>
            ) : (
              <div className="team-list">
                {(data || []).map((team, i) => (
                  <LeaderboardCard
                    key={team.id + i}
                    teamId={team.id}
                    name={team.name}
                    members={team.members}
                    score={team.score}
                    rank={team.rank}
                    image={team.photo}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
