import './DrawerPowerUpInfo.scss';
import { assets } from '../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { app } from '../../../data/AppController';

export const DrawerPowerUpInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="drawer-details">
      <div className="drawer-layout powerup-info">
        <div className="drawer-header">
          {/* image */}
          <div className="drawer-header-icon">
            <img src={assets.sloth_earn} />
          </div>
          {/* title and description */}
          {/* <div className="drawer-header-title">{t(`pup_daily_combo`)}</div> */}
          <div className="drawer-header-subtitle powerup-info">
            <div className="text">{t(`pup_daily_combo_description`)}</div>
          </div>
          <div className="checks-3">
            {<img src={assets.green_tick} />}
            {<img src={assets.green_tick} />}
            {<img src={assets.green_tick} />}
          </div>
        </div>
      </div>

      {/* buy button */}
      <div className="footer-buttons">
        <div className={`btn btn-normal`} onClick={app.ui.drawer.close}>
          {t('pup_daily_feeling_lucky')}
        </div>
      </div>
    </div>
  );
};
