import { configureDebugPanel } from '@play-co/debug-panel';
import {
  analytics,
  type AnalyticsProperties,
  PlatformMock,
  PlatformTelegram,
} from '@play-co/gcinstant';
import {
  configureExtensions,
  createPayloadEncoder,
} from '@play-co/gcinstant/replicantExtensions';
import {
  ClientReplicant,
  createOfflineReplicant,
  createOnlineReplicant,
  ReplicantFromConfig,
} from '@play-co/replicant';
import { useEffect, useState } from 'react';
import {
  addMorseUp,
  addClickerPointUp,
} from '../components/pages/ClickerPage/ClickerDiamond/addPointUp';
import replicantConfig from '../replicant/config';
import { getReferralUrl } from '../replicant/features/chatbot/chatbot.private';
import {
  generateUserPayloadKey,
  getBalance,
  getEnergy,
  getLeague,
  getPointsPerTap,
  getDailyCode,
  getHasCompletedDailyCode,
} from '../replicant/features/game/game.getters';
import { getDayMidnightInUTC } from '../replicant/utils/time';
import {
  League,
  SEASON,
  SEASON_LEAGUE_REWARDS,
} from '../replicant/features/game/ruleset/league';
import { FriendList } from '../replicant/features/game/ruleset/friends';
import { sendEntryFinalAnalytics } from './analytics/entryFinal';
import { gameApi, socialApi } from './api';
import cheats from './cheats';
import { config, configByEnv, env, qpConfig } from './config';
import { View } from './View';
import { boosterConfig } from './consts';
import { CriticalErrors, onError, onReplicationError } from './errors';
import { EventListener } from './EventListener';
import { captureGenericError, initSentry, setSentryUser } from './sentry';
import i18n from '../i18n';
import { extractPayloadUserId } from '../replicant/chatbot';
import { TelegramUser } from '../replicant/features/chatbot/chatbot.schema';
import {
  Booster,
  Buff,
  Fn,
  GiftCardInitInfo,
  PromoCardInitInfo,
  LeagueLeaderboard,
  ModalComponent,
  PlayerGame,
  ShopListing,
  Team,
  TTeamPageShowOpts,
} from './types';
import { PowerUp, PowerUpCardType } from '../replicant/features/powerups/types';
import {
  getActivePowerUpById,
  getGiftMessage,
  getOwnedPowerUpsStats,
  getPowerUps,
  hasReceivedFromUserToday,
} from '../replicant/features/powerups/getters';
import { t } from 'i18next';
import { ConnectedWallet, TonConnectError, TonConnectUI } from '@tonconnect/ui';
import { config as gameConfig } from '../replicant/features/game/game.config';
import { isTiktokEnabled, waitFor } from './utils';
import {
  ElementUIState,
  UIController,
  UIEvents,
} from './Controllers/UIController/UIController';
import { TutorialController } from './tutorial/TutorialController';
import { DAY_IN_MS } from '../replicant/utils/time';
import { ModalLabels, tests } from '../replicant/ruleset';
import { EarnPageData, isExpectedError } from '../replicant/types';
import { getFTUE } from './tutorial/tutorials';
import { morseCodeAlphabet, morseTimes } from './morseCode';
import { assets } from '../assets/assets';
import { DAILY_CODE_PRIZE } from '../replicant/features/game/ruleset/dailyRewards';
import { InternetController, InternetEvents } from './InternetController';
import { SessionController } from './Controllers/SessionController';
import { TradingController } from './Controllers/Memes/TradingController';
import { generatePromoLink } from './sharing';
import { NavController } from './Controllers/NavController';
import { IAPConfig } from '../replicant/features/offchainTrading/types';
import {
  TradingToken,
  getTMGFarmingIsShowing,
} from '../replicant/features/offchainTrading/offchainTrading.getters';
import { Tutorials } from './tutorial/types';
import { largeIntegerToLetter } from '../replicant/utils/numbers';
import { RESET_SEASON_2_LABEL } from '../replicant/features/offchainTrading/offchainTrading.ruleset';
import { ProfileController } from './Controllers/ProfileController';
import { Buffer } from 'buffer';
import { getUnpromotedQuests } from '../replicant/features/quests/getters';
import { TokenMiniGamesController } from './Controllers/TokenMiniGames/TMGController';
import { ReplicantCollector } from './ReplicantCollector';
import { MemesController } from './Controllers/Memes/MemesController';
import { AutomationController } from './Controllers/AutomationController';
window.Buffer = window.Buffer || Buffer;

type Listener = (value: () => void) => () => void;

interface ReRenderOpts {
  id: string;
  listener?: Listener;
  dep?: boolean;
  debug?: boolean;
}

export const useAppUpdates = ({ id, listener, dep, debug }: ReRenderOpts) => {
  const [renderCount, setRenderCount] = useState(0);

  useEffect(() => {
    if (!listener || dep === false) {
      return;
    }
    const callback = () => {
      if (debug) {
        // console.log(`Re-render ${id}`);
      }
      setRenderCount(renderCount + 1);
    };
    return listener(callback);
  }, [renderCount, listener, dep]);
};

export enum AppEvents {
  onMyTeamUpdate = 'onMyTeamUpdate',
  onGameStateUpdate = 'onGameStateUpdate',
  onRocketmanChange = 'onRocktmanStart',
  onAppReady = 'onAppReady',
  onBuffBought = 'onBuffBought',
  onAdEnergyRewardChange = 'onAdEnergyRewardUpdate',
  onCriticalError = 'onCriticalError',
  onAppModeChange = 'onAppModeChange',
}

let doOnce = qpConfig.simulateRocketman;

export const isLocal = env === 'local' && !qpConfig.replicant;

export const gcinstant = isLocal ? new PlatformMock() : new PlatformTelegram();

export type ReplicantClient = ClientReplicant<
  ReplicantFromConfig<typeof replicantConfig>
>;

export class AppController extends EventListener {
  // Listen to all events within AppEvents
  events = Object.keys(AppEvents).reduce(
    (res, cur) => ({
      ...res,
      [cur]: [],
    }),
    {},
  );

  public ui = new UIController(this);

  public tutorial = new TutorialController(this);

  public profile = new ProfileController(this);

  private isRocketmanActive = false;

  public views: {
    LeaderboardDrawer: View<Team[]>;
    JoinTeam: View<Team[]>;
    TeamPage: View<Team | undefined, TTeamPageShowOpts>;
    Friends: View<FriendList>;
    Shop: View<ShopListing>;
    Toast: View<{ text?: string; hidePurchaseText?: true } | undefined>;
    Maintenance: View<void>;
    EarnPage: View<EarnPageData>;
    LeaguePage: View<LeagueLeaderboard>;
    MinePage: View<PowerUp[]>;
    TradingPage: View<{ isNew: true } | undefined>;
    TradingCreatePage: View<undefined>;
    TradingCreateLinksPage: View<undefined>;
    TradingEditLinksPage: View<undefined>;
    TradingTokenPage: View<TradingToken>;
    TiktokPage: View<TradingToken>;
    TiktokSearchPage: View<{ isNew: true } | undefined>;
    //
    ModalComponent: View<ModalComponent>;
    ProfilePage: View<void>;
    LoadingPage: View<void>;
  };

  private _playerTeam?: Team;
  public get playerTeam() {
    return this._playerTeam;
  }

  private player?: PlayerGame;
  public get energyLimit() {
    return this.player?.energyLimit || 0;
  }

  public get league() {
    return getLeague(this.replicant.state) as League;
  }

  private _botEarnings?: number;
  public get botEarnings() {
    return this._botEarnings;
  }

  private _powerUpBonus = 0;
  public get powerUpBonus() {
    return this._powerUpBonus;
  }

  private _unclaimedReferralRewards = 0;
  public get unclaimedReferralRewards() {
    return this._unclaimedReferralRewards;
  }

  private _inviteDrawerDuration = 0;
  public get inviteDrawerDuration() {
    return this._inviteDrawerDuration;
  }

  private _cardPromo?: PromoCardInitInfo;
  public get cardPromo() {
    return this._cardPromo;
  }

  private _cardGift?: GiftCardInitInfo;
  public get cardGift() {
    return this._cardGift;
  }

  private _expiredGift?: string;
  public get expiredGift() {
    return this._expiredGift;
  }

  // used to trigger critical error drawers
  private _criticalError: CriticalErrors | null = null;
  public set criticalError(value: CriticalErrors | null) {
    this._criticalError = value;
    this.nav.goToHomePage();
    this.ui.drawer.show({ id: 'criticalError', hideClose: true });
    this.sendEvents(AppEvents.onCriticalError);
  }
  public get criticalError() {
    return this._criticalError;
  }

  public get isFirstSession() {
    // only show the message for players joining organically (no invite)
    return Boolean(this.player?.isFirstSession);
  }

  public get isFirstSessionOfTheDay() {
    return analytics.getUserProperties().lastEntryIsFirstOfDay || false;
  }

  // @todo: playerBalance and displayBalance should just be the same thing
  // @suggesiton: fakeBalanceForAnimation should be named displayBalance
  // and use cases for legacy displayBalance should be split between playerBalance and new displayBalance
  public get playerBalance() {
    return getBalance(this.replicant.state, this.now());
  }

  // public get hasNeverHadZeroEnergy(): boolean {
  //   return getLastEnergyZero(this.state) === 0;
  // }

  public morseCodeMode = false;
  private morseCode = '';
  private morseTimer?: NodeJS.Timeout;
  public morseWord = '';
  public morseWin = false;
  private hasTappedMorseOnce = false;

  private _isReady = false;
  private _isUserBanned = false;

  private trackedTaps = 0;

  private interval?: ReturnType<typeof setInterval>;

  private searchTrackedThisSession = false;

  public get bonus() {
    if (
      !this.isRocketmanActive ||
      !this.player ||
      !this.player?.rocketmanDuration
    ) {
      return undefined;
    }

    const elapsedTimeInMs =
      this.player.lastRocketmanStart +
      this.player.rocketmanDuration -
      this.now();

    return {
      timeLeft: elapsedTimeInMs,
      multiplier: this.player.rocketmanMultiplier,
    };
  }

  private firstEverSession = false;

  private resolveReplicantClient!: (replicantClient: ReplicantClient) => void;

  public replicantClientPromise = new Promise<ReplicantClient>((resolve) => {
    this.resolveReplicantClient = resolve;
  });

  public replicant!: ReplicantClient;
  public tonConnectUI!: TonConnectUI;

  public internet = new InternetController();

  public session = new SessionController(this);

  // expect to have UIController instantiated
  public nav = new NavController(this);

  // public trading = new TradingController(this);

  public memes = new MemesController(this);

  // @TODO: move this insde memes
  // expet to have MemesController instantiated
  public ttg = new TokenMiniGamesController(this);

  public replCollector = new ReplicantCollector(this);

  public automation = new AutomationController(this);

  get isReady() {
    return this._isReady;
  }

  get isUserBanned() {
    return this._isUserBanned;
  }

  get state() {
    return this.replicant?.state;
  }

  constructor(private playerId: string) {
    super();
    console.warn(`
      !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      !!!!!!!!!!!!!!!!!! VERSION: ${process.env.REACT_APP_APP_VERSION} !!!!!!!!!!!!!!!!!!!!!!!!!!
      !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      `);

    // @note: forces the game to refresh after 24h
    // the goal is to avoid exploiting an old client
    // (better solution would be to invalidate old backend to force a refresh)
    setTimeout(() => {
      window.location.reload();
    }, DAY_IN_MS);

    initSentry();

    this.internet.addEventListener(InternetEvents.OnChange, () => {
      if (this.internet.online) {
        analytics.pushEvent('wifi_reconnect', {
          offline_duration: this.internet.offlineDuration,
        });
      } else {
        analytics.pushEvent('wifi_disconnect');
      }
    });

    // initialize ton connect ui
    // (santosh) note that this needs to be done right away
    // to avoid conflicts with avoid closing telegram hack in App class
    this.initTonConnect();

    // initialise TON analytics
    if (config.telegramAnalyticsToken) {
      try {
        //@ts-ignore
        window.telegramAnalytics.init({
          token: config.telegramAnalyticsToken,
          appName: config.telegramAnalyticsAppName,
        });
      } catch {
        console.error(`Could not init telegram analytics`);
      }
    }

    this.initGame()
      .then(() => this.setUserPhoto())
      .catch(async (error) => {
        analytics.pushError('AppInitFailed', error);
        captureGenericError('AppInitFailed', error);

        if (Telegram?.WebApp?.initDataUnsafe?.user) {
          onReplicationError();
        }
      });

    this.views = {
      ProfilePage: new View('ProfilePage', this, async () => {}, {
        onOpen: () => {
          this.track('Profile_view', {
            feature: 'profile',
            $subFeature: 'profile',
            originFeature: 'home',
            username: this.profile.current?.name || 'unknown',
            self: Boolean(this.profile.current?.isSelf),
          });
        },
      }),
      Friends: new View('Friends', this, this.getFriends, {
        onOpen: () => {
          this.track('OpenFriendPage', {
            feature: 'friends',
            $subFeature: 'friends',
            originFeature: 'home',
          });
        },
      }),
      LeaderboardDrawer: new View('Leaderboard', this, gameApi.leaderboard, {
        onOpen: () => {
          this.track('OpenLeaderboard', {
            feature: 'leaderboard',
            $subFeature: 'leaderboard',
            originFeature: 'home',
          });
        },
      }),
      JoinTeam: new View('JoinTeam', this, gameApi.leaderboard, {
        onOpen: () => {
          this.track('JoinTeam', {
            feature: 'team',
            $subFeature: 'team_join',
            originFeature: 'home',
          });
        },
      }),
      TeamPage: new View<Team | undefined, TTeamPageShowOpts>(
        'TeamPage',
        this,
        gameApi.getTeam,
        {
          onOpen: (showOpts) => {
            this.track('OpenTeamPage', {
              ...showOpts?.eventProps,
              feature: 'team',
              $subFeature: 'team_view',
              originFeature: 'home',
            });
          },
          onClose: () => {
            // This prevent lingering data from another team page to display when visiting different teams
            this.views.TeamPage.setData(undefined);
          },
          onFetchComplete: () => {
            // If we refresh the team and it's the players team, update player team
            if (
              this.playerTeam &&
              this.playerTeam?.id === this.views.TeamPage.data?.id
            ) {
              this.setPlayerTeam(this.views.TeamPage.data);
            }

            // If we are viewing the page for a non migrated team, call migration
            if (this.views.TeamPage.data && !this.views.TeamPage.data.search) {
              this.invoke.migrateTeam({ teamId: this.views.TeamPage.data.id });
            }
          },
        },
      ),
      Shop: new View('Shop', this, () => gameApi.getShop(this.playerId)),
      Toast: new View('Toast', this, async () => undefined),
      Maintenance: new View('Maintenance', this, async () => {}, {
        onOpen: () => {
          this.track('OpenMaintenancePage', {
            feature: 'maintenance',
            $subFeature: 'maintenance',
            originFeature: 'home',
          });
        },
      }),
      EarnPage: new View('Earn', this, this.getEarnPageData, {
        onOpen: () => {
          this.track('OpenEarnPage', {
            feature: 'earn',
            $subFeature: 'earn_page',
            originFeature: 'home',
          });
        },
        onClose: () => {},
      }),
      LeaguePage: new View<LeagueLeaderboard>(
        'LeaguePage',
        this,
        this.fetchLeaguePageData,
      ),
      MinePage: new View(
        'MinePage',
        this,
        async () => {
          return getPowerUps(this.replicant.state, this.now());
        },
        {
          onOpen: () => {
            this.track('OpenMinePage', {
              feature: 'mine',
              $subFeature: 'mine_page',
              originFeature: 'home',
            });
          },
        },
      ),
      TradingPage: new View<{ isNew: true } | undefined>(
        'TradingPage',
        this,
        async () => {
          return undefined; // todo: fetch data if we need to
        },
        {
          onOpen: () => {
            this.track('OpenTradingPage', {});
          },
        },
      ),
      TradingTokenPage: new View(
        'TradingTokenPage',
        this,
        async () => {
          // don't use fetch. use SetData when opening the view
          return {} as unknown as TradingToken;
        },
        {
          onOpen: () => {
            this.track('OpenTradingTokenPage', {});
          },
        },
      ),
      TiktokPage: new View(
        'TiktokPage',
        this,
        async () => {
          // don't use fetch. use SetData when opening the view
          return {} as unknown as TradingToken;
        },
        {
          onOpen: () => {
            this.track('OpenTiktokPage', {});
          },
        },
      ),
      TiktokSearchPage: new View<{ isNew: true } | undefined>(
        'TiktokSearchPage',
        this,
        async () => {
          return undefined; // todo: fetch data if we need to
        },
        {
          onOpen: () => {
            this.track('OpenTiktokSearchPage', {});
          },
        },
      ),
      TradingCreatePage: new View(
        'TradingCreatePage',
        this,
        async () => {
          return undefined; // todo: fetch data if we need to
        },
        {
          onOpen: () => {
            this.track('OpenTradingCreatePage', {});
          },
        },
      ),
      TradingCreateLinksPage: new View(
        'TradingCreateLinksPage',
        this,
        async () => {
          return undefined; // todo: fetch data if we need to
        },
        {
          onOpen: () => {
            this.track('OpenTradingCreateLinksPage', {});
          },
        },
      ),
      TradingEditLinksPage: new View(
        'TradingEditLinksPage',
        this,
        async () => {
          return undefined; // todo: fetch data if we need to
        },
        {
          onOpen: () => {
            this.track('OpenTradingEditLinksPage', {});
          },
        },
      ),
      //
      ModalComponent: new View<ModalComponent>(
        'ModalComponent',
        this,
        async () => ({ queue: [] }),
      ),
      LoadingPage: new View('LoadingPage', this, async () => {}, {
        startVisible: true,
      }),
    };
  }

  get invoke() {
    return this.replicant.invoke;
  }

  get asyncGetters() {
    return this.replicant.asyncGetters;
  }

  public now = () => {
    return this.replicant.now();
  };

  private initWebApp = () => {
    Telegram.WebApp.expand();
    Telegram.WebApp.setBackgroundColor('#000000');
    try {
      Telegram.WebApp.setHeaderColor('#000000');
    } catch (error) {
      Telegram.WebApp.setHeaderColor('bg_color');
    }
    Telegram.WebApp.ready();

    // display confirmation message when telegram app is about to be closed
    Telegram.WebApp.isClosingConfirmationEnabled = true;

    // @workaround: This doesn't seem to register if done in-sync with
    // constructor, and delaying the setting of this onClick works.
    setTimeout(() => {
      Telegram.WebApp.BackButton.onClick(() => {
        this.onBack();
      });
    }, 1000);
  };

  onBack = () => {
    if (this.tutorial.step?.onBack) {
      return this.tutorial.step?.onBack();
    }
    this.nav.back();
    // this.closeAllPages();
    // if (this.backEffect) {
    //   this.backEffect();
    //   this._backEffect = undefined;
    // } else {
    //   Telegram.WebApp.BackButton.hide();
    // }
  };

  private setUserPhoto = async () => {
    if (env === 'local' || this.isUserBanned) {
      return;
    }

    const profile = this.replicant.state.profile;
    if (profile.photo !== gcinstant.playerPhoto) {
      await this.replicant.invoke.setProfilePicture({
        profilePictureUrl: gcinstant.playerPhoto,
      });
    }
  };

  private async initTonConnect() {
    let stage = process.env.REACT_APP_STAGE || 'dev';
    this.tonConnectUI = new TonConnectUI({
      manifestUrl: `${gameConfig.playUrl}/tonconnect/${stage}/tonconnect-manifest.json`,
    });

    this.tonConnectUI.onStatusChange(
      async (status: ConnectedWallet | null) => {
        await this.replicantClientPromise;

        if (status) {
          this.replicant.invoke.setWalletInfo({
            app_name: status.appName,
            address: status.account.address,
          });

          analytics.setUserProperties({
            walletAddress: status.account.address,
          });
        }
      },
      (error: TonConnectError) => {
        // console.error(`Failed to connect Ton Wallet: ${error.message}`);
        analytics.pushError('WalletConnectError', {
          name: error.name,
          message: error.message,
        });
      },
    );

    this.maybeFixBrokenWalletConnectQuest();
  }

  private initGame = async () => {
    // If there are update, we reload here
    // TODO: Disabled since we have cache buster and this was potentially causing issues
    // await this.session.checkForUpdates();

    const startTime = Date.now();

    await gcinstant.initializeAsync({
      // These are for local development and will get overwritten in dev/prod
      amplitudeKey: config.amplitudeKey,
      amplitudeTimeZone: config.amplitudeTimeZone,
      appID: 'gemz-coin',
      disableAutomaticTosPopup: true,
      shortName: 'gemz-coin-telegram',
      version: process.env.REACT_APP_APP_VERSION!,
      revenueCurrency: 'USD',
    });

    setSentryUser({ id: gcinstant.playerID, username: gcinstant.playerName });

    this.initWebApp();

    let telegramAuthorizationData: Record<string, string> = {};

    if (gcinstant instanceof PlatformTelegram && Telegram.WebApp.initData) {
      telegramAuthorizationData = gcinstant.getTelegramAuthorizationData();
      this.playerId = gcinstant.playerID;
    }

    let replicantEndpoint: string | undefined;
    const offlineMode = config.replicant.offlineMode;
    const replicantEnv = qpConfig.replicant;
    if (!offlineMode || replicantEnv) {
      replicantEndpoint = offlineMode
        ? configByEnv[replicantEnv as keyof typeof configByEnv]?.replicant
            .endpoint
        : config.replicant.endpoint;
    }

    if (replicantEndpoint) {
      this.replicant = await createOnlineReplicant(
        replicantConfig,
        gcinstant.playerID,
        {
          endpoint: replicantEndpoint,
          platform: 'web',
          telegramAuthorizationData,
          batchingMaxTimeOverrides: { tap: 6_000 }, // Use a longer batching window for `tap` actions to reduce request rate
        },
      );
    } else {
      this.replicant = await createOfflineReplicant(
        replicantConfig,
        qpConfig.playerId,
        {
          platform: 'mock',
        },
      );
    }

    this.replicant.setOnError((error) => onError(error, this.replicant));

    this.resolveReplicantClient(this.replicant);

    await this.replicantClientPromise; // Avoid `Replicant client is not initialized` on `gcinstant.loadStorage`
    await gcinstant.loadStorage();

    this.replCollector.startCollecting();
    // async on purpose
    this.memes.init();

    const entryPayloadKey =
      (process.env.REACT_APP_ENV === 'local' && qpConfig.simulateStartParam) ||
      telegramAuthorizationData.start_param;
    let telegramUser = Telegram?.WebApp?.initDataUnsafe?.user as TelegramUser;
    const entryUserProps: { [key: string]: unknown } = {};

    if (this.replicant.state.first_interaction) {
      // extract referrer id
      let referrerId: string | undefined = undefined;
      try {
        referrerId = extractPayloadUserId(entryPayloadKey);
      } catch (e: any) {
        // allow continuance, but allow log to analytics and sentry
        this.track('FirstInteractPayloadUserIdError', {
          error_message: e?.message || 'unknown',
        });
        captureGenericError('FirstInteractPayloadUserIdError', e);
      }

      if (process.env.REACT_APP_ENV === 'local') {
        telegramUser = {
          id: parseInt(this.replicant.state.id),
          first_name: 'foo',
          last_name: 'bar',
          is_bot: false,
          is_premium: false,
        };
      }

      const payload = await payloadEncoder.decode({
        ['$key']: entryPayloadKey,
      });

      const deeplinkOpts = qpConfig.dlOpts || payload?.payload?.dlOpts;

      const tokenId = deeplinkOpts?.offchainTokenId;

      await this.replicant.invoke.handleFirstEntry({
        referrer: referrerId,
        tokenId,
        telegramUser,
      });

      // do not await for this as it might hang forever
      this.joinReferrerTeam();

      entryUserProps.username = this.replicant.state.username;
      this.firstEverSession = true;
    } else {
      this.replicant.invoke.handleReentry();
    }

    if (telegramUser) {
      entryUserProps.isPremium = Boolean(telegramUser.is_premium);
    }

    if (process.env.REACT_APP_IS_DEVELOPMENT) {
      configureDebugPanel({
        replicant: this.replicant,
        ui: cheats,
      });
    }

    if (this.playerId === 'ANON') {
      throw new Error(`Trying to initialise game without playerId`);
    }

    gcinstant.locale = this.initLanguage(gcinstant.platformLocale);

    analytics.setUserProperties({
      friendCount: this.replicant.state.friendCount ?? 0,
      league: getLeague(this.replicant.state),
      score: this.replicant.state.score,
      balance: this.replicant.state.balance,
      teamId: this.replicant.state.team_id,
      ...entryUserProps,
    });

    const qpPayload = qpConfig.payload;

    // Starts the game once assets are loaded and the backend is ready.
    const startSessionResponse = await gameApi.startSession();

    // In case of an existing user's first entry with GCInstant, bump entry count to 2 to avoid tracking entry as `first: true` in Amplitude:
    const isMigratingUsersFirstEntry =
      gcinstant.storage.entry.count === 1 &&
      !startSessionResponse.player.isFirstSession;

    if (isMigratingUsersFirstEntry) {
      gcinstant.storage.assign((storage) => (storage.entry.count = 2));
    }

    await gcinstant.startGameAsync();

    await this.ui.init();

    if (env === 'local') {
      gcinstant.playerID = this.playerId;
      gcinstant.playerName = `Player ${this.playerId}`;
    }

    this._botEarnings = startSessionResponse.botEarnings;
    this._inviteDrawerDuration = startSessionResponse.inviteDrawerDuration;
    this._powerUpBonus = startSessionResponse.powerUpBonus;
    this._unclaimedReferralRewards =
      startSessionResponse.unclaimedReferralRewards;

    this.setPlayer(startSessionResponse.player, true);
    this.setPlayerTeam(startSessionResponse.team);

    let payloadEntryData: AnalyticsProperties.EntryData | undefined = undefined;
    if (entryPayloadKey) {
      // I think any missing/errors regarding decoding of a payload key returns undefined,
      // but just in case?
      try {
        payloadEntryData = await payloadEncoder.decode({
          ['$key']: entryPayloadKey,
        });
      } catch (e: any) {
        // allow to continue, but log to analytics and sentry
        this.track('InitGamePayloadDecodeError', {
          error_message: e?.message || 'unknown',
        });
        captureGenericError('InitGamePayloadDecodeError', e);
      }
      // handle mine feature entries
      if (payloadEntryData) {
        await this.handleMineEntry(payloadEntryData);
      }
    }

    const deeplinkRoute =
      qpPayload.payload?.dlRoute ||
      qpConfig.dlRoute ||
      payloadEntryData?.payload?.dlRoute;
    const deeplinkOpts =
      qpPayload.payload?.dlOpts ||
      qpConfig.dlOpts ||
      payloadEntryData?.payload?.dlOpts;

    this.track('SessionStart', {
      deeplinkRoute: deeplinkRoute,
      deeplinkOpts: JSON.stringify(deeplinkOpts),
    });

    const elapsedTime = Date.now() - startTime;
    const minLoadScreenTime = this.ui.minLoadScreenTime;
    const remainingTime = minLoadScreenTime - elapsedTime;

    if (remainingTime > 0) {
      await new Promise((resolve) => setTimeout(resolve, remainingTime));
    }

    // Nav wont work until init is called
    this.nav.init();

    // We need to skip a frame here so TG is ready to render the app
    setTimeout(async () => {
      this.nav.goToHomePage();

      await this.gameStart();

      // Try to deeplink to a view (@CAI)
      const didDeeplink = await this.nav.deepLink(deeplinkRoute, deeplinkOpts);

      this._isReady = true;

      this.sendEvents(AppEvents.onGameStateUpdate);
      this.sendEvents(AppEvents.onAppReady);
      this.interval = setInterval(() => {
        this.onSecondElapsed();
      }, 1000);

      analytics.setUserProperties({
        banned: !!this.replicant.state.banned,
      });

      const finalEntryProps = {
        ...payloadEntryData?.payload,
        ...qpPayload,
      };

      await sendEntryFinalAnalytics(
        this.replicant.state,
        finalEntryProps,
        this.replicant.now(),
      );

      if (this.replicant.state.banned) {
        this._isUserBanned = true;
      }

      const isDeveloperUser = this.playerId === '6319323425'; // damon; needed to access promo link generation in prod
      if (isDeveloperUser) {
        const linka = generatePromoLink('pu_specials_binance_bonanza', 'a');
        console.log('PROMOLINK A', linka);
        const linkb = generatePromoLink('pu_specials_binance_bonanza', 'b');
        console.log('PROMOLINK B', linkb);
      }
    }, 100);
  };

  public fetchPlayerState = async (playerId: string) => {
    try {
      const playerStateMap = await this.replicant.fetchStates([playerId]);
      const playerState = playerStateMap?.[playerId]?.state;
      return playerState;
    } catch (error) {
      analytics.pushError('FetchStateFailed', {
        playerId,
        message: typeof error === 'string' ? error : (error as Error).message,
      });
    }
  };

  private joinReferrerTeam = async () => {
    const referrerId = this.state.referrer_id;
    if (!referrerId) {
      return;
    }

    const teamId = await this.replicant.asyncGetters.getPlayerTeamId({
      userId: referrerId,
    });
    if (!teamId) {
      return;
    }

    this.replicant.invoke.joinTeam({ teamId });
  };

  private handleMineEntry = async (payload: AnalyticsProperties.EntryData) => {
    const subfeature = payload.$subFeature;
    if (subfeature === 'gift') {
      try {
        await this.handleMineGiftEntry(payload);
      } catch (e: any) {
        this.track('InitGameHandleMineGiftEntryError', {
          error_message: e?.message || 'unknown',
        });
        captureGenericError('InitGameHandleMineGiftEntryError', e);
      }
    } else if (subfeature === 'promo') {
      try {
        await this.handleMinePromoEntry(payload);
      } catch (e: any) {
        this.track('InitGameHandleMinePromoEntryError', {
          error_message: e?.message || 'unknown',
        });
        captureGenericError('InitGameHandleMinePromoEntryError', e);
      }
    }
  };

  private handleMineGiftEntry = async (
    payload: AnalyticsProperties.EntryData,
  ) => {
    const today = getDayMidnightInUTC(this.now());
    const card = payload.payload.card;
    const powerUpCard = getActivePowerUpById(card);
    if (!powerUpCard) {
      console.error(`Invalid card: ${card}`);
      return;
    }

    if (today === payload.payload.createdAt) {
      const senderId = payload.playerID;
      // ignore if the player has clicked its own gift link
      if (senderId && senderId !== this.playerId) {
        const canAcceptGift = !hasReceivedFromUserToday(
          this.replicant.state,
          powerUpCard,
          senderId,
        );
        if (canAcceptGift) {
          try {
            const cardGiftOrError = await this.invoke.handleMineGiftEntry({
              card,
              senderId,
            });
            if (isExpectedError(cardGiftOrError)) {
              throw new Error(cardGiftOrError.errorMessage);
            }
            this._cardGift = cardGiftOrError as GiftCardInitInfo;
          } catch (e) {
            console.error(e);
            app.track('JoinGiftExpired', {
              gift_name: card || 'unknown',
            });
            this._expiredGift = card;
          }
        }
      }
    } else {
      app.track('JoinGiftExpired', {
        gift_name: card || 'unknown',
      });
      this._expiredGift = card;
    }
  };

  private handleMinePromoEntry = async (
    payload: AnalyticsProperties.EntryData,
  ) => {
    const today = getDayMidnightInUTC(this.now());
    const card = payload.payload.card;
    const powerUpCard = getActivePowerUpById(card);

    if (!powerUpCard) {
      console.error(`Invalid card: ${card}`);
      return;
    }

    const availablePowerup = getPowerUps(this.replicant.state, this.now()).find(
      (item) =>
        item.id === card &&
        item.specialState === 'Available' &&
        item.type === PowerUpCardType.HIDDEN,
    );

    const isPromoAvailable = availablePowerup !== undefined;
    if (isPromoAvailable) {
      try {
        const cardPromoOrError = await this.invoke.handleMinePromoEntry({
          card,
        });
        if (isExpectedError(cardPromoOrError)) {
          throw new Error(cardPromoOrError.errorMessage);
        }
        this._cardPromo = cardPromoOrError as PromoCardInitInfo;
      } catch (e) {
        console.error(e);
        app.track('PromoCardExpired', {
          card_name: card || 'unknown',
        });
      }
    } else {
      app.track('PromoCardExpired', {
        card_name: card || 'unknown',
      });
    }
  };

  public getABTest = (key: keyof typeof tests) => {
    return this.replicant.abTests.getBucketID(tests[key]) as string | undefined;
  };

  public getIsInAB = (
    key: keyof typeof tests,
    ab: string | string[],
  ): boolean => {
    const abs = typeof ab === 'string' ? [ab] : ab;
    const test = this.getABTest(key);
    if (!test) {
      return false;
    }
    return abs.includes(test);
  };

  private gameStart = async () => {
    // decide if to start a tutorial
    const tutorialEnabled = true; // global flag in case we want to disable all tutorials

    if (tutorialEnabled) {
      // We must be in the A/B and it must be the firstSession ever or if we have started before
      const tutorial = getFTUE(this);

      const showTutorial =
        qpConfig.testTutorial ||
        (tutorial &&
          (this.firstEverSession ||
            this.state.tutorials[tutorial] !== undefined));

      if (showTutorial && tutorial) {
        // start ftue
        this.tutorial.startTutorial(tutorial);
      }
    }

    // decide if to display daily code completed state
    if (getHasCompletedDailyCode(this.state)) {
      this.ui.setClickerUIState({ btnDailyCode: ElementUIState.Complete });
    }

    // decide if to display badges
    if (
      !this.isFirstSession &&
      !this.realFirstEverSession &&
      !this.tutorial.active
    ) {
      await gameApi.addBadges();
    }

    // decide if to display initial modal sequence
    this.handleAppInitModals();
  };

  // @TODO: Maybe move this to UIController?
  private handleAppInitModals = async () => {
    if (
      this._criticalError ||
      this.tutorial.active ||
      qpConfig.skipInitModals
    ) {
      return;
    }

    await waitFor(500);

    if (this.ui.state.suspendInitialModals) {
      await this.ui.waitForInitialModalsSuspense;
    }

    // show season kick-off before any drawer
    if (this.state.season !== SEASON) {
      const previousSeason = SEASON - 1;
      const previousSeasonIdx = previousSeason - 1;
      const previousSeasonScore = this.state.seasonScores[previousSeasonIdx];
      const previousSeasonLeague = this.state.seasonLeagues[
        previousSeasonIdx
      ] as League;

      if (previousSeasonLeague) {
        const reward =
          SEASON_LEAGUE_REWARDS[previousSeason][previousSeasonLeague];
        await this.tutorial.startTutorial(Tutorials.SlideshowSeasonKickOff, {
          substitutes: {
            season: SEASON.toString(),
            previousSeason: previousSeason.toString(),
            score: previousSeasonScore!.toString(),
            reward: largeIntegerToLetter(reward),
          },
        });
      }
    } else {
      if (this.state.labels.includes(RESET_SEASON_2_LABEL)) {
        this.ui.drawer.show({
          id: 'generic',
          opts: {
            title: t('drawer_s2_reset_title'),
            image: assets.sloth_reporter,
            subtitle: `${t('drawer_s2_reset_description')}\n${t(
              'drawer_s2_reset_reward',
            )}`,
            buttons: [
              {
                cta: t('drawer_s2_reset_cta'),
                onClick: () => {
                  this.invoke.removeLabels({ labels: [RESET_SEASON_2_LABEL] });
                  this.ui.drawer.close();
                },
              },
            ],
          },
        });
      }
    }

    if (
      this.state.labels.includes(ModalLabels.SHOW_MEME_TOKEN_GIFT_MODAL) &&
      this.state.trading.giftTokenId
    ) {
      this.asyncGetters
        .getOffchainTokensFromOpenSearch({
          offchainTokenIds: [this.state.trading.giftTokenId],
        })
        .then(([token]) => {
          if (!token) {
            return;
          }

          // meme_token_gift_1_description
          const tKeyPrefix = `meme_token_gift_3_`;

          this.ui.drawer.show({
            id: 'generic',
            opts: {
              title: t(`${tKeyPrefix}title`),
              image: token.profile.image,
              subtitle: t(`${tKeyPrefix}description`, {
                tokenName: token.profile.name,
              }),
              buttons: [
                {
                  cta: t('trading_coming_soon_button'),
                  onClick: () => {
                    this.invoke.removeLabels({
                      labels: [ModalLabels.SHOW_MEME_TOKEN_GIFT_MODAL],
                    });
                    this.ui.drawer.close();
                    app.nav.goTo('TradingPage');
                  },
                },
              ],
            },
          });
        });
    }

    if (this.isFirstSession) {
      if (this.cardPromo) {
        this.ui.drawer.show({ id: 'minePromo' });
      } else {
        this.ui.drawer.show({ id: 'welcome' });
      }
    }
    if (this.expiredGift) {
      this.ui.drawer.show({ id: 'mineGiftExpired' });
    }
    if (
      !app.isFirstSession &&
      (this.botEarnings || this.powerUpBonus || this.unclaimedReferralRewards)
    ) {
      this.ui.drawer.show({
        id: 'rewardSummary',
        hideClose: true,
        onClose: () => {
          this.ui.confetti.hide();
        },
      });
    }

    const unpromotedQuests = getUnpromotedQuests(this.state, this.now());
    if (!this.isFirstSession && unpromotedQuests.length > 0) {
      const [questConfig] = unpromotedQuests;
      this.ui.showPromoDrawer(questConfig, 'initial');
    }

    if (!this.isFirstSession && this.cardPromo) {
      this.ui.drawer.show({ id: 'minePromo' });
    }
    if (this.cardGift) {
      this.ui.drawer.show({ id: 'mineGiftReceived' });
    }
    if (this.inviteDrawerDuration > 0) {
      this.ui.drawer.show({ id: 'inviteFriend' });
    }

    if (this.isFirstSessionOfTheDay && this.getDailyAirdrop()) {
      this.ui.drawer.show({ id: 'multipleGifts' });
    }

    // tiktok teaser 1

    if (this.state.labels.includes(ModalLabels.SHOW_TIKTOK_TEASER_MODAL)) {
      if (!isTiktokEnabled()) {
        this.ui.drawer.show({
          id: 'generic',
          opts: {
            title: t('tiktok_teaser_title'),
            image: assets.tiktok_teaser,
            subtitle: `${t('tiktok_teaser_description')}`,
            subtitle2: `${t('tiktok_teaser_subtitle2')}`,
            buttons: [
              {
                cta: t('tiktok_teaser_cta'),
                onClick: () => {
                  this.ui.drawer.close();
                },
              },
            ],
          },
        });
      }

      this.invoke.removeLabels({
        labels: [ModalLabels.SHOW_TIKTOK_TEASER_MODAL],
      });
    }

    const isTikTokOnlyExperience = app.getIsInAB(
      'TEST_TIKTOK_ONLY',
      'tiktok_only_experience',
    );

    // tiktok teaser 2
    const showTeaser2 =
      this.state.labels.includes(ModalLabels.SHOW_TIKTOK_TEASER_MODAL_2) &&
      isTiktokEnabled() &&
      !isTikTokOnlyExperience;

    if (showTeaser2) {
      const isShowingFarming = getTMGFarmingIsShowing(this.state);
      const subtitleKey = isShowingFarming
        ? 'tiktok_teaser_2_description'
        : 'tiktok_teaser_2_description_b';
      this.ui.drawer.show({
        id: 'generic',
        opts: {
          title: t('tiktok_teaser_2_title'),
          image: assets.tiktok_teaser,
          subtitle: `${t(subtitleKey)}`,
          isSubtitleLeft: true,
          buttons: [
            {
              cta: t('tiktok_teaser_2_cta'),
              onClick: () => {
                this.ui.drawer.close();
              },
            },
          ],
        },
      });

      this.invoke.removeLabels({
        labels: [ModalLabels.SHOW_TIKTOK_TEASER_MODAL_2],
      });
    }
  };

  initLanguage(languageCode: string) {
    const supportedLanguages = ['en', 'es', 'fa', 'id', 'pt', 'ru', 'tr', 'uz'];
    const locale = supportedLanguages.includes(languageCode)
      ? languageCode
      : 'en';

    i18n.changeLanguage(locale);

    const rtlLanguages = ['ar', 'fa', 'he'];
    const isRtl = rtlLanguages.includes(locale);
    if (isRtl) {
      document.documentElement.setAttribute('dir', 'rtl');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
    }

    return locale;
  }

  trackTaps(taps: number) {
    this.trackedTaps += taps;
    return this.trackedTaps;
  }

  track = (
    eventName: string,
    eventProps: Record<string, string | number | boolean | undefined> = {},
    userProps: Record<string, string | number | boolean | undefined> = {},
  ): void => {
    let realtimeUserProps: Record<string, string | number | boolean> = {};
    if (this.replicant?.state) {
      const state = this.replicant.state;
      const now = this.replicant.now();
      const powerUpStats = getOwnedPowerUpsStats(state, now);
      realtimeUserProps = {
        '#realtimeScore': state.score,
        '#realtimeBalance': getBalance(state, now),
        '#realtimeLeague': getLeague(state),
        '#realtimeEnergy': getEnergy(state, now),
        '#realtimeEarningsPerHour': Math.round(powerUpStats.bonusPerHour),
        '#realtimeEarningsPerSecond': Math.round(
          powerUpStats.bonusPerHour / 3600,
        ),
        '#realtimeCardsUnique': powerUpStats.uniqueCount,
        '#realtimeCardsTotal': powerUpStats.totalCount,
        '#realtimeCardsGear': powerUpStats.gearUniqueCount,
        '#realtimeCardsWorkers': powerUpStats.companionUniqueCount, // companion == worker
        '#realtimeCardsServices': powerUpStats.serviceUniqueCount,
        '#realtimeCardsSpecials': powerUpStats.specialUniqueCount,
        '#realtimeDailyReward': state.streak_days - state.unclaimed_rewards,
        '#realtimeOffchainTokenCount': Object.keys(state.trading.offchainTokens)
          .length,
        '#realtimeFollowerCount': state.followersCount,
        '#realtimeFollowingCount': state.followingsCount,
      };
    }
    analytics.pushEvent(eventName, eventProps, undefined, {
      ...realtimeUserProps,
      ...userProps,
    });
  };

  setUserProperties = (userProps: Record<string, number | string>): void => {
    analytics.setUserProperties(userProps);
  };

  onTap = () => {
    if (!this.player) {
      console.error(`Cannot do taps without player set`);
      return;
    }

    if (this.tutorial.step?.onClickerTap) {
      this.tutorial.step.onClickerTap();
    }

    if (getEnergy(this.state, app.now()) > 0) {
      this.replicant.invoke.tap();
    }

    // @note: needs to be done after the tap
    const isOutOfEnergy = getEnergy(this.state, app.now()) < 1;
    if (isOutOfEnergy) {
      this.tutorial.step?.onAction?.('outOfEnergy');
      this.ui.drawer.show({
        id: 'outOfEnergy',
        opts: {
          title: '',
          subtitle: '',
          buttons: [],
        },
      });
      return;
    }

    const pointsPerTap = getPointsPerTap(this.state, this.now()); // todo: this.now() might have been cause of desync

    this.tutorial.updateStepTapTrack(pointsPerTap);

    this.sendEvents(AppEvents.onGameStateUpdate);

    this.ui.onAppValueUpdate(UIEvents.OnBalanceUpdate);

    addClickerPointUp(pointsPerTap, Boolean(this.bonus));
  };

  joinTeam = async (teamId: string) => {
    const response = await gameApi.joinTeam(this.playerId, teamId);

    this.setPlayerTeam(response.team);
    this.setPlayer(response.player);
    this.sendEvents(AppEvents.onMyTeamUpdate);
    // this.components.TeamPage.fetch(teamId);
    this.nav.goToHomePage();
  };

  leaveTeam = async () => {
    const response = await gameApi.leaveTeam(this.playerId);

    this.setPlayerTeam(undefined);
    this.setPlayer(response.player);
    this.sendEvents(AppEvents.onMyTeamUpdate);
    // this.components.TeamPage.fetch(currenTeamId);
    this.nav.goToHomePage();
    this.tutorial.step?.onAction && this.tutorial.step?.onAction('leaveTeam');
  };

  // Gets called every second
  private onSecondElapsed = () => {
    this.sendEvents(AppEvents.onGameStateUpdate);

    if (this.bonus && this.bonus.timeLeft <= 0) {
      this.setRocketman(false);
    }
  };

  private setPlayer = (player: PlayerGame, isStart = false) => {
    this.player = player;
    if (!this.isRocketmanActive && player.rocketmanMultiplier > 1) {
      this.setRocketman(true);
    }
  };

  public setPlayerTeam = (team?: Team) => {
    this._playerTeam = team;
    this.sendEvents(AppEvents.onMyTeamUpdate);
  };

  private setRocketman = (start: boolean) => {
    this.isRocketmanActive = start;
    this.sendEvents(AppEvents.onRocketmanChange);
  };

  buyBooster = async (booster: Booster) => {
    const response = await gameApi.buyBooster(this.playerId, booster);
    if (isExpectedError(response)) {
      return response;
    }
    app.views.Toast.setData({ text: t(boosterConfig[booster].name) });
    this.setPlayer(response.player, true);
    this.views.Shop.fetch();
    app.views.Toast.show(false);
    return response.player.balance;
  };

  buyBuff = async (buff: Buff) => {
    const response = await gameApi.buyBuff(this.playerId, buff);
    if (isExpectedError(response)) {
      return response;
    }
    app.views.Toast.setData({ text: t(boosterConfig[buff].name) });
    this.nav.goToHomePage();
    this.setPlayer(response.player, true);
    this.views.Shop.fetch();
    app.views.Toast.show(false);
    if (buff === Buff.Rocketman && this.tutorial.step?.onAction) {
      this.tutorial.step?.onAction('rocketman');
    }
  };

  getFriends = async () => {
    // const start = Date.now();
    const friends = await socialApi.getFriends();
    // console.error('Friends in ', Date.now() - start)
    return friends;
  };
  getEarnPageData = async () => {
    return {
      friendCount: this.state.friendCount,
    };
  };

  fetchLeaguePageData = async (league: League) => {
    const res = await gameApi.getLeagueLeaderboard(league, this.playerId);
    return res as LeagueLeaderboard;
  };

  getShareUrl(payloadKey: string, inviteText: string): string {
    const referralUrl = getReferralUrl(payloadKey, this.replicant.state);

    const url = encodeURIComponent(referralUrl);
    const text = encodeURIComponent(inviteText);

    return `https://t.me/share/url?url=${url}&text=${text}`;
  }

  getMineGiftUrl(payloadKey: string, card: string): string {
    const referralUrl = getReferralUrl(payloadKey);
    // always english name since gift message is english
    const cardName = getActivePowerUpById(card)?.name;

    const url = encodeURIComponent(referralUrl);
    const text = encodeURIComponent(getGiftMessage(this.state, cardName));

    return `https://t.me/share/url?url=${url}&text=${text}`;
  }

  private getSortedGifts = () => {
    return getPowerUps(this.replicant.state, this.now())
      .filter(
        (item) =>
          item.isGift &&
          item.endTime !== undefined &&
          item.startTime !== undefined &&
          this.now() < item.endTime &&
          this.now() >= item.startTime,
      )
      .sort((a, b) => (b.startTime ?? 0) - (a.startTime ?? 0));
  };

  getDailyAirdrop = () => {
    return this.getSortedGifts()[0];
  };

  getMultipleGifts = (amount: number) => {
    return this.getSortedGifts().slice(0, amount);
  };

  showLeagueLeaderboad = () => {
    this.views.LeaguePage.fetch(this.league);
    this.nav.goTo('LeaguePage');
  };

  get realFirstEverSession() {
    return this.firstEverSession;
  }

  initPowerUpSpecials = async () => {
    await this.replicant.invoke.initPowerUpSpecials();
    this.sendEvents(AppEvents.onGameStateUpdate);
  };

  // temporary: if A/B will be resolved for this bucket
  // we will keep this in state
  // but we will need to do a migration etc
  private _clickedOnAction = {
    followOnX: false,
    followOnXJW: false,
    joinCommunity: false,
    followOnYoutube: false,
    joinAnnouncement: false,
  };
  get clickedOnAction() {
    return { ...this._clickedOnAction };
  }

  setClickedOnAction(action: keyof typeof this._clickedOnAction) {
    this._clickedOnAction[action] = true;
  }
  maybeFixBrokenWalletConnectQuest = async () => {
    // we check the earnings flag against the existence of a wallet here
    await this.replicantClientPromise;

    const walletConnectQuestDone = this.replicant.state.earnings.walletConnect;
    const stateWalletConnected = this.replicant.state.wallet.length > 0;
    if (!stateWalletConnected && walletConnectQuestDone) {
      // wallet is not connected but quest was completed, so reset
      await this.replicant.invoke.resetEarningWalletConnect();
    }
  };

  /**
   * Searches team by name. If `name` is empty or just spaces, this will return undefined.
   * @param name The search term.
   * @returns array of Team, or undefined if `name` is empty or just spaces.
   */
  searchTeams = async (name: string): Promise<Team[] | undefined> => {
    if (!this.replicant) {
      return undefined;
    }

    const trimmedName = name.trim();
    if (!trimmedName) {
      return undefined;
    }

    if (!this.searchTrackedThisSession && trimmedName.length >= 3) {
      this.track('SearchTeamInSession', {
        feature: 'search',
        $subfeature: 'search_team',
        originFeature: 'team',
        originSubFeature: 'team_view',
      });
      this.searchTrackedThisSession = true;
    }

    const teams = await this.replicant.asyncGetters.searchTeams({
      searchString: trimmedName,
    });
    return teams as Team[];
  };

  onMorseInput = (code: '-' | '.') => {
    if (this.morseWin) {
      return;
    }
    addMorseUp(code);
    clearInterval(this.morseTimer);
    this.morseCode += code;
    this.morseTimer = setTimeout(() => {
      this.ui.setClickerUIState({ btnClicker: ElementUIState.Inactive });
      this.verifyMorseCode();

      // telemetry when user taps morse code for the first time in the session
      if (!this.hasTappedMorseOnce) {
        app.track('tap_morse', {});
        this.hasTappedMorseOnce = true;
      }
    }, morseTimes.codeEndDelay);
  };

  private verifyMorseCode = async () => {
    const code = morseCodeAlphabet[this.morseCode];
    const dailyCode = getDailyCode(this.now());
    if (dailyCode && code !== undefined) {
      const expectedLetter = dailyCode[this.morseWord.length];
      const isCorrect = code.toLowerCase() === expectedLetter.toLowerCase();
      if (isCorrect) {
        this.morseWord += code;
        // telemetry when user successfully adds one letter to the code
        app.track('tap_letter', {});
      } else {
        this.morseWord = '';
      }
      const correctWord =
        this.morseWord.toLowerCase() === dailyCode.toLowerCase();

      if (correctWord) {
        const confirmed = await this.invoke.onDailyCodeComplete({
          code: this.morseWord,
        });

        if (confirmed) {
          this.morseWin = true;

          this.ui.setClickerUIState({
            btnClicker: ElementUIState.Inactive,
            btnDailyCode: ElementUIState.Complete,
          });

          this.toggleDailyCodeActive(true);
          this.ui.confetti.show();
          this.morseCodeMode = false;

          this.ui.drawer.show({
            id: 'generic',
            opts: {
              image: assets.sloth_hacker,
              title: 'You win',
              subtitle: 'you solved the code',
              buttons: [
                {
                  cta: 'claim prize',
                  onClick: () => {
                    this.ui.animateBalance(DAILY_CODE_PRIZE);
                    this.ui.confetti.hide();
                    this.ui.drawer.close();
                  },
                },
              ],
            },
            onClose: () => {
              this.ui.confetti.hide();
            },
          });

          // telemetry when user successfully solves the code
          app.track('code_solved', {});
        } else {
          // Handle wrong server response
        }
      }
    }
    setTimeout(() => {
      this.morseCode = '';
      this.ui.setClickerUIState({ btnClicker: ElementUIState.Normal });
    }, 100);
  };

  toggleDailyCodeActive = (forceOff = false) => {
    // Trying to turn off but it's already off
    const isAlreadyOff = !this.morseCodeMode && forceOff;
    const isDisabled =
      this.ui.state.clicker.dailyCode === ElementUIState.Disabled;
    if ((isDisabled && !forceOff) || isAlreadyOff) {
      return;
    }

    this.morseCodeMode = forceOff ? false : !this.morseCodeMode;
    this.morseCode = '';
    this.morseWord = '';
    this.ui.setClickerUIState({
      dailyCode: this.morseCodeMode
        ? ElementUIState.Normal
        : ElementUIState.Remove,
    });

    this.sendEvents(AppEvents.onAppModeChange);

    // telemetry when user activates or deactivates the daily code mode
    if (this.morseCodeMode) {
      this.track('activate_daily_code', {
        feature: 'daily_code',
        $subfeature: 'daily_code_morse',
        originFeature: 'home',
      });
    } else {
      this.track('deactivate_daily_code’', {
        feature: 'daily_code',
        $subfeature: 'daily_code_morse',
        originFeature: 'home',
      });
    }
  };

  getIAPId = (cfg: IAPConfig) => {
    return `${cfg.productId}_${this.playerId}`;
  };
}

export const app = new AppController(qpConfig.playerId);
export type Route = keyof typeof app.views;

configureExtensions({
  analytics,
  gcinstant,
  replicantClientPromise: app.replicantClientPromise,
});

// Configure a payload encoder with shorter payload keys to comply with Telegram bot link limits: https://core.telegram.org/api/links#bot-links
const generatePayloadKey = (): string => {
  return generateUserPayloadKey(app.replicant.userId);
};

export const payloadEncoder = createPayloadEncoder(
  () => app.replicant,
  analytics,
  { generatePayloadKey },
);

gcinstant.setDataCodec(payloadEncoder); // Must be called after configureExtensions!

// ngrok http --domain=cai-privy-server.ngrok.dev 8080

if (process.env.REACT_APP_ENV !== 'prod') {
  (window as any).app = app;
  (window as any).gcinstant = gcinstant;
}
