import './DrawerTradingCreateConfirm.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app, useAppUpdates } from '../../../../data/AppController';
import { useEffect, useRef, useState } from 'react';
import { ButtonBack } from '../../../shared/ButtonBack/ButtonBack';
import { TradingTokenFooter } from '../../TradingTokenPage/TradingTokenFooter/TradingTokenFooter';
import { insertTelegramStarIconIntoText } from '../../../../data/utils';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';

export const DrawerTradingCreateConfirm = () => {
  useAppUpdates({
    id: 'DrawerTradingCreateConfirm',
    listener: app.memes.attachEventListener(MemesEvents.TradingOnTxUpdate),
  });

  const { t } = useTranslation();

  const inputData = app.memes.factory.newTokenForm.data;

  const starAmount = app.memes.factory.starsPrice;

  const imgPreviewRef = useRef<any>();
  const [previewVerticalAspectRatio, setPreviewVerticalAspectRatio] =
    useState(false);

  // make telegram back button close the drawer instead of navigating back
  useEffect(() => {
    app.nav.showingBlockingModal();
  }, []);

  useEffect(() => {
    if (!inputData) {
      // @TODO: Show some generic error?
      // @TODO: close modal
    }

    if (inputData) {
      if (imgPreviewRef.current) {
        const isVertical =
          imgPreviewRef.current.naturalWidth <
          imgPreviewRef.current.naturalHeight;
        setPreviewVerticalAspectRatio(isVertical);
      }
    }
  }, [inputData]);

  const noCoins = app.state.balance <= 0;

  const title = noCoins
    ? t('trading_create_title_no_coins')
    : t('trading_create_title', { tokenName: inputData?.name });
  const tipTranslation = noCoins
    ? t('trading_create_tip_no_coins', { starAmount })
    : t('trading_create_tip', { starAmount });
  const tipHtml = insertTelegramStarIconIntoText(tipTranslation);

  return (
    <div className="trading-create-confirm">
      <ButtonBack />

      <div className="drawer-layout trading-create-confirm">
        <div className="drawer-header">
          {/* <div className="radial" /> */}

          <div className="trading-token-container">
            <div className="trading-token-frame">
              <img src={assets.trading_token_frame} />

              <div className="trading-token-image">
                <img
                  ref={imgPreviewRef}
                  src={inputData?.image || assets.icon_camera}
                  style={{
                    width: previewVerticalAspectRatio ? '100%' : 'unset',
                    height: previewVerticalAspectRatio ? 'unset' : '100%',
                  }}
                />
              </div>

              {/* ticker name */}
              <div className="trading-ticker-name">{inputData?.ticker}</div>
            </div>
          </div>

          <div className="info">
            {/* title and tip */}
            <div className="title">{title}</div>
            <div
              className="tip"
              dangerouslySetInnerHTML={{ __html: tipHtml }}
            ></div>
          </div>
        </div>

        <TradingTokenFooter footerMode={'create'} />
      </div>
    </div>
  );
};
