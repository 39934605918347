import { AppController } from './AppController';
import { Fn } from './types';

interface Opts<TOpenOpts = any> {
  onOpen?: (openOpts?: TOpenOpts) => void;
  onClose?: Fn<void>;
  onFetchComplete?: Fn<void>;
  debug?: boolean;
  startVisible?: boolean;
}

export class View<T, TShowOpts = any> {
  private onUpdateListener?: Fn<void>;

  private onVisibilityUpdateListner?: Fn<void>;

  private _visible = false;

  get visible() {
    return this._visible;
  }

  private _loading = false;

  get loading() {
    return this._loading;
  }

  private _data?: T;

  get data() {
    return this._data;
  }

  constructor(
    private id: string,
    private app: AppController,
    private fetcher: (props?: any) => Promise<T>,
    private opts?: Opts<TShowOpts>,
  ) {
    this._visible = Boolean(opts?.startVisible);
  }

  private onUpdate = () => {
    if (this.onUpdateListener) {
      this.onUpdateListener();
    } else {
      console.warn(
        `Component '${this.id}' onUpdate called. But no listeners set.`,
      );
    }
  };

  fetch = (props?: any) => {
    // Avoid making requests already in flight
    if (this._loading) {
      return;
    }
    this._loading = true;
    this.fetcher(props)
      .then((r) => {
        if (this.opts?.debug) {
          console.log(`Component '${this.id}' fetch.`, { props });
        }
        this._data = r;
      })
      .catch((e) => {
        console.error(`Component '${this.id}' fetch failed.`);
        console.error(e);
      })
      .finally(() => {
        this._loading = false;
        this.onUpdate();
        this.opts?.onFetchComplete && this.opts.onFetchComplete();
      });
    this.onUpdate();
  };

  private setVisible = (visibile: boolean) => {
    this._visible = visibile;
    this.onVisibilityUpdateListner &&
      this.onVisibilityUpdateListner(this._visible);
    this.onUpdate();
  };

  show = (showOpts?: TShowOpts) => {
    if (this._visible) {
      return;
    }
    // Make sure we deactivate daily code on navigation
    this.app.toggleDailyCodeActive(true);
    if (this.opts?.onOpen) {
      this.opts?.onOpen(showOpts);
    }
    this.setVisible(true);
    return this;
  };

  hide = (clearData = false) => {
    if (!this._visible) {
      return;
    }
    if (this.opts?.onClose) {
      this.opts?.onClose();
    }
    this.setVisible(false);
    if (clearData) {
      this._data = undefined;
    }
    return this;
  };

  clearData = () => {
    this._data = undefined;
  };

  attachEventListener = () => (callback: Fn<void>) => {
    this.onUpdateListener = callback;
    return () => {
      this.onUpdateListener = undefined;
    };
  };

  // An external way of setting data if neede
  setData = <K>(data: T | K) => {
    this._data = data as T;
    this.onUpdate();
    return this;
  };

  rerender = () => {
    this.onUpdate();
  };

  onVisibilityChange = (cb: (visibility: boolean) => void) => {
    this.onVisibilityUpdateListner = cb;
    return () => {
      this.onVisibilityUpdateListner = undefined;
    };
  };
}
