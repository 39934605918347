import { tutorialModules } from '../modules';
import { AppController } from '../../AppController';

// V2

// const initialTutorialSteps: (typeof TutorialModule)[] = [
// EnergyRecovery, // wait 5 seconds after
// WaitFiveSeconds,
// WaitForCoinTaps,
// YourEnergyIsLow,
// FullEnergyState,
// WaitFiveSeconds,
// FriendEqualsCoins,
// YouAreDoingGreat15k,
// tutorialModules.Grant1kState,
// MineButton,
// MinePageCard,
// MineCardFriends,
// FriendsButton,
// FriendsPage,
// MoreCoins15k,
// tutorialModules.Grant1_5kState,
// AnimateBalance15k,
// TeamsButton,
// TeamsPage,
// FullEnergy,
// FullEnergyState,
// BoosterButton,
// BoosterRocketman,
// ClickerOnly22s,
// EarnButton,
// EarnPage,
// FreeGiftRocketman,
// RechargeRockermanState,
// LeagueLevel2,
// ShowMineBadgeState,
// WaitForTapsOrTimeout,
// NextCard,
// HideMineBadgeState,
// ];

const abToConfig = {
  '5-10': {
    tooltipTaps: 5,
    promptTaps: 10,
  },
  '5-25': {
    tooltipTaps: 5,
    promptTaps: 25,
  },
  '25-10': {
    tooltipTaps: 25,
    promptTaps: 10,
  },
  '25-50': {
    tooltipTaps: 25,
    promptTaps: 50,
  },
};

// V3

export const getInitialTutorialSteps = (app: AppController) => {
  const ab = false; //app.getABTest('TEST_FTUE_2') as keyof typeof abToConfig;

  if (!ab) {
    throw new Error(`Trying to get tutorial steps without AB value`);
  }

  const { tooltipTaps, promptTaps } = abToConfig[ab];

  return [
    // tutorial steps V4
    // -----------------
    // clicker
    new tutorialModules.IntroducingClicker(app, tooltipTaps), // number of taps driven by AB Test

    // energy
    new tutorialModules.EnergyRecovery(app),
    new tutorialModules.WaitForCoinTaps(app, promptTaps), // numberof taps driven by AB Test
    new tutorialModules.YourEnergyIsLow(app),
    new tutorialModules.FullEnergyState(app),

    // friends
    new tutorialModules.WaitForTapsOrTimeout(app, 5, 5000),
    new tutorialModules.FriendEqualsCoins(app),

    // booster
    new tutorialModules.BoosterButton(app),
    new tutorialModules.BoosterRocketman(app),
    new tutorialModules.ClickerOnly22s(app),

    // friends
    new tutorialModules.FriendsButton(app),
    new tutorialModules.FriendsPage(app),

    // you are doing great (get 1500 coins)
    new tutorialModules.YouAreDoingGreat15k(app),
    new tutorialModules.Grant1500State(app),

    // mine (spend 1500 coins)
    new tutorialModules.MineButton(app),
    new tutorialModules.MinePageCard(app),

    // earn (potentially get 1000 coins)
    new tutorialModules.EarnButton(app),
    new tutorialModules.EarnPage(app),

    // teams
    new tutorialModules.TeamsButton(app),
    new tutorialModules.TeamsPage(app),

    // next card
    new tutorialModules.NextCard(app),

    // league level up
    new tutorialModules.LeagueLevel2(app),
    new tutorialModules.LeagueButton(app),
    new tutorialModules.LeaguePage(app),

    // end of tutorial (get 1500 coins)
    new tutorialModules.EndOfTutorial(app),
    new tutorialModules.Grant1500State(app),
    new tutorialModules.AnimateBalance1500(app),

    // -----------------
  ];
};
