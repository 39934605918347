import { assets } from '../../assets/assets';
import './Popup.scss';
import { app, useAppUpdates } from '../../data/AppController';
import { Animated } from '../shared/Animated/Animated';

export const Popup = () => {
  useAppUpdates({
    id: 'Popup',
    listener: app.ui.popup.view.attachEventListener(),
  });

  const { visible, hide, data } = app.ui.popup.view;

  // const onClose = () => {
  //   hide();
  // };

  return (
    <Animated visible={visible}>
      <div className="popup">
        <div className="pop-content">
          {/* <img
              className="modal-button-close"
              src={assets.button_x}
              onClick={onClose}
          /> */}
          <div className="pop-title">{data?.title}</div>
          <div className="pop-description">{data?.description}</div>

          {data?.score && (
            <div className="pop-score">
              <div className="icon">
                <img src={assets.coin} />
              </div>
              <div className="text">{data?.score}</div>
            </div>
          )}

          <div className="pop-footer">
            <div className="btn btn-normal pop-cta" onClick={data?.onClick}>
              {data?.cta}
            </div>
          </div>
        </div>
      </div>
    </Animated>
  );
};
