import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../config';
import { MutableState } from '../schema';
import { migrateFriendIdsToSubstate } from '../features/friends/friends.modifiers';

export function migrateFriendIdsToFriendsSubstate(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  return migrateFriendIdsToSubstate(state, api, false);
}
