import { useTranslation } from 'react-i18next';
import { app } from '../../../../data/AppController';
import { sendMineCardGift } from '../../../../data/sharing';
import {
  PowerUp,
  PowerUpCardType,
} from '../../../../replicant/features/powerups/types';
import { ButtonInvite } from '../../../shared/ButtonInvite/ButtonInvite';
import { isExpectedError } from '../../../../replicant/types';

interface Props {
  powerup: PowerUp;
  onDailyFound?: (pu: PowerUp) => void;
}

export const DPUFooter = ({ powerup, onDailyFound }: Props) => {
  const { t } = useTranslation();

  const canBuy = powerup ? app.playerBalance >= powerup.cost : false;

  const onGetPowerUpClick = async () => {
    if (!canBuy) {
      return;
    }
    try {
      const pu = powerup;
      const id = powerup?.id;
      if (id) {
        // buy powerup
        const responseOrError = await app.invoke.buyPowerUp({ id });
        if (isExpectedError(responseOrError)) {
          throw new Error(responseOrError.errorMessage);
        }
        const { isDaily } = responseOrError;
        app.track('card_purchase', {
          card_id: pu.id,
          amount_spent: pu.cost,
          card_name: pu.details.name,
          level: pu.level,
          daily_booster: isDaily,
        });
        // update the powerUps listing
        app.views.MinePage.fetch();
        if (isDaily) {
          onDailyFound && onDailyFound(powerup);
        }
      }
    } catch (e) {
      console.error('on get pu click error:', e);
    } finally {
      if (powerup.id === 'pu_specials_tutorial_1') {
        app.tutorial.step?.onAction?.('onTutorialMineCardBuy');
      }
      app.ui.drawer.close();
    }
  };

  const onSendGift = () => {
    app.track('SendGift', {
      card: powerup.id,
      gift_name: powerup.id,
    });
    sendMineCardGift({
      card: powerup.id,
      originFeature: 'daily',
      originSubFeature: 'daily_powerup',
    });
    close();
  };

  const ended: boolean = Boolean(
    powerup.endTime && app.now() > powerup.endTime,
  );

  let showGetBtn = true;
  if (powerup?.isGift) {
    if (powerup.type === PowerUpCardType.GIFT_ONLY) {
      showGetBtn = false;
    } else {
      showGetBtn = powerup.level > 0;
    }
  } else if (powerup?.isFriendGated) {
    showGetBtn = !powerup.locked;
  }

  return (
    <div className="footer-buttons">
      {powerup?.isGift && !ended && (
        <div className={`btn btn-normal`} onClick={onSendGift}>
          {t('pup_send_gift')}
        </div>
      )}
      {powerup?.locked && powerup?.isFriendGated && (
        <ButtonInvite
          caption={t('friends_invite_button')}
          feature="friend"
          subFeature={`drawer_powerup_${powerup.id}`}
          showCopyBtn={true}
        />
      )}
      {showGetBtn && (
        <div
          className={`btn btn-normal ${!canBuy && 'disabled'}`}
          onClick={onGetPowerUpClick}
        >
          {t('pup_get')}
        </div>
      )}
    </div>
  );
};
