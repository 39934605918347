import { useEffect, useState } from 'react';
import { assets } from '../../../../assets/assets';
import { AppEvents, app, useAppUpdates } from '../../../../data/AppController';
import { getUIStateClassName, msToFullTime } from '../../../../data/utils';
import './DailyMenu.scss';
import { getTimeUntilNextDay } from '../../../../replicant/utils/time';
import { ElementUIState } from '../../../../data/Controllers/UIController/UIController';

export const DailyCode = () => {
  useAppUpdates({
    id: 'DailyCode/appMode',
    listener: app.attachEventListener(AppEvents.onAppModeChange),
  });

  const [inputIndicator, setInputIndicator] = useState('|');

  useEffect(() => {
    if (inputIndicator === '|') {
      setTimeout(() => {
        setInputIndicator(' ');
      }, 200);
    } else {
      setTimeout(() => {
        setInputIndicator('|');
      }, 200);
    }
  }, [inputIndicator]);

  const time = msToFullTime(getTimeUntilNextDay(app.now(), 'pst'));

  const uiState = app.ui.state.clicker.dailyCode;
  const uiClassName = getUIStateClassName(app.ui.state.clicker.dailyCode);

  return (
    <div className={`daily-code ${uiClassName}`}>
      {uiState === ElementUIState.Normal && (
        <>
          <div className="dc-title">
            Daily Code
            <br />
            {time}
          </div>
          <div className="dc-input">
            {app.morseWord}
            {inputIndicator}
          </div>
          <div className="dc-prize">
            <img src={assets.coin} />
            +1.000.000
          </div>
        </>
      )}
    </div>
  );
};
