import Big from 'big.js';
import {
  getMyOffchainTokenTokenAmount,
  TradingToken,
} from '../../../replicant/features/offchainTrading/offchainTrading.getters';
import { TradingTokenListing } from '../../../replicant/features/offchainTrading/types';
import { MIN_IN_MS } from '../../../replicant/utils/time';
import { AppController } from '../../AppController';
import { EventListener } from '../../EventListener';
import { Fn, Optional } from '../../types';
import {
  SearchFilters,
  GetTokenStrategy,
  MemeFilters,
  UserMemeFilters,
} from './types';
import { TradingController } from './TradingController';
import { OwnedOffchainToken } from '../../../replicant/features/game/player.schema';
import { analytics } from '@play-co/gcinstant';
import { delay } from '@play-co/replicant/lib/core/utils/AsyncUtils';
import { shareDeeplink } from '../../sharing';
import { TransactionSuccess } from '../UIController/UIController';
import { TokenFactory } from './MemeFactory';
import { isTiktokEnabled } from '../../utils';
import { MemeListController } from './MemeListsController';
import { SearchableMemeList } from './SearchableMemeList';
import { UserMemeList } from './UserMemeList';
import { ProfileEvents } from '../ProfileController';
import { MemeFeedAdController, MemeFeedAds } from './MemeFeedAds';
import { tests } from '../../../replicant/ruleset';

interface SetCurrentOpts {
  tokenId?: string;
  filter?: MemeFilters;
}

export type FeedItemConfig =
  | {
      type: 'meme';
      item: TradingTokenListing;
    }
  | {
      type: 'ad';
      item: MemeFeedAdController;
    };

export type MemeOverviewSourceCategory = 'navigation' | 'share';
export type MemeOverviewSourceName =
  | MemeFilters
  | 'swipe'
  | 'update'
  | 'profile-list'
  | 'feed-list'
  | 'holder-list'
  | 'feed'
  | 'share'
  | 'back'
  | 'creation';

interface MemeOverviewTrackingOpts {
  sourceCategory: MemeOverviewSourceCategory;
  sourceName: MemeOverviewSourceName;
  swipe?: 'up' | 'down';
  positionInFeed?: number;
}

const maxCacheValidity = 2 * MIN_IN_MS;
interface CacheControl {
  id: string;
  timestamp: number;
  data?: Record<string, any>;
}

export enum MemesEvents {
  OnReady = 'OnReady',
  OnUpdate = 'OnUpdate',
  // When the token is selected; via navigation or carousel
  OnTokenSelected = 'OnTokenSelected',
  // When are are updating the tx, i.e mode | amount | etc
  OnCurrentTokenUpdate = 'OnCurrentTokenUpdate',
  //
  OnListingUpdate = 'OnListingUpdate',
  OnFilterChange = 'OnFilterChange',

  // Trading
  TradingOnTxUpdate = 'TradingOnTxUpdate',
  TradingOnNotification = 'TradingOnNotification',
  TradingOnPortfolioUpdate = 'TradingOnPortfolioUpdate',
}

export class MemesController extends EventListener {
  private sendEventProxy = (evt: MemesEvents) => {
    this.sendEvents(evt);
  };

  private cache = {
    tokens: {} as Record<string, CacheControl>,
  };

  private tokens: Record<string, TradingToken> = {};

  private tokenFetchRequests: Record<
    string,
    Promise<TradingToken | undefined>
  > = {};

  private tokenSources: Record<string, MemeOverviewSourceCategory> = {};

  private currentTokenId?: string;

  private searchString: string = '';

  private referrerTokenId: string = '';

  private currentSlideIndex: number = 0;

  public memeFeedAds: MemeFeedAds = new MemeFeedAds();

  private readyResolver?: Fn<void>;
  private readyPromise = new Promise((resolve) => {
    this.readyResolver = resolve;
  });

  private lists = {
    market: new MemeListController<SearchFilters>(this.app, {
      id: 'market',
      sendEvent: this.sendEventProxy,
      filters: ['Hot', 'New', 'Top'],
      defaultFilter: 'Hot',
      MemeListClass: SearchableMemeList,
      prefetch: 'waitFirst',
    }),
    userMemes: new MemeListController<UserMemeFilters>(this.app, {
      id: 'userMemes',
      sendEvent: this.sendEventProxy,
      filters: ['Created', 'Owned', 'Farming'],
      defaultFilter: 'Created',
      MemeListClass: UserMemeList,
    }),
    myMemes: new MemeListController<UserMemeFilters>(this.app, {
      id: 'myMemes',
      sendEvent: this.sendEventProxy,
      filters: ['Created', 'Owned', 'Farming'],
      defaultFilter: 'Created',
      MemeListClass: UserMemeList,
      prefetch: 'async',
    }),
  };

  private selectedList: keyof typeof this.lists = 'market';

  public get market() {
    return this.lists.market;
  }
  public get userMemes() {
    return this.lists.userMemes;
  }
  public get myMemes() {
    return this.lists.myMemes;
  }

  public trading = new TradingController(this.app, this.sendEventProxy);

  public factory = new TokenFactory(this.app);

  public get currentMeme() {
    // Set all initial values to Optional<Type> or with its default
    const value = {
      token: undefined as Optional<TradingToken>,
      listing: undefined as Optional<TradingTokenListing>,
      isOwner: false,
      myTokenState: undefined as Optional<OwnedOffchainToken>,
    };
    // If not token is selected then return initial value
    if (!this.currentTokenId) {
      return value;
    }
    // Overwrite initial values and return
    value.token = this.tokens[this.currentTokenId];
    const item = this.currentList.getItem(this.currentTokenId);
    value.listing = item;
    value.isOwner = value.token?.creatorId === this.app.state.id;
    value.myTokenState =
      this.app.state.trading.offchainTokens[this.currentTokenId];
    return value;
  }

  public setCurrentSlideIndex(slideIndex: number) {
    this.currentSlideIndex = slideIndex;
  }

  public get slideIndex() {
    return this.currentSlideIndex;
  }

  public get currentFilter() {
    return this.currentList.filter;
  }

  public get currentList() {
    return this.lists[this.selectedList];
  }

  public get isReady() {
    return this.readyPromise;
  }

  public get myMemesCount() {
    return Object.keys(this.app.state.trading.offchainTokens).length;
  }

  constructor(private app: AppController) {
    super();
  }

  public init = async () => {
    if (!this.app.replicant) {
      console.warn(
        `MemesController: Trying to 'init' without replicant, abort.`,
      );
      return;
    }

    this.memeFeedAds.init();

    await this.trading.init();
    // No need to pre init 'userMemes'
    await this.market.init();
    // Select the first item in market
    this.setCurrent({});
    // Await until our profile is fetched to init my memes
    this.app.profile.isReady.then(() => {
      this.myMemes.init();
    });

    this.app.profile.addEventListener(ProfileEvents.OnUpdate, () => {
      if (this.app.profile.current?.isSelf === false) {
        this.userMemes.refresh();
      }
    });

    this.app.views.TiktokPage.onVisibilityChange((isShowing) => {
      if (isShowing) {
        this.currentList.refresh();
      }
    });

    this.readyResolver!();
    this.sendEventProxy(MemesEvents.OnReady);
  };

  public setReferredMeme = (tokenId: string) => {
    this.referrerTokenId = tokenId;
  };

  public getReferredTokenId = () => {
    return this.referrerTokenId;
  };

  private getListFirstItem = () => {
    return this.currentList.firstItem;
  };

  /**
   * @param list
   * @returns false if 'selectedList' has not changed value; true if it did
   */
  private setSelectedList = (list: typeof this.selectedList) => {
    if (this.selectedList === list) {
      return false;
    }
    this.selectedList = list;
    return true;
  };
  /**
   * @param nextFilter
   * @returns false if 'selectedList' and filter have not changed value; true if it did
   */
  private computeSelectedList = ({ tokenId, filter }: SetCurrentOpts) => {
    let newSelectedList = this.selectedList;
    if (filter) {
      if (this.market.getIsMyFilter(filter)) {
        newSelectedList = 'market';
      } else if (this.app.profile.current?.isSelf) {
        newSelectedList = 'myMemes';
      } else {
        newSelectedList = 'userMemes';
      }
    }
    // If we changed the selected list then it's dirty
    if (this.setSelectedList(newSelectedList)) {
      return true;
    }
    // Otherwise
    const isNotTheSameFilter = this.currentFilter !== filter;
    const isNotTheSameTokenId = this.currentTokenId !== tokenId;
    // if the filter or the token id has changed then it's dirty
    return isNotTheSameFilter || isNotTheSameTokenId;
  };

  // To be used by navigation and the carousel
  public setCurrent = async (
    opts: SetCurrentOpts,
    trackingOpts?: MemeOverviewTrackingOpts | undefined,
  ) => {
    const isDirty = this.computeSelectedList(opts);
    if (!isDirty) {
      return;
    }

    const { tokenId, filter } = opts;
    if (filter) {
      this.currentList.setFilter(filter);
      if (isDirty) {
        this.sendEventProxy(MemesEvents.OnFilterChange);
      }
      const nextTokenId = tokenId ?? this.getListFirstItem()?.offchainTokenId;
      if (nextTokenId) {
        await this.setToken(nextTokenId, trackingOpts);
      }
    } else {
      if (tokenId || !isTiktokEnabled()) {
        await this.setToken(tokenId, trackingOpts);
      }
    }

    // @TODO: what's this for again???
    // if (newToken) {
    //   this.trading.onCurrentTokenChange(await this.getToken())
    // }
    this.sendEvents(MemesEvents.OnTokenSelected);
  };

  /**
   * TEMP (this should be done with events)
   */
  public onTokenCreated = (tokenId: string) => {
    this.myMemes.refreshTargetList('Created');
    this.app.profile.refresh();

    setTimeout(() => {
      this.market.refresh();
    }, 300);
  };

  public onTokenBuyOrSell = (tokenId: string) => {
    this.myMemes.refreshTargetList('Owned');
  };

  public shareOffchainToken = async (
    screen_location: string,
    props: TransactionSuccess | undefined,
    isTiktok: Optional<'isTiktok'> = undefined,
    skipShare = false,
  ) => {
    if (!props) {
      return;
    }

    if (isTiktok) {
      this.app.replCollector.updateShare(props.offchainTokenId);
    }

    const myToken =
      this.app.state.trading.offchainTokens[props.offchainTokenId];
    const tokenBalance = myToken ? Big(myToken.tokenAmount).toNumber() : 0;

    this.app.track('memecard_share', {
      screen_location,
      memecard_name: props.offchainTokenName,
      token_balance: tokenBalance,
      cardID: props.offchainTokenId,
      action: props.mode,
    });

    await analytics.flush();

    // leave time to flush (just in case)
    await delay(500);

    return shareDeeplink('TradingTokenPage', {
      messageOpts: {
        title: props?.offchainTokenName as unknown as string,
        text: props?.offchainTokenDescription as unknown as string,
      },
      deeplinkOpts: this.app.nav.getDeeplinkOpts('TradingTokenPage'),
      skipShare,
    });
  };

  public searchToken = (searchTerm: string) => {
    this.searchString = searchTerm;
    this.currentList.search(this.searchString);
  };

  public getTokenState = (tokenId = this.currentMeme.token?.id) => {
    if (!tokenId) {
      return undefined;
    }
    return this.app.state.trading.offchainTokens[tokenId];
  };

  /**
   *
   * @param tokenId (optional) defaults to currentTokenId
   * @param strategy (`fetch`|`forceFetch`|`fetchAndUpdate`|`cacheOnly`) defaults to `cacheOnly`
   *
   * @strategy `fetch` - awaits for the token to be fetched then return it (respects cache ttl)
   *
   * @strategy `forceFetch` - aawaits for the token to be fetched then return it (bypass cache ttl)
   *
   * @strategy `fetchAndUpdate` (use for current token) - send a fetch request which will send an event once updated and return cached token (respects cache ttl)
   *
   * @strategy `cacheOnly` - get the current cached value
   */
  public getToken = async (
    tokenId = this.currentTokenId,
    strategy: GetTokenStrategy = 'cacheOnly',
  ) => {
    if (!tokenId) {
      return undefined;
    }
    const cachedToken = this.tokens[tokenId];
    switch (strategy) {
      case 'cacheOnly':
        return cachedToken;
      case 'fetch':
        await this.fetchAndUpdateToken(tokenId);
        return this.tokens[tokenId];
      case 'fetchAndUpdate':
        this.fetchAndUpdateToken(tokenId);
        return cachedToken;
      case 'forceFetch':
        await this.fetchAndUpdateToken(tokenId, 'forceRefetch');
        return this.tokens[tokenId];
      default:
        return undefined;
    }
  };

  private trackOffchainTokenOverview = async (
    token: TradingToken,
    opts: MemeOverviewTrackingOpts,
  ) => {
    const introSource = this.tokenSources[token.id];

    const firstVisit = introSource === undefined;
    if (firstVisit) {
      this.tokenSources[token.id] = opts.sourceCategory;
    }

    // strangely the buyPrice is not always a Big
    const buyPrice =
      token.buyPrice instanceof Big
        ? token.buyPrice.toNumber()
        : Big(token.buyPrice).toNumber();

    this.app.track('memeoffchainToken_overview_page', {
      first: firstVisit,
      memecard_intro_source: introSource ?? opts.sourceCategory,
      meme_overview_count: Object.keys(this.tokenSources).length,
      swipe: opts.swipe,
      cardID: this.currentTokenId,
      memecard_ticker: token.ticker,
      memecard_name: token.name,
      memecard_creator: token.creatorId,
      current_price: buyPrice,
      current_owned: Big(
        getMyOffchainTokenTokenAmount(this.app.state, token.id),
      ).toNumber(),
      total_holders: token.holderCount,
      source_name: opts.sourceName,
      position: opts.positionInFeed,
      search_term_length: this.currentList.getSearchTermLength(),
    });
  };

  private setToken = async (
    tokenId?: string,
    trackingOpts?: MemeOverviewTrackingOpts,
  ) => {
    this.currentTokenId = tokenId;
    // Allow undefined so we can support non tiktok
    if (!tokenId) {
      return;
    }

    const fetchTokenRequest = this.fetchAndUpdateToken(tokenId);

    if (trackingOpts) {
      fetchTokenRequest.then((offchainToken) => {
        if (!offchainToken) {
          return;
        }

        this.trackOffchainTokenOverview(offchainToken, trackingOpts);
      });
    }

    if (!this.currentMeme.token) {
      await fetchTokenRequest;
    }
    // No need to send update event here; 'fetchAndUpdateToken' will take care of it
  };

  private createFetchAndUpdateRequest = async (tokenId: string) => {
    const offchainToken = await this.app.asyncGetters.getOffchainToken({
      offchainTokenId: tokenId,
    });

    if (!offchainToken) {
      return;
    }

    const marketCap = offchainToken.overview.marketCap;
    if (typeof marketCap === 'string') {
      offchainToken.overview.marketCap = Big(marketCap);
    }

    // Update request cache
    this.cache.tokens[tokenId] = {
      id: tokenId,
      timestamp: this.app.now(),
    };
    // Update token value
    this.tokens[tokenId] = offchainToken;

    return offchainToken;
  };

  private fetchAndUpdateToken = async (
    tokenId: string,
    forceRefetch: Optional<'forceRefetch'> = undefined,
  ) => {
    const cacheNotExpired = !this.isCacheExpired(tokenId);
    const skipRefetch = cacheNotExpired && !forceRefetch;

    if (skipRefetch) {
      return this.tokens[tokenId];
    }

    let tokenFetchAndUpdateRequest = this.tokenFetchRequests[tokenId];
    if (forceRefetch || !tokenFetchAndUpdateRequest) {
      // avoid multiple simultaneous requests
      tokenFetchAndUpdateRequest = this.createFetchAndUpdateRequest(tokenId);
      this.tokenFetchRequests[tokenId] = tokenFetchAndUpdateRequest;

      tokenFetchAndUpdateRequest.finally(() => {
        // delete immediately after fetch is complete
        // note that the token cache will take over if necessary
        delete this.tokenFetchRequests[tokenId];
      });
    }

    const offchainToken = await tokenFetchAndUpdateRequest;
    return offchainToken;
  };

  private isCacheExpired = (tokenId: string) => {
    const cache = this.cache.tokens[tokenId];
    // If we don't have cache then flag as expired so we set a new one
    if (!cache) {
      return true;
    }

    const now = this.app.now();

    const ttl = cache.timestamp + maxCacheValidity;

    const isExpired = ttl <= now;

    return isExpired;
  };

  public curateFeedWithAds = (
    memeItems: TradingTokenListing[],
  ): FeedItemConfig[] => {
    const bucketId = this.app.state.ruleset.abTests[tests.TEST_MFA]?.bucketId;
    const mfaEnabled = bucketId === 'enabled';

    if (!mfaEnabled) {
      return memeItems.map((memeItem) => ({
        type: 'meme',
        item: memeItem,
      }));
    }

    let feedItems: FeedItemConfig[] = [];

    let carouselIdx = 0;
    for (let i = 0; i < memeItems.length; i += 1) {
      while (this.memeFeedAds.isFeedItemAnAd(this.currentFilter, carouselIdx)) {
        feedItems[carouselIdx] = {
          type: 'ad',
          item: this.memeFeedAds.getMemeAdController(
            this.currentFilter,
            carouselIdx - i,
          ),
        };
        carouselIdx += 1;
      }

      feedItems[carouselIdx] = {
        type: 'meme',
        item: memeItems[i],
      };
      carouselIdx += 1;
    }

    return feedItems;
  };
}
