import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UIController';
import { TutorialModule } from './TutorialModule';

export class BoosterRocketman extends TutorialModule {
  id = 'BoosterRocketman' as 'BoosterRocketman';

  onAction = (id?: string) => {
    if (id === 'rocketman') {
      this.onComplete();
    }
  };

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });

    // navigate to shop page
    this.app.nav.goTo('Shop');

    this.app.ui.setShopUIState({ btnRocket: ElementUIState.Highlight });

    return super.execute().then(() => {
      this.app.ui.setShopUIState({ btnRocket: ElementUIState.Normal });
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_boosts_click_rocket',
    };
  }
}
