import './DrawerFollow.scss';
import { assets } from '../../../assets/assets';
import { app, useAppUpdates } from '../../../data/AppController';
import { Tabs, TabStyle } from '../../shared/Tabs/Tabs';
import { ReactNode, useEffect, useState } from 'react';
import Dots from '../../shared/Dots/Dots';
import { ProfileEvents } from '../../../data/Controllers/ProfileController';
import {
  getCoinAmountForTokenSell,
  getMarketCap,
} from '../../../replicant/features/offchainTrading/offchainTrading.getters';
import Big from 'big.js';
import { TradingSearchResult } from '../../../replicant/features/offchainTrading/offchainTrading.properties';

type FollowType = 'followers' | 'following';

/*
{
    "id": "6170825979",
    "score": 2000000000,
    "scoreSeason1": 1001,
    "referrerContribution": 0,
    "balance": 1080000000,
    "name": "edgecaser",
    "photo": "https://ddw8nafke4m1l.cloudfront.net/user-assets/gemzcoin-charlie/tg-profile-AQADLawxG16VQUYAAQ",
    "league": "league8",
    "notSeenSinceLegacy": false,
    "season": 2,
    "offchainTokens": [
        {
            "id": "offchainTrading-be34c40e-9113-443e-9aca-86a0db497061",
            "isCreator": false,
            "tokenAmount": 0.0007741772278975,
        },
        {
            "id": "offchainTrading-111a1842-a9ff-457c-a0ca-82f05949fef9",
            "isCreator": false,
            "tokenAmount": 41.94554110553511,
        }
    ],
    "teamId": "-1002207165707",
    "banned": false,
    "updatedAt": 1726501804853,
    "followings": [
        "5796798150",
        "7070750048",
        "6540606306"
    ],
    "followersCount": 0
}
*/
interface PartialUser {
  id: string;
  name: string;
  photo: string;
  offchainTokens: { id: string; isCreator: boolean; tokenAmount: number }[];
}

const formatUser = (
  user: PartialUser,
  myUserId: string,
  type: FollowType,
  tokens: TradingSearchResult[],
  followings?: string[],
) => {
  const tokensCreated = user.offchainTokens.filter((t) => t.isCreator);
  const tokensOwned = user.offchainTokens;

  const sumOfMktCap = tokensCreated.reduce(
    (res, cur) => res.add(getMarketCap(Big(cur.tokenAmount))),
    Big(0),
  );
  const sumOfSellPrice = tokensOwned.reduce((res, cur) => {
    const token = tokens.find((t) => t.id === cur.id);
    if (!token) {
      return res;
    }
    return res.add(
      getCoinAmountForTokenSell(Big(token.supply), Big(cur.tokenAmount)),
    );
  }, Big(0));

  return {
    userId: user.id,
    name: user.name,
    picture: user.photo ?? assets.default_profile,
    tokensCreated: tokensCreated.length,
    tokensOwned: tokensOwned.length,
    following:
      type === 'followers' ? followings?.includes(myUserId) ?? false : true,
    follower: type === 'followers',
    sumOfMktCap,
    sumOfSellPrice,
  };
};

export const DrawerFollow = () => {
  useAppUpdates({
    id: 'DrawerFollow',
    listener: app.profile.attachEventListener(ProfileEvents.OnUpdate),
  });

  const [tab, setTab] = useState<FollowType>(
    app.ui.drawer.view.data?.props?.followView ?? 'followers',
  );

  const selectTab = (tab: FollowType) => () => {
    app.track('Follow_tab_switch', {
      tab_name: tab,
    });
    setTab(tab);
  };

  const [followers, setFollowers] = useState<FollowProfile[]>([]);
  const [following, setFollowing] = useState<FollowProfile[]>([]);

  useEffect(() => {
    app.track('Follow_page_view');
    app.nav.showingBlockingModal();
  }, []);

  const currentProfile = app.profile.current;

  useEffect(() => {
    app.profile.getFollows().then((response) => {
      const nFollowers = response.followers.map((user) =>
        formatUser(
          user,
          app.state.id,
          'followers',
          response.tokens ?? [],
          currentProfile?.followings,
        ),
      );
      setFollowers(nFollowers);
      const nFollowing = response.following.map((user) =>
        formatUser(
          user,
          app.state.id,
          'following',
          response.tokens ?? [],
          currentProfile?.followings,
        ),
      );
      setFollowing(nFollowing);
      console.log({ nFollowers, nFollowing });
    });
  }, [currentProfile?.followers, currentProfile?.following]);

  return (
    <Tabs<FollowType>
      tabStyle={TabStyle.Squared}
      height={500}
      initial={tab}
      autoSelected={tab}
      onTabClick={selectTab}
      tabs={[
        {
          id: 'followers',
          name: 'Followers',
          component: <FollowList type={'followers'} users={followers} />,
        },
        {
          id: 'following',
          name: 'Following',
          component: <FollowList type={'following'} users={following} />,
        },
      ]}
    />
  );
};

interface FollowProfile {
  userId: string;
  name: string;
  picture: string;
  tokensCreated: number;
  tokensOwned: number;
  following: boolean;
  follower: boolean;
  sumOfMktCap: Big;
  sumOfSellPrice: Big;
}

const FollowEntry = ({
  userId,
  picture,
  name,
  tokensCreated,
  tokensOwned,
  following,
  follower,
  sumOfMktCap,
  sumOfSellPrice,
}: FollowProfile) => {
  const [loading, setLoading] = useState(false);

  let btnText: ReactNode = getBtnText(
    following,
    follower,
    Boolean(app.profile.current?.isSelf),
  );
  let btnStyle = following ? 'btn-inverse' : 'btn-normal';

  let followStatus = '';
  if (follower) {
    followStatus = following ? '(Follows you)' : '';
  }

  if (loading) {
    btnText = <Dots />;
  }

  const onAction = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (following) {
      await app.profile.unfollowUser(userId);
    } else {
      await app.profile.followUser(userId);
    }
    setLoading(false);
  };

  const hideBtn = userId === app.state.id || btnText === 'Hidden';

  return (
    <div className="follow-entry-container">
      <div className="follow-entry">
        <div className="follow-picture">
          <img src={picture} />
        </div>
        <div className="follow-details">
          <div className="follow-name">
            {name} {followStatus}
          </div>
          <div className="follow-stats">
            {tokensCreated} Created ({sumOfMktCap.toNumber()}) . {tokensOwned}{' '}
            Owned ({sumOfSellPrice.toNumber()})
          </div>
        </div>
        <div className={`follow-btns ${hideBtn && 'invisible'}`}>
          <div className={`btn ${btnStyle}`} onClick={onAction}>
            {btnText}
          </div>
        </div>
      </div>
    </div>
  );
};

const FollowList = ({
  type,
  users,
}: {
  type: FollowType;
  users: FollowProfile[];
}) => {
  const msg = type === 'followers' ? `No followers` : `Not following anyone`;
  return (
    <div>
      {users.length === 0 && <div>{msg}</div>}
      {users.map((user) => (
        <FollowEntry key={user.userId} {...user} />
      ))}
    </div>
  );
};

/**
  Follow button - With proper state relevant to the local user: Follow, Follow Back, Following, or Hidden.
*/
const getBtnText = (following: boolean, follower: boolean, self: boolean) => {
  if (following) {
    return 'Following';
  }

  if (self) {
    if (follower) {
      return 'Follow Back';
    }
    return 'Hidden';
  }

  return 'Follow';
};
