import { t } from 'i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/AppController';
import { TransactionSuccess } from '../../../../data/Controllers/UIController/UIController';
import { resolveProfilePicture } from '../../../../replicant/features/game/game.getters';
import { TradingTokenListing } from '../../../../replicant/features/offchainTrading/types';

interface SidebarUIProps {
  token: TradingTokenListing;
}

export const SidebarUI = ({ token }: SidebarUIProps) => {
  const { offchainTokenId, ticker, shares } = token;

  const onTapAuthor = () => {
    app.nav.goToProfile(token.creatorId);
  };

  const { currentMeme } = app.memes;

  const onTapShare = () => {
    if (!currentMeme.token || currentMeme.token.id !== offchainTokenId) {
      return;
    }

    const {
      description: { description },
      name,
    } = currentMeme.token;

    app.memes.shareOffchainToken(
      'feed_sidebar',
      {
        offchainTokenId,
        offchainTokenName: t('tiktokpage_share_title', { memeTitle: name }),
        offchainTokenDescription: t('tiktokpage_share_body'),
      } as TransactionSuccess,
      'isTiktok',
    );
  };

  return (
    <div className="sidebar">
      {/* share */}
      <div className="sidebar-item share" onClick={onTapShare}>
        <div className="sidebar-image">
          <img src={assets.icon_sidebar_share} />
        </div>
        <div className="label">{shares}</div>
      </div>

      {/* chat */}
      {/* <div className="sidebar-item chat" onClick={onTapChat}>
        <div className="sidebar-image">
          <img src={assets.icon_sidebar_chat} />
        </div>
        <div className="label">{0}</div>
      </div> */}

      {/* author */}
      <div className="sidebar-item author" onClick={onTapAuthor}>
        <img src={resolveProfilePicture(token.creatorId, token.creatorImage)} />
        {/* <div className="label">{token.creatorName}</div> */}
      </div>
    </div>
  );
};
