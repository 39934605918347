import './DrawerBoosterDetails.scss';
import { app } from '../../../data/AppController';
import { assets } from '../../../assets/assets';
import { Booster, Buff } from '../../../data/types';
import { boosterConfig } from '../../../data/consts';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../replicant/utils/numbers';
import { isExpectedError } from '../../../replicant/types';
import { getBaseEnergy } from '../../../replicant/features/game/game.getters';
import { MAX_ADDITIONAL_ENERGY } from '../../../replicant/features/game/ruleset/player';

export const DrawerBoosterDetails = () => {
  const { t } = useTranslation();

  const { data } = app.ui.drawer.view;
  if (!data) return null;
  if (!data.props) return null;
  if (!data?.props?.booster) return null;

  const { boosterId, listing, onBuyBooster } = data?.props?.booster;
  const { image, name, desc, effect } = boosterConfig[boosterId];

  const onBuyClick = () => {
    data.onClose && data.onClose();
    // This is the bot rewards modal, it's not a shop booster/buff
    if (
      boosterId === Buff.AutoBotReward ||
      boosterId === Buff.AutoBotFirstSession
    ) {
      // Yulia: this call break drawers chain
      // but I not sure what could be broken if we remove it
      // so let's keep it for now
      // app.closeAllPages();
      return;
    }

    console.warn('>>> buying booster', boosterId);
    if (Object.values(Booster).includes(boosterId as Booster)) {
      onBuyBooster && onBuyBooster(boosterId as Booster);
    } else {
      app.buyBuff(boosterId as Buff).then((maybeError) => {
        if (isExpectedError(maybeError)) {
          console.log('BoosterDetails buyBuff expected error:', maybeError);
        }
      });
    }
  };

  const baseEnergy = getBaseEnergy(app.state);
  const additionalEnergy = Math.min(MAX_ADDITIONAL_ENERGY, baseEnergy);

  return (
    <div className="booster-details">
      <div className="booster-details-layout">
        <div className="booster-details-header">
          <div className="booster-details-icon">
            <img src={image} />
          </div>
          <div className="booster-details-title">{t(name)}</div>
          <div className="booster-details-subtitle">{t(desc)}</div>
        </div>

        <div className="booster-details-body">
          <div className="booster-details-effect">
            {t(effect, { energy: additionalEnergy })}
          </div>
          <div className="booster-details-points">
            <img className="booster-details-points-icon" src={assets.coin} />
            <div className="booster-details-points-value">
              {listing.price === 0 ? 'Free' : formatPrice(listing.price)}
            </div>
          </div>
        </div>
      </div>

      <div className="footer-buttons">
        <div className="btn btn-normal" onClick={onBuyClick}>
          {t('get_button')}
        </div>
      </div>
    </div>
  );
};
