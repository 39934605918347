import { ElementUIState } from '../../Controllers/UIController/UIController';
import { waitFor } from '../../utils';
import { TutorialModule } from './TutorialModule';

export class AnimateBalance1500 extends TutorialModule {
  id = 'AnimateBalance1500' as 'AnimateBalance1500';

  execute = async () => {
    await this.app.ui.animateBalance(1500);
    this.onComplete();

    return super.execute().then(() => {});
  };
}
