import { ElementUIState } from './Controllers/UIController/UIController';
import { assets } from '../assets/assets';
import Resizer from 'react-image-file-resizer';
import { app } from './AppController';
import { tests } from '../replicant/ruleset';
import { REPLICANT_UPLOAD_LENGTH_LIMIT } from '../replicant/features/offchainTrading/offchainTrading.ruleset';

interface FormatOpts {
  /**
   * defaults to true; disable to get format X days
   */
  includeHours?: boolean;
  /**
   * defaults to true; disable to get format Xd Xh
   */
  includeMinutes?: boolean;
  /**
   * defaults to true; disable to get format Xd Xh Xm
   */
  includeSeconds?: boolean;
  /**
   * function default to auto use time format when less than 1 day
   * use this to disable it
   */
  disableAutoTimeFormat?: boolean;
}
export function msToFullTimeWithDays(duration: number, opts?: FormatOpts) {
  let timeDiff = Math.floor(duration / 1000);

  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(timeDiff / (24 * 3600));

  if (days < 1 && !opts?.disableAutoTimeFormat) {
    return msToFullTime(duration);
  }

  timeDiff %= 24 * 3600;
  const hours = Math.floor(timeDiff / 3600);
  timeDiff %= 3600;
  const minutes = Math.floor(timeDiff / 60);
  const seconds = timeDiff % 60;

  // Format the output
  const pad = (num: number) => String(num).padStart(2, '0');

  if (opts?.includeSeconds ?? true) {
    return `${pad(days)}d ${pad(hours)}h ${pad(minutes)}:${pad(seconds)}`;
  }
  if (opts?.includeMinutes ?? true) {
    return `${pad(days)}d ${pad(hours)}h ${pad(minutes)}m`;
  }
  if (opts?.includeHours ?? true) {
    return `${pad(days)}d ${pad(hours)}h`;
  }

  return `${pad(days)} days`;
}

export function msToFullTime(duration: number) {
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 60);

  const hrs = `${hours < 10 ? `0${hours}` : hours}`;
  const min = `${minutes < 10 ? `0${minutes}` : minutes}`;
  const sec = `${seconds < 10 ? `0${seconds}` : seconds}`;
  return `${hrs}:${min}:${sec}`;
}

export function msToTime(duration: number) {
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);

  const min = `${minutes < 10 ? `0${minutes}` : minutes}`;
  const sec = `${seconds < 10 ? `0${seconds}` : seconds}`;
  return `0:${min}:${sec}`;
}

export function msToMinutes(duration: number) {
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);

  const min = `${minutes < 10 ? `0${minutes}` : minutes}`;
  const sec = `${seconds < 10 ? `0${seconds}` : seconds}`;
  return `${min}:${sec}`;
}

export function msToSeconds(duration: number) {
  const seconds = Math.floor((duration / 1000) % 60);
  const sec = `${seconds < 10 ? `0${seconds}` : seconds}`;
  return `0:${sec}`;
}

export function msToAgo(duration: number) {
  let timeDiff = Math.floor(duration / 1000);

  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(timeDiff / (24 * 3600));

  timeDiff %= 24 * 3600;
  const hours = Math.floor(timeDiff / 3600);
  timeDiff %= 3600;
  const minutes = Math.floor(timeDiff / 60);
  const seconds = timeDiff % 60;

  // @TODO: We might want to handle week/months/years?

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }
  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }
  if (seconds > 0) {
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  }
}

export function timeInSec(time: number) {
  return time / 1000;
}

export const pluralize = (
  count: number,
  title: string,
  pluralTitle?: string,
) => {
  return count === 1
    ? `${count} ${title}`
    : `${count} ${pluralTitle || title + 's'}`;
};

export const pluralizeWordOnly = (
  count: number,
  title: string,
  pluralTitle?: string,
) => {
  return count === 1 ? title : pluralTitle || `${title}s`;
};

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch {
    var dummy = document.createElement('input');
    dummy.style.display = 'none';
    document.body.appendChild(dummy);

    dummy.setAttribute('id', 'dummy_id');
    (document.getElementById('dummy_id') as HTMLInputElement).value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }
};

/**
 * Smoothly transitions a number from a starting value to an ending value over a specified number of chunks,
 * with each chunk having a specified duration, calling a callback function with the current value at each step.
 *
 * @param {number} startValue - The initial value.
 * @param {number} endValue - The final value.
 * @param {function} cb - The callback function to call with the updated value.
 * @param {number} chunks - The number of chunks to divide the delta into. Default to 20
 * @param {number} chunkDuration - The duration of each chunk in milliseconds. Default to 50
 * @returns {Promise<void>} - A Promise that resolves when the animation is complete.
 */
export function rollingNumberValue(
  startValue: number,
  endValue: number,
  cb: (value: number) => void,
  chunks: number = 20,
  chunkDuration: number = 50,
): Promise<void> {
  return new Promise((resolve) => {
    const delta = endValue - startValue;
    const step = delta / Math.min(chunks, Math.abs(delta));

    let current = startValue;
    const intervalId = setInterval(() => {
      current += step;

      if (
        (step > 0 && current >= endValue) ||
        (step < 0 && current <= endValue)
      ) {
        clearInterval(intervalId);
        cb(endValue);
        resolve();
      } else {
        cb(Math.round(current));
      }
    }, chunkDuration);
  });
}

const uiStateClassNames: Partial<Record<ElementUIState, string>> = {
  [ElementUIState.Hidden]: 'invisible',
  [ElementUIState.Highlight]: 'highlight pulse',
  [ElementUIState.Inactive]: 'inactive',
  [ElementUIState.Mystery]: 'mystery',
  [ElementUIState.Disabled]: 'disabled',
  [ElementUIState.Remove]: 'removed',
  [ElementUIState.Complete]: 'complete',
};

export function getUIStateClassName(uiState: ElementUIState) {
  return uiStateClassNames[uiState] || '';
}

/**
 * @param time in miliseconds
 * @returns
 */
export async function waitFor(time: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export const cai = (title: string, log?: Record<string, any>) => {
  console.log({ cai: title, ...log });
};

export function insertIconIntoText(text: string) {
  const coinIconText = insertCoinIconIntoText(text);
  const starIconText = insertTelegramStarIconIntoText(coinIconText);
  const ticketIcontText = insertTicketIconIntoText(starIconText);
  return ticketIcontText;
}

export function insertCoinIconIntoText(text: string) {
  return insertAssetIntoText(text, 'coinIcon', assets.coin);
}

export function insertTelegramStarIconIntoText(text: string) {
  return insertAssetIntoText(text, 'starIcon', assets.telegram_star);
}

export function insertTicketIconIntoText(text: string) {
  return insertAssetIntoText(text, 'ticketIcon', assets.tiktok_ticket);
}

function insertAssetIntoText(text: string, key: string, asset: string) {
  return text.replace(
    new RegExp(`{{${key}}}`, 'g'),
    `<img src='${asset}' style='height: 1em; vertical-align: middle; margin: 0 .25em; margin-top: -0.15em;' alt='${key}' />`,
  );
}

// ==============================================================
// User scrolling

// Enable/Disable scroll gesture on mobile on the app root level,
// by enabling/disabling touchmove gesture

function preventDefaultAndPropagation(e: Event) {
  e.preventDefault();
  e.stopPropagation();
}

export function setUserScrolling(enabled: boolean) {
  if (enabled) {
    window.removeEventListener(
      'touchmove',
      preventDefaultAndPropagation,
      false,
    );
    document.removeEventListener(
      'touchmove',
      preventDefaultAndPropagation,
      false,
    );
    document.body.removeEventListener(
      'touchmove',
      preventDefaultAndPropagation,
      false,
    );
  } else {
    window.addEventListener(
      'touchmove',
      preventDefaultAndPropagation,
      getWheelOptions(),
    );
    document.addEventListener(
      'touchmove',
      preventDefaultAndPropagation,
      getWheelOptions(),
    );
    document.body.addEventListener(
      'touchmove',
      preventDefaultAndPropagation,
      getWheelOptions(),
    );
  }
}

// Modern Chrome requires { passive: false } when adding event

function getWheelOptions() {
  var supportsPassive = false;
  try {
    const obj = Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassive = true;
      },
    });
    // @TODO: Can this be removed? What is the point of this listener?
    // @ts-ignore
    window.addEventListener('test', null, obj);
  } catch (e) {}

  return supportsPassive ? { passive: false } : false;
}

// ==============================================================
const resizeConfig = {
  maxWidth: 2048,
  maxHeight: 2048,
  quality: 100,
  rotation: 0,
  outputType: 'base64',
};

export const fileToB64 = (file: File): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    const onImageResized = <T>(value: T) => {
      resolve(value as string);
    };

    try {
      const fileType = file.type.split('/')[1].toLowerCase();
      let compressFormat = 'JPEG';

      if (fileType === 'png') {
        // Check if PNG has transparent pixels
        const hasTransparency = await checkPNGTransparency(file);
        compressFormat = hasTransparency ? 'PNG' : 'JPEG';
      } else if (fileType === 'gif') {
        // For GIFs, we'll use the first frame and compress to JPEG
        compressFormat = 'JPEG';
      }

      const resizeImage = (width: number, height: number, quality: number) => {
        return new Promise<string>((resolveResize) => {
          Resizer.imageFileResizer(
            file,
            width,
            height,
            compressFormat,
            quality,
            resizeConfig.rotation,
            (result: string | File | Blob | ProgressEvent<FileReader>) =>
              resolveResize(result as string),
            resizeConfig.outputType,
          );
        });
      };

      let currentWidth = resizeConfig.maxWidth;
      let currentHeight = resizeConfig.maxHeight;
      let result: string;

      do {
        // Try with quality 100 first
        result = await resizeImage(currentWidth, currentHeight, 100);
        if (result.length <= REPLICANT_UPLOAD_LENGTH_LIMIT) {
          onImageResized(result);
          return;
        }

        // If quality 100 is too large, try with quality 90
        result = await resizeImage(currentWidth, currentHeight, 90);
        if (result.length <= REPLICANT_UPLOAD_LENGTH_LIMIT) {
          onImageResized(result);
          return;
        }

        currentWidth *= 0.75;
        currentHeight *= 0.75;
      } while (currentWidth >= 1 && currentHeight >= 1);

      reject(new Error('Unable to resize image to meet size limit'));
    } catch (e) {
      reject(e);
    }
  });
};

const checkPNGTransparency = (file: File): Promise<boolean> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        img.onload = null;
        img.onerror = null;
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          resolve(false);
          return;
        }
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        for (let i = 3; i < imageData.data.length; i += 4) {
          if (imageData.data[i] < 255) {
            resolve(true);
            return;
          }
        }
        resolve(false);
      };
      img.onerror = () => {
        img.onload = null;
        img.onerror = null;
        resolve(false);
      };
      img.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
};

export const upperFirst = (text: string) => {
  const [first, ...rest] = text;
  return `${first.toUpperCase()}${rest.join('')}`;
};

/**
 * Use this function to get a pretty format (n.nn%) percentage
 * @param partial
 * @param total
 * @param fixed (optional) default 2
 * @returns
 */
export const getPct = (partial: number, total: number, fixed = 2) => {
  if (total < 0) {
    total = total < 1 ? total + 1 : total;
  }
  const num = (100 * partial) / total;
  return `${num.toFixed(fixed)}%`;
};

export const cropImageToSquare = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const size = Math.min(img.width, img.height);
        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext('2d');

        if (ctx === null) {
          img.onload = null;
          img.onerror = null;
          resolve(file);
          return;
        }

        ctx.drawImage(
          img,
          (img.width - size) / 2,
          (img.height - size) / 2,
          size,
          size,
          0,
          0,
          size,
          size,
        );

        canvas.toBlob((blob) => {
          if (blob) {
            const croppedFile = new File([blob], file.name, {
              type: file.type,
            });
            resolve(croppedFile);
          } else {
            resolve(file);
          }
        }, file.type);
      };

      img.onerror = () => {
        img.onload = null;
        img.onerror = null;
        resolve(file);
      };

      img.src = e.target?.result as string;
    };

    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

// todo carles
export const cropImageToPortrait = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        // const size = Math.min(img.width, img.height);

        const sizeX = img.width / 2;
        const sizeY = img.height / 2;

        const canvas = document.createElement('canvas');
        canvas.width = sizeX;
        canvas.height = sizeY;
        const ctx = canvas.getContext('2d');

        if (ctx === null) {
          img.onload = null;
          img.onerror = null;
          resolve(file);
          return;
        }

        ctx.drawImage(
          img,
          // (img.width - sizeX) / 2,
          // (img.height - sizeY) / 2,
          sizeX,
          sizeY,
          sizeX,
          sizeY,
          0,
          0,
          sizeX,
          sizeY,
        );

        canvas.toBlob((blob) => {
          if (blob) {
            const croppedFile = new File([blob], file.name, {
              type: file.type,
            });
            resolve(croppedFile);
          } else {
            resolve(file);
          }
        }, file.type);
      };

      img.onerror = () => {
        img.onload = null;
        img.onerror = null;
        resolve(file);
      };

      img.src = e.target?.result as string;
    };

    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export function isTiktokEnabled() {
  return (
    app.replicant?.abTests?.getBucketID(tests.TEST_TIKTOK_FEED) === 'enabled'
  );
}

export function isTiktokTapGameAutoStart() {
  return (
    app.replicant?.abTests?.getBucketID(tests.TEST_TIKTOK_TAPGAME_AUTOSTART) ===
    'enabled'
  );
}

function getABValue<T>(
  test: keyof typeof tests,
  values: Record<'control' | string, T>,
) {
  let key = 'control';
  if (app?.replicant?.abTests) {
    key = app?.replicant?.abTests?.getBucketID(tests[test]) as string;
  }
  return values[key] ?? values.control;
}
export const tiktokGameSessionDuration: Record<string, number> = {
  control: 30,
  '15_sec_playsession': 15,
};
export function getTiktokGameSessionDuration() {
  return getABValue(
    'TEST_TIKTOK_GAME_SESSION_DURATION',
    tiktokGameSessionDuration,
  );
}
export const tiktokAutoplayDuration: Record<string, number> = {
  control: 5,
  '3_second_autoplay_delay': 3,
  '10_second_autoplay_delay': 10,
};
export function getTiktokAutoplayDuration() {
  return getABValue('TEST_TIKTOK_AUTOPLAY_DURATION', tiktokAutoplayDuration);
}

export function dedupArray<T>(array: T[]): T[] {
  return [...new Set(array)];
}
