import './DrawerPowerUp/DrawerPowerUp.scss';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/AppController';
import { useTranslation } from 'react-i18next';
import { getEarnDiff } from '../../../replicant/features/powerups/getters';
import { sendMineCardGift } from '../../../data/sharing';

export const DrawerDailyAirdrop = () => {
  // todo: this causes first tap anywhere after app start to show powerupsPage
  // todo: any specific reason why we need all this here?
  // useEffect(() => {
  //   app.setBackExtraEffect(app.ui.drawer.close);
  //   app.onBackNavigateTo('MinePage');
  //   return () => {
  //     app.setBackExtraEffect(undefined);
  //     app.onBackNavigateTo(undefined);
  //   };
  // }, []);

  const { t } = useTranslation();
  const powerup = app.getDailyAirdrop();

  const onSendGift = () => {
    if (powerup) {
      app.track('free_gift_prompt', {
        card: powerup.id,
        gift_name: powerup.id,
      });
      sendMineCardGift({
        card: powerup.id,
        originFeature: 'daily',
        originSubFeature: 'daily_airdrop',
      });
    }
    app.ui.drawer.close();
  };

  return (
    <>
      <div className="drawer-layout powerup">
        <div className="drawer-header">
          {/* image */}
          <div className="drawer-header-icon">
            <img src={powerup?.details.image} />
          </div>
          {/* title and description */}
          <div className="drawer-header-title">{t(`free_gift_modal_name`)}</div>
          <div className="drawer-header-subtitle powerup">
            <div className="text">{t(`free_gift_modal_description`)}</div>
          </div>

          {/* earn */}
          <div className="puc-earn">
            <div className="puc-earn-coins">
              <img src={assets.coin} />
              <div className="puc-earn-title">
                +
                {t(`free_gift_modal_amount`, {
                  amount: getEarnDiff(powerup?.id || '', app.state, app.now()),
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* buy button */}
      <div className="footer-buttons">
        <div className={`btn btn-normal`} onClick={onSendGift}>
          {t(`free_gift_modal_button`)}
        </div>
      </div>
    </>
  );
};
