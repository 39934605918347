import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UIController';
import { waitFor } from '../../utils';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class BoosterButton extends TutorialModule {
  id = 'BoosterButton' as 'BoosterButton';

  onAction = (id?: string) => {
    this.app.ui.tooltip.hide();
    if (id === 'booster') {
      this.onComplete();
    }
  };

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
      league: '1',
    });

    this.app.ui.setClickerUIState({
      btnClicker: ElementUIState.Normal,
      btnLeague: ElementUIState.Inactive,

      footer: ElementUIState.Normal,

      btnFriends: ElementUIState.Mystery,
      btnMine: ElementUIState.Mystery,
      btnEarn: ElementUIState.Mystery,
      btnBooster: ElementUIState.Highlight,
      btnTeam: ElementUIState.Hidden,
    });

    await waitFor(500);

    this.app.tutorial.showTooltip({
      text: t('tut_tooltip_earnmorefaster'), // 'Earn more faster',
      origin: { x: '100%', y: '100%' },
      offset: { x: '-76px', y: '-260px' },
      align: 'bottom',
    });

    return super.execute().then(() => {
      this.app.ui.tooltip.hide();
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_boosts_click_store_button',
    };
  }
}
