import './TradingBanner.scss';
import { useTranslation } from 'react-i18next';
import { app } from '../../../../data/AppController';
import { getUIStateClassName, isTiktokEnabled } from '../../../../data/utils';

export const TradingBanner = () => {
  const { t } = useTranslation();

  const onClick = () => {
    if (isTiktokEnabled()) {
      app.nav.goToTiktokFeed();
    } else {
      app.nav.goTo('TradingPage');
    }
  };

  return (
    <div className={`btn trading-banner`} onClick={onClick}>
      <div className="trading-banner-bg" />

      <div className="trading-banner-new">{t('trading_banner_new')}</div>

      <div className="trading-banner-content">
        <div className="trading-banner-cup" />

        <div className="trading-banner-info">
          <div className="trading-banner-info-top">
            <div className="title">{t('trading_banner_title')}</div>
          </div>
          <div className="trading-banner-info-bottom">
            <div className="owned">
              {t('trading_banner_owned', {
                amount: app.memes.myMemesCount,
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
