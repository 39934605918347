import { SEC_IN_MS } from '../replicant/utils/time';
import { EventListener } from './EventListener';
import { Fn } from './types';

export enum TimerEvents {
  OnStart = 'OnStart',
  OnEnd = 'OnEnd',
  OnStop = 'OnStop',
  OnPause = 'OnPause',
  OnResume = 'OnResume',
  OnTick = 'OnTick',
}

interface TimerOpts {
  id: string;
  duration: number;
  step?: number;
  countType?: 'up' | 'down';
  debug?: boolean;
}

export class Timer extends EventListener {
  private timer?: NodeJS.Timeout;

  private currentTime = 0;

  private cfg: Required<TimerOpts>;

  private active = false;

  private timerResolver!: Fn<void>;
  private timerPromise = new Promise((resolve) => {
    this.timerResolver = resolve;
  });

  // Computed state based on 'countType'
  private get state() {
    const isCountdown = this.cfg.countType === 'down';

    let isEnd = false;

    if (isCountdown) {
      if (this.currentTime <= 0) {
        isEnd = true;
      }
    } else {
      // @TODO: I think there's a use-case where we might not want '='; Add that as an option then
      if (this.currentTime >= this.cfg.duration) {
        isEnd = true;
      }
    }

    return {
      startTime: isCountdown ? this.cfg.duration : 0,
      timeModifier: isCountdown ? -1 : 1,
      isEnd,
      endTime: isCountdown ? 0 : this.cfg.duration,
    };
  }

  public get time() {
    return this.currentTime;
  }

  public get isRunning() {
    return this.active;
  }

  public get waitForComplete() {
    return this.timerPromise;
  }

  public get duration() {
    return this.cfg.duration;
  }

  constructor(opts: TimerOpts) {
    super();
    this.cfg = {
      step: SEC_IN_MS,
      countType: 'down',
      debug: false,
      ...opts,
    };
  }

  private reset = () => {
    clearInterval(this.timer);
    this.active = false;
    this.currentTime = this.state.endTime;
  };

  private onEnd = () => {
    this.timerResolver();
    this.reset();
    this.sendEvents(TimerEvents.OnEnd);
  };

  private startTimer = () => {
    this.timerPromise = new Promise((resolve) => {
      this.timerResolver = resolve;
    });
    this.timer = setInterval(() => {
      this.currentTime += this.state.timeModifier;
      if (this.cfg.debug) {
        console.log(`Timer (timer):`, {
          currentTime: this.currentTime,
          state: this.state,
        });
      }
      this.sendEvents(TimerEvents.OnTick);
      if (this.state.isEnd) {
        this.onEnd();
      }
    }, this.cfg.step);
  };

  public start = () => {
    this.stop();
    this.active = true;
    this.currentTime = this.state.startTime;
    if (this.cfg.debug) {
      console.log(`Timer (start):`, {
        currentTime: this.currentTime,
        state: this.state,
      });
    }
    this.startTimer();
    this.sendEvents(TimerEvents.OnStart);
    return this;
  };

  public stop = () => {
    this.reset();
    this.sendEvents(TimerEvents.OnStop);
  };

  public pause = () => {
    clearInterval(this.timer);
    this.sendEvents(TimerEvents.OnPause);
  };

  public resume = () => {
    this.startTimer();
    this.sendEvents(TimerEvents.OnResume);
  };

  public setDuration = (newDuration: number) => {
    this.cfg.duration = newDuration;
    this.reset();
  };
}
