import Big from 'big.js';
import { useState, useEffect } from 'react';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/AppController';
import { TMGEvents } from '../../../../data/Controllers/TokenMiniGames/TMGController';
import { useManyAppUpdates } from '../../../../data/hooks';
import {
  getMyToken,
  TradingToken,
  getMarketCap,
  hasReachedMemeHoldingLimit,
} from '../../../../replicant/features/offchainTrading/offchainTrading.getters';
import {
  TradingTokenListing,
  TxType,
} from '../../../../replicant/features/offchainTrading/types';
import {
  displayTokenAmount,
  largeNumberToLetter,
  formatPrice,
} from '../../../../replicant/utils/numbers';
import { TradingMiniChart } from '../../TradingTokenPage/Charts/TradingMiniChart';
import { TiktokItemTransactionBubbles } from '../TiktokItemTransactionBubbles/TiktokItemTransactionBubbles';
import { FeedMemeFooter } from './FeedMemeFooter';
import { SidebarUI } from './FeedMemeSidebar';
import { tmgRuleset } from '../../../../replicant/features/offchainTrading/offchainTrading.ruleset';
import { t } from 'i18next';

interface FeedMemeBottomUIProps {
  token: TradingTokenListing;
  uiAlpha: number;
  selected: boolean;
  slideIndex: number;
  canShowTapGame: boolean;
}

export const FeedMemeBottomUI = ({
  token,
  uiAlpha,
  selected,
  slideIndex,
  canShowTapGame,
}: FeedMemeBottomUIProps) => {
  useManyAppUpdates({
    id: 'FeedMemeBottomUI',
    events: [
      {
        listener: app.ttg.attachEventListener(TMGEvents.OnTappingTap),
        dep: selected,
      },
      {
        listener: app.ttg.attachEventListener(TMGEvents.OnPointsUpdate),
        dep: selected,
      },
    ],
  });

  const { offchainTokenId } = token;

  const priceChange = 100 * (token.priceChange?.last24hours ?? 0);
  const color = priceChange === 0 ? 'white' : priceChange > 0 ? 'green' : 'red';
  const iconArrow =
    priceChange > 0 ? assets.arrow_up_green : assets.arrow_down_red;

  const [offchainTokenState, setOffchainTokenState] = useState<TradingToken>();
  const [tokenAmountOwned, setTokenAmountOwned] = useState<string>(
    Big(0).toString(),
  );

  const { currentMeme } = app.memes;

  const holdersCount = currentMeme.token?.holderCount || 0;
  const myToken = getMyToken(app.state, offchainTokenId);

  useEffect(() => {
    setTokenAmountOwned(myToken?.tokenAmount ?? 0);

    if (offchainTokenId) {
      app.memes
        .getToken(offchainTokenId, 'fetch')
        .then((offchainTokenState) => {
          setOffchainTokenState(offchainTokenState);
        });
    }
  }, [
    uiAlpha,
    offchainTokenId,
    currentMeme.token?.id,
    currentMeme.token?.buyPrice,
    myToken?.tokenAmount,
  ]);

  const marketCap = getMarketCap(Big(offchainTokenState?.supply ?? 0));

  const onTapDetails = () => {
    // open tiktok details drawer
    app.ui.drawer.show({
      id: 'drawerTiktokDetails',
      hideClose: true,
      props: { tokenData: token },
    });
  };

  const onTapPurchaseButton = (txType: TxType) => {
    if (
      txType === 'buy' &&
      hasReachedMemeHoldingLimit(app.state, offchainTokenId)
    ) {
      app.ui.drawer.show({
        id: 'drawerTradingWarning',
        props: {
          tradingWarning: {
            warningTitle: t('meme_limit_reached_holding_title'),
            warningMessage: t('meme_limit_reached_holding_message', {
              limit: tmgRuleset.holdingLimit,
            }),
            warningCta: t('meme_limit_reached_holding_cta'),
            icon: assets.trading_transaction_success,
          },
        },
      });

      return;
    }

    // first, set purchase mode
    app.memes.trading.setPurchaseMode(txType);

    // then open buy/sell drawer
    app.ui.drawer.show({
      id: 'drawerTiktokBuySell',
    });

    // debug log
    const item = app.memes.currentMeme.token;
    console.warn('>>> onTapPurchaseButton', txType, item?.id, item?.name);
  };

  const onTapHoldersButton = () => {
    // open tiktok holders drawer
    app.ui.drawer.show({
      id: 'drawerTiktokHolders',
      hideClose: true,
    });
  };

  const tokenAmountOwnedStr = displayTokenAmount(
    Big(tokenAmountOwned).toNumber(),
    4,
    true,
  );

  // decide if normal bottom-ui is going to fade in or out
  const isDisabled = app.ttg.isTapping(); // selected && app.ttg.tap?.showPoints && canShowTapGame;
  let fadeClass = isDisabled ? 'fade-out' : 'fade-in-slow';

  return (
    <div className="feed-item-ui-bottom" style={{ opacity: uiAlpha }}>
      <div className={`feed-item-ui-content ${fadeClass}`}>
        {/* transaction notification bubbles */}
        <TiktokItemTransactionBubbles
          tokenState={offchainTokenState}
          selected={selected}
        />

        {/* item row -> item, graph, value */}
        <div className="row token">
          <SidebarUI token={token} />

          {/* left - item info */}
          <div className="btn item" onClick={onTapDetails}>
            <div className="item-circle">
              <div className="item-image">
                <img className="image" src={token.image} />
              </div>
              <div className={`item-position ${slideIndex < 3 ? 'top3' : ''}`}>
                #{slideIndex + 1}
              </div>
            </div>
            <div className="info">
              <div className="row left">
                <div className="label tickerName">${token.ticker}</div>
              </div>

              <div className="row left">
                <div
                  className="label owners"
                  dangerouslySetInnerHTML={{ __html: tokenAmountOwnedStr }}
                />
                <div className="label owners">{token.ticker} POINTS</div>
              </div>
            </div>
          </div>

          {/* right - chart and value */}
          <div className="row right">
            {/* mini-chart */}
            <div className="btn mini-chart" onClick={onTapDetails}>
              {offchainTokenState && (
                <TradingMiniChart tokenState={offchainTokenState} height={33} />
              )}
            </div>

            {/* item value */}
            <div className="btn value-area" onClick={onTapDetails}>
              <div className="price-container">
                <div className="coin">
                  <img src={assets.coin} />
                </div>
                <div className="label">
                  {largeNumberToLetter(marketCap.toNumber(), 3)}
                </div>
              </div>
              <div className="percent-container">
                <div className={`arrow ${color}`}>
                  {priceChange !== 0 && <img src={iconArrow} />}
                </div>
                <div className={`percent ${color}`}>
                  {formatPrice(Math.round(priceChange * 10) / 10)}%
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* token name + holders */}

        <div className="row left">
          <div className="label tokenName">{token.name}</div>
          <div className="label dot">{`•`}</div>
          <div
            className="btn box left transparent"
            onClick={onTapHoldersButton}
          >
            <div className="label holders">{t('tiktok_holders')}</div>
            <div className="label holders">
              {largeNumberToLetter(holdersCount, 3)}
            </div>
          </div>
        </div>

        {/* buy/sell buttons */}
        <FeedMemeFooter onTapPurchaseButton={onTapPurchaseButton} />
      </div>
    </div>
  );
};
