import { assets } from '../../../assets/assets';
import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UIController';
import { waitFor } from '../../utils';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

/**
 * Don't forget to call `Grant15kState` module as next step
 */
export class YouAreDoingGreat15k extends TutorialModule {
  id = 'YouAreDoingGreat15k' as 'YouAreDoingGreat15k';

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });

    this.app.ui.setClickerUIState({
      btnClicker: ElementUIState.Normal,
      btnLeague: ElementUIState.Inactive,

      footer: ElementUIState.Normal,

      btnFriends: ElementUIState.Normal,
      btnMine: ElementUIState.Mystery,
      btnEarn: ElementUIState.Mystery,
      btnBooster: ElementUIState.Normal,
      btnTeam: ElementUIState.Hidden,
    });

    this.app.nav.goToHomePage();

    await waitFor(500);

    this.app.ui.drawer.show({
      id: 'generic',
      hideClose: true,
      opts: {
        image: assets.sloth_teams,
        title: t('tut_drawer_gift15K_title'), // 'You’re doing great!',
        subtitle: t('tut_drawer_gift15K_description'), // 'We’re thrilled to have you here. Here’s a gift to help you earn faster!',
        score: '1,000',
        onClose: () => {
          this.app.ui.animateBalance(1000, false);
          this.closeDrawerAndContinue();
        },
        buttons: [
          {
            cta: t('tut_drawer_gift15K_cta'), // 'Get',
            onClick: () => {
              // Animate the 15k here but only actually grant them in state when the next step starts
              this.app.ui.animateBalance(1000, false);
              this.closeDrawerAndContinue();
            },
          },
        ],
      },
    });

    await waitFor(1000);

    return super.execute().then(() => {});
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_mine_coins_gift_dialog',
      data: {
        gift_amount: 1_000,
      },
    };
  }
}
