import '../../pages/Shop/Shop.scss';
import { app } from '../../../data/AppController';
import { assets } from '../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../replicant/utils/numbers';

export const DrawerWelcome = () => {
  const { t } = useTranslation();

  // This came hardcoded as a value from ModalComponent; But should be 500 for new user and 2500 with friend invite
  const price = 500;

  return (
    <>
      <div className="booster-details-layout">
        <div className="booster-details-header">
          <div className="booster-details-icon">
            <img src={assets.sloth_earning_summary} />
          </div>
          <div className="booster-details-title">
            {t('buff_autobotfirstsession_name')}
          </div>
          <div className="booster-details-subtitle">
            {t('buff_autobotfirstsession_desc')}
          </div>
        </div>

        <div className="booster-details-body">
          <div className="booster-details-effect">
            {t('buff_autobotfirstsession_effect')}
          </div>
          <div className="booster-details-points">
            <img className="booster-details-points-icon" src={assets.coin} />
            <div className="booster-details-points-value">
              {formatPrice(price)}
            </div>
          </div>
        </div>
      </div>

      <div className="footer-buttons">
        <div className="btn btn-normal" onClick={app.ui.drawer.close}>
          {t('get_button')}
        </div>
      </div>
    </>
  );
};
