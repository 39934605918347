import './DrawerTradingTransaction.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/AppController';
import { displayTokenAmount } from '../../../../replicant/utils/numbers';
import Big from 'big.js';

export const DrawerTradingTransactionSuccess = () => {
  const { t } = useTranslation();

  // get offchainToken props and buy/sell mode
  const props = app.ui.drawer.view.data?.props?.transactionSuccess;

  const onShare = () => {
    app.memes.shareOffchainToken('transaction_success_drawer', props);
  };

  const isBuy = props?.mode === 'buy';
  const icon = isBuy ? props?.offchainTokenImage || '' : assets.coin;
  const currency = isBuy ? props?.offchainTokenName : 'Coins';

  return (
    <div className="drawer-trading-transaction">
      <div className="drawer-trading-transaction-header">
        <img
          className="drawer-trading-transaction-icon"
          src={assets.trading_transaction_success}
        />

        <div className="drawer-trading-transaction-title">{`Transaction Successful`}</div>
        <div className="drawer-trading-transaction-subtitle">{`You received`}</div>
        <div className="drawer-trading-transaction-reward">
          <img className="icon" src={icon} />
          <div
            className="value"
            dangerouslySetInnerHTML={{
              __html: isBuy
                ? displayTokenAmount(
                    Big(props?.offchainTokenValue ?? 0).toNumber(),
                    4,
                    true,
                  )
                : props?.offchainTokenValue || '',
            }}
          ></div>
          <div className="currency">{currency}</div>
        </div>
      </div>

      <div className="footer-buttons">
        {/* share */}
        <div
          className={`btn btn-normal`}
          onClick={() => {
            app.ui.confetti.hide();
            app.ui.drawer.close();
            onShare();
          }}
        >
          Share
        </div>
      </div>
    </div>
  );
};
