import './TradingTokenItem.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { getUIStateClassName } from '../../../../data/utils';
import { app } from '../../../../data/AppController';
import { largeIntegerToLetter } from '../../../../replicant/utils/numbers';
import { TradingTokenListing } from '../../../../replicant/features/offchainTrading/types';
import Big from 'big.js';

interface Props {
  offchainTokenData: TradingTokenListing;
}

let hasClickedCreator = false;

export const TradingTokenItem = ({ offchainTokenData }: Props) => {
  const { t } = useTranslation();

  const {
    name,
    offchainTokenId,
    image,
    creatorId,
    creatorName,
    creatorImage,
    marketCap,
    ticker,
  } = offchainTokenData;

  const tickerName = ticker;

  const onShowOffchainTokenDetails = async () => {
    if (hasClickedCreator) {
      return;
    }

    // navigate to tiktok feed instead of token details
    // app.nav.goToTradingToken(offchainTokenId);
    app.nav.goToTiktokFeed(offchainTokenId, undefined, 'feed-list');
  };

  let marketCapValue =
    marketCap instanceof Big ? marketCap.toNumber() : marketCap;

  const onCreatorClick = () => {
    // Use 'hasClickedCreator' to prevent the click on the name to open the token page
    hasClickedCreator = true;
    app.nav.goToProfile(creatorId);
    setTimeout(() => {
      hasClickedCreator = false;
    });
  };

  return (
    <div
      className={`btn trading-token-item ${getUIStateClassName(
        app.ui.getCardState(offchainTokenId),
      )}`}
      onClick={onShowOffchainTokenDetails}
    >
      {/* <Badge
        visible={hasBadge}
        hasNoText
        checked={app.ui.powerupOffchainTokensBadgesState[id]}
      /> */}

      <div className="trading-token-item-container">
        <div className="trading-token-item-image">
          <img src={image} />
        </div>

        <div className="trading-token-item-content">
          <div className="trading-token-item-top">
            <div className="trading-token-item-title">{name}</div>

            {/* market cap */}
            <div className="trading-token-item-market-cap">
              <div className="trading-token-item-market-cap-label">
                {t('trading_token_item_market_cap')}
              </div>
              <div className="trading-token-item-market-cap-value">
                <div className="icon">
                  <img src={assets.coin} />
                </div>
                <div className="label">
                  {largeIntegerToLetter(marketCapValue, 3)}
                </div>
              </div>
              {/* todo(Cai): hook this with real data */}
              {/* <div className="trading-token-item-market-cap-percent">+300%</div> */}
            </div>
          </div>

          <div className="trading-token-item-bottom">
            <div className="trading-token-item-author">
              {/* ticker name */}
              {tickerName && (
                <>
                  <div className="trading-token-item-author-label">
                    {tickerName}
                  </div>
                  <div className="trading-token-item-author-label">•</div>
                </>
              )}

              {/* author */}
              <div
                className="trading-token-item-author-tappable"
                onClick={onCreatorClick}
              >
                <div className="trading-token-item-author-label">
                  {t('tiktok_by')}
                </div>
                <div className="trading-token-item-author-image">
                  <img src={creatorImage} />
                </div>
                <div className="trading-token-item-author-name">
                  {creatorName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
