import './JoinTeam.scss';
import { Page } from '../Page';
import {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useRef,
  FormEventHandler,
} from 'react';
import { AppEvents, app, useAppUpdates } from '../../../data/AppController';
import { assets } from '../../../assets/assets';
import { GlowingImage } from '../../shared/GlowingImage/GlowingImage';
import { CREATE_TEAM_COST } from '../../../replicant/features/teams/teams.ruleset';
import { ButtonInvite } from '../../shared/ButtonInvite/ButtonInvite';
import { useTranslation } from 'react-i18next';
import { LeaderboardCard } from './LeaderboardCard';
import { Team } from '../../../data/types';
import { setUserScrolling } from '../../../data/utils';
import { isMobileEmulatedByBrowser } from '../../../data/device';

const SEARCH_DEBOUNCE_MS = 500;
const MAX_SEARCH_CHARS = 50;

export const JoinTeam = () => {
  useAppUpdates({
    id: 'JoinTeam-1',
    listener: app.views.JoinTeam.attachEventListener(),
  });

  useAppUpdates({
    id: 'JoinTeam-2',
    listener: app.attachEventListener(AppEvents.onMyTeamUpdate),
  });

  const { t } = useTranslation();

  const { playerTeam, playerBalance } = app;

  const { data, loading, visible, fetch } = app.views.JoinTeam;

  const [searchedTeams, setSearchedTeams] = useState<Team[] | undefined>();

  const teams = searchedTeams || data || [];

  const canAffordCreate = playerBalance >= CREATE_TEAM_COST;

  useEffect(() => {
    if (visible) fetch();
  }, [visible]);

  const onOpenDetails = () => {
    app.tutorial.step?.onAction && app.tutorial.step?.onAction('reset-timeout');
    app.ui.drawer.show({ id: 'createTeam' });
  };

  const onSearchResponse = useCallback((t: Team[] | undefined) => {
    // if t is undefined, then search bar is empty, so show default team list
    setSearchedTeams(t);
  }, []);

  return (
    <Page id="modal-team-list" visible={visible}>
      {/* header with astronauts and blue glow */}

      <div className="modal-header jointeam">
        <GlowingImage
          noGlow={true}
          image={assets.sloth_teams}
          blur={70}
          intensity={0.4}
        />
        <div className="modal-header-title">{t('team_join_title')}</div>
        <div className="modal-header-subtitle">{t('team_join_subtitle')}</div>
      </div>

      {/* buttons: invite / join */}

      {loading ? (
        <p className="loading-label fixed">{t('loading')}</p>
      ) : (
        <>
          {!playerTeam && (
            <div className="footer-buttons vertical">
              <ButtonInvite
                caption={t('team_invite_button')}
                feature={'team'}
                subFeature="team_join"
              />
              <div className="btn btn-normal" onClick={onOpenDetails}>
                {t('team_create_button')}
              </div>
              <TeamSearchBar onSearchResponse={onSearchResponse} />
            </div>
          )}

          {/* leaderboard - list of teams */}

          {loading ? (
            <p className="loading-label fixed">{t('loading')}</p>
          ) : (
            <div className="team-list">
              {searchedTeams?.length === 0 && (
                <p className="team-no-results">{t('team_search_no_results')}</p>
              )}
              {teams.map((team, i) => (
                <LeaderboardCard
                  key={team.id + i}
                  teamId={team.id}
                  name={team.name}
                  members={team.members}
                  score={team.score}
                  rank={team.rank}
                  image={team.photo}
                  fromSearch={Boolean(searchedTeams)}
                />
              ))}
            </div>
          )}
        </>
      )}
    </Page>
  );
};

const TeamSearchBar = ({
  onSearchResponse,
}: {
  onSearchResponse: (teams: Team[] | undefined) => void;
}) => {
  const { t } = useTranslation();

  const inputRef = useRef<any>();
  const [isInputFocused, setInputFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    // react will reload the page for some reason when submitting a form
    // this will prevent it to happen
    event.preventDefault();

    // close the keyboard
    inputRef.current?.blur();

    // clear user input after submitting
    // setSearchTerm('');
  };

  const onInputFocus = (focused: boolean) => {
    setInputFocused(focused);

    // prevent scrolling while window open
    setUserScrolling(!focused);

    function setPageHeightStyle(heightStyle: string) {
      const page = document.querySelector(
        '.page.modal-team-list',
      ) as HTMLDivElement;

      if (page) page.style.minHeight = heightStyle;
    }

    if (focused) {
      // when keyboard opens, needs time to finish his own transition
      // then we need to push content up so search bar renders at the top of the screen
      // note: while doing this, we need to make sure that both
      // body and page have enough height for window scroll to work properly
      if (!isMobileEmulatedByBrowser()) {
        setTimeout(() => {
          document.body.style.height = window.innerHeight + 1000 + 'px';
          setPageHeightStyle('1600px');
          window.scrollTo({ top: 552, behavior: 'smooth' }); // this seems to work for all phone resolutions
        }, 500);
      }
    } else {
      setPageHeightStyle('unset');
    }
  };

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const term = e.currentTarget.value;
    if (term.length <= MAX_SEARCH_CHARS) {
      setSearchTerm(term);
    }
  }, []);

  // debounce
  useEffect(() => {
    let stale = false;
    const timeoutId = setTimeout(() => {
      app.searchTeams(searchTerm).then((teams) => {
        // searchTerm can be updated mid-request and thus become stale
        if (stale) {
          return;
        }

        onSearchResponse(teams);
      });
    }, SEARCH_DEBOUNCE_MS);

    return () => {
      stale = true;
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  return (
    <div className="team-search-bar">
      <form onSubmit={onFormSubmit}>
        <input
          ref={inputRef}
          type="input"
          //
          inputMode="text"
          autoCapitalize="on"
          autoComplete="on"
          autoCorrect="on"
          enterKeyHint="search"
          //
          className="team-search-bar-input"
          placeholder={t('team_search_placeholder')}
          value={searchTerm}
          onChange={onInputChange}
          autoFocus={false}
          onFocus={() => onInputFocus(true)}
          onBlur={() => onInputFocus(false)}
        />

        <div className="icon-search">
          <img src={assets.icon_search} />
        </div>
      </form>
    </div>
  );
};

export default JoinTeam;
