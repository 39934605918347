import { useEffect, useState } from 'react';
import './Toast.scss';
import { useAppUpdates, app } from '../../../data/AppController';
import { useTranslation } from 'react-i18next';

export const Toast = () => {
  useAppUpdates({
    id: 'TeamPage',
    listener: app.views.Toast.attachEventListener(),
  });

  const { t } = useTranslation();

  const { visible, data } = app.views.Toast;

  const [up, setUp] = useState(false);

  const [showedOnce, setShowedOnce] = useState(false);

  useEffect(() => {
    if (visible) {
      setShowedOnce(true);
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      setUp(true);
      setTimeout(() => {
        setUp(false);
        app.views.Toast.hide();
      }, 2000);
    }
  }, [visible]);

  return (
    <div
      className={`toast-ctn slide-${up ? 'up' : 'down'} ${
        !showedOnce && 'hide'
      }`}
    >
      <b>{data?.text}</b>{' '}
      {data?.hidePurchaseText ? '' : t('toast_purchased_successfully')}
    </div>
  );
};
