import Big from 'big.js';
import {
  OwnedOffchainToken,
  PriceSlice,
} from '../replicant/features/game/player.schema';
import {
  maxTokenAllTimeSliceCount,
  SliceConfig,
} from '../replicant/features/offchainTrading/offchainTrading.ruleset';
import { app } from './AppController';
import { GraphPoint } from '../components/pages/TradingPage/LightweightChartComponent/LightweightChartComponent';
import { timeInSec } from './utils';
import {
  computePortfolioValue,
  getCurrencyInvested,
  getMarketCap,
  getGrossCoinAmountForTokenSell,
  TradingToken,
} from '../replicant/features/offchainTrading/offchainTrading.getters';
import { TradingSearchResult } from '../replicant/features/offchainTrading/offchainTrading.properties';
import { getRoi } from '../replicant/utils/numbers';
import { State } from '../replicant/schema';

export const getPricePoints = (
  priceSlices: PriceSlice[],
  sliceConfig: SliceConfig | null,
  latestPrice: number,
) => {
  const now = app.now();

  // uncomment to test items with no transactions
  // let testTime = now;
  // new Array(100).fill(0).forEach((_, index) => {
  //   testTime -= Math.round(Math.random() * HOUR_IN_MS);
  //   // const time = now - Math.random() * 10 * HOUR_IN_MS;

  //   priceSlices.unshift({
  //     time: testTime,
  //     price: Math.round(Math.random() * offchainToken.buyPrice),
  //   });
  // });

  // remove data points that are not sorted in time
  let time = 0;
  priceSlices = priceSlices.filter((slice) => {
    const forwardInTime = slice.time > time;
    if (forwardInTime) {
      time = slice.time;
    }
    return forwardInTime;
  });

  const pricePoints: GraphPoint[] = [];

  if (sliceConfig === null) {
    if (priceSlices.length === 0) {
      return pricePoints;
    }
    // trying to make the price intervals match the all time definition,
    // however it cannot exceed a certain limit of the graph wont display it (bug with the chart?)
    const priceIntervals = Math.min(150, maxTokenAllTimeSliceCount);
    const timeWindow = now - priceSlices[0].time;

    pricePoints.push({
      time: timeInSec(now - timeWindow),
      value: Big(priceSlices[0].price).toNumber(),
    });

    let j = 0;
    let price = Big(-1);
    for (let i = 0; i <= priceIntervals; i += 1) {
      const time = now - timeWindow + (timeWindow * i) / priceIntervals;
      while (j < priceSlices.length && priceSlices[j].time < time) {
        price = Big(priceSlices[j].price);
        j += 1;
      }

      if (!price.eq(-1)) {
        pricePoints.push({
          time: timeInSec(time),
          value: price.toNumber(),
        });
      }
    }

    pricePoints.push({
      time: pricePoints[pricePoints.length - 1].time + 1,
      value: Big(priceSlices[priceSlices.length - 1].price).toNumber(),
    });
  } else {
    // remove price points outside of the interval
    // and add missing slices
    const timeInterval = sliceConfig.interval;
    const windowTimeStart = now - sliceConfig.window;

    let startIdx = 0;
    while (
      startIdx < priceSlices.length &&
      priceSlices[startIdx].time < windowTimeStart
    ) {
      startIdx += 1;
    }
    startIdx = Math.max(0, startIdx - 1);

    let sliceIdx = startIdx;
    // temp fix since it was breaking the app, @cai please confirm if this is correct
    if (priceSlices.length === 0) {
      return pricePoints;
    }
    let sliceTime = priceSlices[sliceIdx].time;
    while (sliceTime <= now) {
      while (
        sliceIdx < priceSlices.length &&
        priceSlices[sliceIdx].time < sliceTime
      ) {
        sliceIdx += 1;
      }

      if (sliceIdx === priceSlices.length) {
        break;
      }

      const slice = priceSlices[sliceIdx];
      pricePoints.push({
        time: timeInSec(sliceTime),
        value: Big(slice.price).toNumber(),
      });

      sliceTime = sliceTime + timeInterval;
    }

    // always add latest price as last slice
    pricePoints.push({
      time: timeInSec(sliceTime),
      value: latestPrice,
    });

    if (now > sliceTime) {
      pricePoints.push({
        time: timeInSec(now),
        value: latestPrice,
      });
    }
  }

  return pricePoints;
};

export const getOffchainTokenPricePoints = (
  offchainToken: TradingToken,
  sliceConfig: SliceConfig | null,
) => {
  const priceSlices =
    offchainToken.trends[sliceConfig?.windowId || 'allTime'].slice();
  const latestPrice = Big(offchainToken.buyPrice).toNumber();

  return getPricePoints(priceSlices, sliceConfig, latestPrice);
};

export const getPortfolioPricePoints = (
  state: State,
  sliceConfig: SliceConfig | null,
  ownedOffchainTokens: TradingSearchResult[],
) => {
  const priceSlices =
    state.trading.offchain.portfolioTrends[
      sliceConfig?.windowId || 'allTime'
    ].slice();

  const latestPrice = computePortfolioValue(
    state,
    ownedOffchainTokens,
  ).toNumber();

  return getPricePoints(priceSlices, sliceConfig, latestPrice);
};

export const getOffchainTokenHoldCount = (state: State) => {
  return Object.values(state.trading.offchainTokens).reduce(
    (count, offchainToken) => {
      const tokenAmount = Big(offchainToken.tokenAmount).toNumber();
      return tokenAmount > 0 ? count + 1 : count;
    },
    0,
  );
};

export const getPortfolioRoi = (state: State, portfolioValue: number) => {
  const currencyInvested = getCurrencyInvested(state).toNumber();
  const roi = getRoi(currencyInvested + state.balance, portfolioValue);
  return roi;
};

export const getOffchainTokenHoldingStats = (
  offchainTokenId: string,
  ownedOffchainTokens: TradingSearchResult[],
  tokenHolding?: OwnedOffchainToken,
) => {
  const tokenStatus = ownedOffchainTokens.find((tokenStatus) => {
    return tokenStatus.id === offchainTokenId;
  });
  if (!tokenStatus) {
    return {
      valuation: 0,
      roi: 0,
      marketCap: 0,
    };
  }

  const marketCap = getMarketCap(Big(tokenStatus.supply)).toNumber();
  if (!tokenHolding) {
    return {
      valuation: 0,
      roi: 0,
      marketCap,
    };
  }

  const currencyInvested = Big(tokenHolding.currencyInvested).toNumber();
  const holdingValuation = getGrossCoinAmountForTokenSell(
    Big(tokenStatus.supply),
    Big(tokenHolding.tokenAmount),
  ).toNumber();

  const roi = getRoi(currencyInvested, holdingValuation);

  // console.warn(
  //   '>>> getOffchainTokenHoldingStats',
  //   holdingValuation,
  //   roi,
  //   marketCap,
  // );

  return {
    valuation: holdingValuation,
    roi,
    marketCap,
  };
};
