import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UIController';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class TeamsPage extends TutorialModule {
  id = 'TeamsPage' as 'TeamsPage';
  // @TODO: might require some extra logic because users can navigate to specific team
  onBack = () => {
    this.onComplete();
  };

  private timeout?: NodeJS.Timeout;

  onAction = (id?: string) => {
    this.app.ui.tooltip.hide();
    this.onComplete();
  };

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });

    this.app.ui.setClickerUIState({
      btnClicker: ElementUIState.Normal,
      btnLeague: ElementUIState.Inactive,

      footer: ElementUIState.Normal,

      btnFriends: ElementUIState.Normal,
      btnMine: ElementUIState.Normal,
      btnEarn: ElementUIState.Normal,
      btnBooster: ElementUIState.Normal,
      btnTeam: ElementUIState.Highlight,
    });

    const teamId = this.app.state.team_id;

    if (teamId) {
      this.app.views.TeamPage.fetch(teamId);
      this.app.nav.goTo('TeamPage');
    } else {
      this.app.nav.goTo('JoinTeam');
    }

    const isInATeam = Boolean(this.app.state.team_id);

    this.app.tutorial.showTooltip({
      text: isInATeam
        ? t('tut_tooltip_team_teammates')
        : t('tut_tooltip_team_join'),
      origin: { x: '50%', y: '0%' },
      offset: { x: '0px', y: isInATeam ? '680px' : '610px' },
      align: 'top',
    });

    // todo: remove?
    // Load hand if the user is still in page after 5 seconds
    // remember to clear timeout on super.execute
    // this.timeout = this.addBackTooltip(10_000);

    return super.execute().then(() => {
      this.app.nav.goToHomePage();
      // todo: remove?
      // if (this.timeout) clearTimeout(this.timeout);
      this.app.ui.tooltip.hide();
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_teams_page_prompt',
      data: {
        has_team: Boolean(this.app.state.team_id),
      },
    };
  }
}
