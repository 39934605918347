import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Chart.scss';
import { app } from '../../../../data/AppController';
import { TradingToken } from '../../../../replicant/features/offchainTrading/offchainTrading.getters';
import {
  LightweightChartComponent,
  GraphPoint,
} from '../../TradingPage/LightweightChartComponent/LightweightChartComponent';
import {
  offchainTokenDisplayMultiplier,
  SliceConfig,
  tokenPriceSliceConfigs,
} from '../../../../replicant/features/offchainTrading/offchainTrading.ruleset';

interface TimeInterval {
  sliceConfig: SliceConfig | null;
  text: string;
  textP?: Record<string, any>;
}

const intervals: TimeInterval[] = [
  {
    sliceConfig: tokenPriceSliceConfigs.hour24,
    text: 'trading_chart_1_day',
  },
  {
    sliceConfig: tokenPriceSliceConfigs.day7,
    text: 'trading_chart_x_days',
    textP: { days: 7 },
  },
  {
    sliceConfig: tokenPriceSliceConfigs.day30,
    text: 'trading_chart_x_days',
    textP: { days: 30 },
  },
  {
    sliceConfig: null,
    text: 'trading_chart_all_time',
  },
];

interface Props {
  tokenState: TradingToken;
  height: number;
}

export const TradingChart = ({ tokenState, height }: Props) => {
  // If you are translating within the component use `i18n.t()` not the imported `t()`
  const { t } = useTranslation();

  const [priceData, setPriceData] = useState<GraphPoint[]>([]);

  const [selectedInterval, setSelectedInterval] = useState(intervals[3]);

  useEffect(() => {
    setPriceData(
      app.memes.trading.getOffchainTokenPricePoints(
        tokenState,
        selectedInterval.sliceConfig,
      ),
    );
  }, [tokenState.id, selectedInterval]);

  // bottom gradient
  const colors = {
    backgroundColor: 'transparent',
    lineColor: 'rgba(0, 150, 0, 1)', // #2962FF',
    textColor: '#777', //'white',
    areaTopColor: 'rgba(0, 150, 0, 1)', // '#2962FF',
    areaBottomColor: 'rgba(0, 150, 0, 0.0)', // 'rgba(41, 98, 255, 0.28)',
  };

  return (
    <div className="trading-chart">
      <LightweightChartComponent
        data={priceData}
        colors={colors}
        height={height}
        margin={0}
        lineWidth={4}
        multiplier={1 / offchainTokenDisplayMultiplier}
      />
      <div className="time-interval-buttons">
        {intervals.map((interval, index) => (
          <button
            key={`${interval.text}_${index}`}
            className={`interval-button ${
              selectedInterval.sliceConfig === interval.sliceConfig
                ? 'active'
                : ''
            }`}
            onClick={() => setSelectedInterval(interval)}
          >
            {t(interval.text, interval.textP)}
          </button>
        ))}
      </div>
    </div>
  );
};
