import './DrawerGenericOutcome.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/AppController';
import { useEffect } from 'react';

export const DrawerGenericError = () => {
  const { t } = useTranslation();

  const opts = app.ui.drawer.view.data?.opts;

  // get error message
  const errorMessage = opts?.subtitle ?? 'An unexpected error has occured';
  const title = opts?.title ?? 'Error';
  const btn = opts?.buttons?.[0];
  const cta = btn?.cta ?? 'Okay';
  const onClick = () => {
    btn?.onClick && btn?.onClick();
    app.ui.confetti.hide();
    app.ui.drawer.close();
  };

  useEffect(() => {
    app.ui.drawer.setStyle({
      border: '1px solid #d00948',
      borderBottom: 'unset',
    });
    return () => {
      app.ui.drawer.setStyle(undefined);
    };
  }, []);

  return (
    <div className="drawer-trading-transaction">
      <div className="drawer-trading-transaction-header">
        <img
          className="drawer-trading-transaction-icon"
          src={assets.trading_transaction_error}
        />

        <div className="drawer-trading-transaction-title">{title}</div>
        <div className="drawer-trading-transaction-subtitle">
          {errorMessage}
        </div>
      </div>

      <div className="footer-buttons">
        <div className={`btn btn-normal`} onClick={onClick}>
          {cta}
        </div>
      </div>
    </div>
  );
};
