import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UIController';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class TeamsButton extends TutorialModule {
  id = 'TeamsButton' as 'TeamsButton';

  onAction = (id?: string) => {
    this.app.ui.tooltip.hide();
    if (id === 'team') {
      this.onComplete();
    }
  };

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });
    this.app.ui.setClickerUIState({
      btnClicker: ElementUIState.Normal,
      btnLeague: ElementUIState.Inactive,

      footer: ElementUIState.Normal,

      btnFriends: ElementUIState.Normal,
      btnMine: ElementUIState.Normal,
      btnEarn: ElementUIState.Normal,
      btnBooster: ElementUIState.Normal,
      btnTeam: ElementUIState.Highlight,
    });

    const isInATeam = Boolean(this.app.state.team_id);

    this.app.tutorial.showTooltip({
      text: isInATeam
        ? t('tut_tooltip_team_buttoncheckout')
        : t('tut_tooltip_team_buttonjoin'),
      origin: { x: '50%', y: '0%' },
      offset: { x: '0px', y: '148px' },
      align: 'top',
    });

    return super.execute().then(() => {
      this.app.ui.popup.close();
      this.app.ui.setClickerUIState({ btnTeam: ElementUIState.Normal });
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_team_highlight',
    };
  }
}
