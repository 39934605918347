import {
  getMyOffchainTokenPointsAccumulated,
  getMyOffchainTokenTokenAmount,
} from '../../../replicant/features/offchainTrading/offchainTrading.getters';
import { AppController, AppEvents } from '../../AppController';
import { EventListener, SendEventFn } from '../../EventListener';
import { Optional } from '../../types';
import { TMGEvents } from './TMGController';

/**
 * TokenMiniGame is the base class for all TMG (Token Mini Games) features;
 * It normalises event dispatching proxied via TMGController (so consuming components listen to the same controller instead of each feature)
 * It normalises the context of current selected trading token by exposing `tokenId`
 * @TODO Add here any other common functionality among all TMG features
 */
export class TokenMiniGame<
  T extends Record<string, any>,
> extends EventListener {
  protected get tokenId() {
    return this.app.memes.currentMeme.token?.id;
  }

  constructor(protected app: AppController, private tmgSendEvent: SendEventFn) {
    super();
    this.app.addEventListener(AppEvents.onAppReady, () => {
      this.init();
    });
  }

  protected async init() {}

  protected sendEvent(evt: TMGEvents) {
    this.tmgSendEvent(evt);
  }

  protected track = (evt: string, extraProps: Record<string, any> = {}) => {
    const token = this.app.memes.currentMeme.token;
    if (!token) {
      return;
    }

    this.app.track(evt, {
      cardID: token.id,
      memecard_ticker: token.ticker,
      memecard_name: token.name,
      memecard_creator: token.creatorName,
      token_amount_owned: getMyOffchainTokenTokenAmount(
        this.app.state,
        token.id,
      ),
      points_ever_owned: getMyOffchainTokenPointsAccumulated(
        this.app.state,
        token.id,
      ),
      ...extraProps,
    });
  };

  public get state(): Optional<T> {
    return {} as T;
  }
}
