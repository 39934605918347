import './DrawerDailyReward.scss';
import { assets } from '../../../assets/assets';
import { useEffect, useState } from 'react';
import { app } from '../../../data/AppController';
import { DAILY_REWARDS } from '../../../replicant/features/game/ruleset/dailyRewards';
import { getUnclaimedRewardStartIndex } from '../../../replicant/features/game/game.getters';
import { useTranslation } from 'react-i18next';
import { largeIntegerToLetter } from '../../../replicant/utils/numbers';
import { ElementUIState } from '../../../data/Controllers/UIController/UIController';

export const DrawerDailyReward = () => {
  const { state } = app;
  const [disabled, setDisabled] = useState(!state.unclaimed_rewards);
  const [unclaimedRewardsStartIndex, setUnclaimedRewardsStartIndex] = useState(
    getUnclaimedRewardStartIndex(state),
  );
  const { t } = useTranslation();

  useEffect(() => {
    setUnclaimedRewardsStartIndex(getUnclaimedRewardStartIndex(state));
  }, [state.streak_days, state.unclaimed_rewards]);

  const onClaimClick = async () => {
    if (disabled) {
      return;
    }
    app.ui.confetti.show(false);
    setDisabled(true);

    const claimed = await app.invoke.claimDailyReward();

    if (claimed) {
      setDisabled(false);
      setTimeout(() => {
        app.ui.confetti.hide();
      }, 3000);
      if (state.unclaimed_rewards <= 1) {
        setDisabled(true);
        setTimeout(() => {
          app.ui.drawer.close();
        }, 3000);
      }
      app.ui.setClickerUIState({ btnDailyReward: ElementUIState.Complete });
    }
  };

  const getExtraClass = (index: number) => {
    // so if user has 5 days streak and 2 unclaimed rewards
    // from the row of 5 we show first 3 claimed and 2 last unclaimed
    // user can only claim one by one
    let availableForClaim = false;

    if (unclaimedRewardsStartIndex !== -1) {
      if (index > unclaimedRewardsStartIndex && index <= state.streak_days) {
        availableForClaim = true;
      }
    }

    // available but not claimed
    // green outline
    if (availableForClaim) {
      return 'unclaimed';
    }

    // upcoming rewards
    // gray outline
    if (index > state.streak_days) {
      return 'inactive';
    }
    return '';
  };

  const getExtraClaimClass = () => {
    if (!disabled && state.unclaimed_rewards) {
      return 'btn-unclaimed';
    }
    if (!state.unclaimed_rewards) {
      return 'btn-disabled';
    }
    return '';
  };

  return (
    <>
      <div className="drawer-dailyreward">
        <div className="drawer-dailyreward-header">
          <div className="drawer-dailyreward-icon">
            <img src={assets.earn_daily_reward} />
          </div>
          <div className="drawer-dailyreward-title">{t('daily_rewards')}</div>
          <div className="drawer-dailyreward-subtitle">
            {t('daily_reward_copy')}
          </div>
        </div>
        <div className="rewards">
          {DAILY_REWARDS.map((r, index) => {
            if (index === 0) {
              return null;
            }
            return (
              <div
                key={index}
                className={`reward-item ${getExtraClass(index)}`}
              >
                <div className="reward-days-counter">
                  {t('day')} {index}
                </div>
                <div className="coin-bg" />
                <img className="coin" src={assets.coin} />
                <div className="reward-amount">{largeIntegerToLetter(r)}</div>
              </div>
            );
          })}
        </div>

        <div className="footer-buttons">
          <div
            className={`btn btn-normal ${getExtraClaimClass()}`}
            onClick={onClaimClick}
          >
            {state.unclaimed_rewards ? t('claim') : t('come_back_tomorrow')}
          </div>
        </div>
      </div>
    </>
  );
};
