import { useEffect, useState } from 'react';
import { assets } from '../../../../assets/assets';
import { AppEvents, app, useAppUpdates } from '../../../../data/AppController';
import { ElementUIState } from '../../../../data/Controllers/UIController/UIController';
import { getUIStateClassName, msToFullTime } from '../../../../data/utils';
import {
  DAY_IN_MS,
  getTimeLeftTo24Hours,
  getTimeUntilNextDay,
} from '../../../../replicant/utils/time';
import './DailyMenu.scss';

export const DailyMenu = () => {
  useAppUpdates({
    id: 'DailyMenu/appMode',
    listener: app.attachEventListener(AppEvents.onAppModeChange),
  });

  const [fetchDailyReward, setReloadDailyReward] = useState(false);

  const onDailyRewardClick = () => {
    app.ui.drawer.show({ id: 'dailyReward' });
  };

  const onDailyCodeClick = () => {
    app.toggleDailyCodeActive();
  };

  const onDailyComboClick = () => {
    app.nav.goTo('MinePage');
  };

  const dailyRewardTimeLeft = getTimeLeftTo24Hours(
    app.state.last_reward_granted,
    app.now(),
  );

  if (!fetchDailyReward && dailyRewardTimeLeft <= 0) {
    setReloadDailyReward(true);
  }

  useEffect(() => {
    if (fetchDailyReward) {
      app.invoke.reloadDailyReward().then(() => {
        setReloadDailyReward(false);
      });
    }
  }, [fetchDailyReward]);

  const dailyRewardTime =
    dailyRewardTimeLeft > 0 ? msToFullTime(dailyRewardTimeLeft) : 'Ready!';

  return (
    <div className="daily-menu">
      <DailyButton
        image={assets.icon_daily_rewards}
        title="Daily Rewards"
        time={dailyRewardTime}
        onClick={onDailyRewardClick}
        uiState={app.ui.state.clicker.btnDailyReward}
      />
      <DailyButton
        image={assets.icon_daily_code}
        title="Daily Code"
        time={msToFullTime(getTimeUntilNextDay(app.now(), 'pst'))}
        onClick={onDailyCodeClick}
        active={app.morseCodeMode}
        uiState={app.ui.state.clicker.btnDailyCode}
        // uiState={ElementUIState.Complete} // todo carles: testing purposes
      />
      <DailyButton
        image={assets.icon_daily_combo}
        title="Daily Combo"
        time={msToFullTime(getTimeUntilNextDay(app.now(), 'utc'))}
        onClick={onDailyComboClick}
        uiState={app.ui.state.clicker.btnDailyCombo}
      />
    </div>
  );
};

interface Props {
  image: string;
  title: string;
  time: string;
  onClick: () => void;
  active?: boolean;
  uiState: ElementUIState;
}
const DailyButton = ({
  image,
  title,
  time,
  onClick,
  active,
  uiState,
}: Props) => {
  const stateStyle = getUIStateClassName(uiState);

  return (
    <div
      className={`daily-btn ${active && 'db-active'} ${stateStyle}`}
      onClick={onClick}
    >
      <div className="db-img">
        <img src={image} />
      </div>
      <div className="db-title">{title}</div>
      <div className="db-time">{time}</div>
      {uiState === ElementUIState.Complete && (
        <div className="db-complete">
          <img src={assets.icon_daily_green_tick} />
        </div>
      )}
    </div>
  );
};
