import { AppController } from '../../AppController';
import { EventListener } from '../../EventListener';
import { View } from '../../View';
import { waitFor } from '../../utils';
import { BasicModalOpts } from './UIController';

export enum ModalEvents {
  OnUpdate = 'OnUpdate',
}

export class ModalManager<T extends BasicModalOpts> extends EventListener {
  private modalOpts?: T;

  private modalQueue: T[] = [];

  private modalOpen: boolean = false;

  private getModalOpts = async () => this.modalOpts;

  private listeners: (() => void)[] = [];

  public view = new View('Modal', this.app, this.getModalOpts);

  constructor(private app: AppController) {
    super();
  }

  private nextModal = async () => {
    if (this.modalQueue.length > 0) {
      const nextOpts = this.modalQueue.shift();
      if (nextOpts) {
        this.openModal(nextOpts);
      }
    }
  };

  private openModal = async (opts: T) => {
    if (this.modalOpen) {
      await this.close();
    }
    this.modalOpts = opts;
    this.view.fetch();
    this.view.show(false);
    this.modalOpen = true;
    this.sendEvents(ModalEvents.OnUpdate);
  };

  show = (opts: T, force = false) => {
    if (force || (this.modalQueue.length === 0 && !this.modalOpen)) {
      this.openModal(opts);
    } else {
      // set for drawers
      const id = (opts as T & { id?: string }).id;
      // make sure we dont add duplicates to the queue again
      if (
        id &&
        this.modalQueue.find((m) => (m as T & { id?: string }).id === id)
      ) {
        return;
      }
      this.modalQueue.push(opts);
    }
  };

  close = async () => {
    this.view.hide(false); // do not clear data BEFORE transition out

    await waitFor(250); // wait for transition out

    this.view.clearData(); // clear data AFTER transition out
    await waitFor(100); // wait a little more to avoid any issues after clearing data

    this.listeners.forEach((l) => l());
    this.modalOpts?.onClose && this.modalOpts?.onClose();

    this.modalOpts = undefined;
    this.modalOpen = false;
    this.sendEvents(ModalEvents.OnUpdate);
    this.nextModal();
  };

  clear = () => {
    this.modalQueue = [];
    this.close();
  };

  updateOpts = (extraOpts: Partial<T>) => {
    const newOpts = {
      ...this.modalOpts,
      ...extraOpts,
    } as T;
    this.modalOpts = newOpts;
  };

  onAnyModalClose = (listener: () => void) => {
    if (!this.listeners.includes(listener)) {
      this.listeners.push(listener);
    }
  };

  setStyle = (style?: React.CSSProperties) => {
    this.view.setData({
      ...this.view.data,
      style: style,
    });
  };

  updateCurrentOpenModalView = (update: Partial<T>) => {
    this.view.setData({
      ...this.view.data,
      ...update,
    });
    this.sendEvents(ModalEvents.OnUpdate);
  };
}
