import './DrawerTiktokDetails.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/AppController';
import {
  displayPrice,
  formatPrice,
  largeIntegerToLetter,
} from '../../../../replicant/utils/numbers';
import { TradingChart } from '../../TradingTokenPage/Charts/TradingChart';
import {
  getMarketCap,
  getPriceBackThen,
  getValueChange,
  getStatsBackThen,
  getSupplyFromCurvePrice,
  TradingToken,
  getCurvePrice,
} from '../../../../replicant/features/offchainTrading/offchainTrading.getters';
import Big from 'big.js';
import { useEffect, useState } from 'react';
import { HOUR_IN_MS } from '../../../../replicant/utils/time';
import { tokenStatsSliceConfigs } from '../../../../replicant/features/offchainTrading/offchainTrading.ruleset';
import { TradingAbout } from '../../TradingTokenPage/TradingTokenTabs/TradingTokenTabsContent';
import { FeedMemeFooter } from '../TiktokFeedItem/FeedMemeFooter';

export const DrawerTiktokDetails = () => {
  const { t } = useTranslation();

  const token = app.ui.drawer.view.data?.props?.tokenData;
  if (!token) return null;

  const { offchainTokenId } = token;

  // todo: holdingStats does not seem to work. always returns 0 when testing with prod data
  const myToken = app.memes.getTokenState(offchainTokenId);
  // app.state.trading.offchainTokens[offchainTokenId];
  // const holdingStats = app.memes.trading.getOffchainTokenHoldingStats(
  //   offchainTokenId,
  //   myToken,
  // );

  const priceChange = 100 * (token.priceChange?.last24hours ?? 0);
  const color = priceChange === 0 ? 'white' : priceChange > 0 ? 'green' : 'red';
  const iconArrow =
    priceChange > 0 ? assets.arrow_up_green : assets.arrow_down_red;

  const [offchainTokenState, setOffchainTokenState] = useState<TradingToken>();
  useEffect(() => {
    app.memes.getToken(offchainTokenId, 'fetch').then((offchainTokenState) => {
      setOffchainTokenState(offchainTokenState);
    });
  }, [offchainTokenId]);

  const now = app.now();
  const then = now - 24 * HOUR_IN_MS;

  const supply = Big(offchainTokenState?.supply ?? 0);
  const marketCap = getMarketCap(supply);
  const price24hAgo = getPriceBackThen(
    offchainTokenState?.trends.hour24 || [],
    then,
  );
  const supply24hAgo = getSupplyFromCurvePrice(price24hAgo);
  const marketCap24hAgo = getMarketCap(supply24hAgo);
  const marketCapChange = 100 * getValueChange(marketCap, marketCap24hAgo);

  // using stats of the previous time interval to have latest, complete stats
  const stats = offchainTokenState?.stats?.hour24 || [];
  const statsSlice = getStatsBackThen(
    stats,
    now - tokenStatsSliceConfigs.hour24.interval,
  );
  const statsSlice24hAgo = getStatsBackThen(stats, then);

  const volumeChange =
    100 * getValueChange(Big(statsSlice.volume), Big(statsSlice24hAgo.volume));
  const holderChange =
    100 *
    getValueChange(
      Big(statsSlice.holderCount),
      Big(statsSlice24hAgo.holderCount),
    );

  const price = getCurvePrice(supply).toNumber();
  const holderCount = offchainTokenState?.holderCount ?? 0;
  const totalVolume = stats.reduce((totalVolume, statsSlice) => {
    if (statsSlice.time < then) {
      return totalVolume;
    }

    return totalVolume.add(statsSlice.volume);
  }, Big(0));

  // ------------------------------------------------------------
  const onMinimize = () => {
    app.ui.drawer.close();
  };

  const onTapPurchaseButton = (mode: 'buy' | 'sell') => {
    // first, set purchase mode
    app.memes.trading.setPurchaseMode(mode);

    // close details drawer, then open buy/sell drawer
    app.ui.drawer.show({
      id: 'drawerTiktokBuySell',
    });
  };

  // ------------------------------------------------------------

  return (
    <div className="drawer-tiktok-details">
      {/* header - left title and minimize button */}
      <div className="drawer-tiktok-details-header">
        <div className="drawer-tiktok-details-header-title">
          ${app.memes.currentMeme.token?.ticker} {t('tiktok_price_action')}
        </div>
        <div
          className="btn drawer-tiktok-details-header-close"
          onClick={onMinimize}
        >
          <img src={assets.button_arrow_down} />
        </div>
      </div>

      <div className="header-item">
        <div className="item">
          {/* item image */}
          <div className="image">
            <img src={token?.image} />
          </div>

          {/* item info */}
          <div className="info">
            {/* item name */}
            <div className="item-name">
              <div className="tickerName">{token?.ticker}</div>
              <div className="name">•</div>
              <div className="name">{token?.name}</div>
            </div>
            {/* item value */}
            <div className="value-area">
              <div className="price-container">
                <div className="coin">
                  <img src={assets.coin} />
                </div>
                <div className="label">{displayPrice(price)}</div>
              </div>
              <div className="percent-container">
                <div className={`arrow ${color}`}>
                  {priceChange !== 0 && <img src={iconArrow} />}
                </div>
                <div className={`percent ${color}`}>
                  {formatPrice(Math.round(priceChange * 10) / 10)}%
                </div>
              </div>
            </div>
            {/* --- */}
          </div>
        </div>

        {/* coming soon */}
        <div className="coming-soon">
          <div
            className="btn image"
            onClick={() => {
              app.ui.drawer.show({
                id: 'drawerTradingComingSoon',
                hideClose: true,
              });
            }}
          >
            <img src={assets.trading_price_area_icon} />
          </div>
        </div>
      </div>

      {/* trading chart */}
      {app.memes.currentMeme.token && (
        <TradingChart
          tokenState={app.memes.currentMeme.token}
          height={120} // 210
        />
      )}

      {/* drawer details boxes */}
      <div className="drawer-details-boxes">
        <DrawerDetailsBox
          title={t('tiktok_mcap')}
          value={largeIntegerToLetter(marketCap.toNumber())} // todo: how the hell do we achieve a SHORT number here?
          percent={marketCapChange}
        />
        <DrawerDetailsBox
          title={t('tiktok_24hvol')}
          value={largeIntegerToLetter(totalVolume.toNumber())} // todo: how the hell do we achieve a SHORT number here?
          percent={volumeChange}
        />
        <DrawerDetailsBox
          title={t('tiktok_holders')}
          value={holderCount.toLocaleString()}
          percent={holderChange}
        />
      </div>

      <div className="about">
        <TradingAbout />
      </div>

      {/* buy/sell buttons */}
      <FeedMemeFooter onTapPurchaseButton={onTapPurchaseButton} />
    </div>
  );
};

// ====================================================================
// Drawer Details boxes

interface DrawerDetailsBoxProps {
  title: string;
  value: string;
  percent: number;
}

const DrawerDetailsBox = ({ title, value, percent }: DrawerDetailsBoxProps) => {
  const color = percent !== 0 ? (percent > 0 ? 'green' : 'red') : 'white';

  return (
    <div className={`drawer-details-box`}>
      <div className="value-area">
        <div className="title">{title}</div>

        <div className="value">{value}</div>

        <div className="drawer-details-box-percent">
          {Boolean(percent) && (
            <div className={`arrow ${color}`}>
              <img
                src={
                  percent > 0
                    ? assets.arrow_up_green
                    : assets.arrow_down_red_bright
                }
              />
            </div>
          )}
          <div className={`percent ${color}`}>
            {formatPrice(Math.round(percent * 10) / 10)}%{' '}
          </div>
        </div>
      </div>
    </div>
  );
};
