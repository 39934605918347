import './TradingCreatePage.scss';
import { useTranslation } from 'react-i18next';
import { app, useAppUpdates } from '../../../data/AppController';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { TradingCreateInput } from './TradingCreateInput/TradingCreateInput';
import { Page } from '../Page';
import { TradingTokenImageUploader } from './TradingTokenImageUploader/TradingTokenImageUploader';
import { fileToB64 } from '../../../data/utils';
import { FormEvents } from '../../../data/Form';

const targetFields = ['name', 'ticker', 'description'];
export const TradingCreatePage = () => {
  const { t } = useTranslation();
  const { visible } = app.views.TradingCreatePage;

  useAppUpdates({
    id: 'TradingCreatePage',
    listener: app.views.TradingCreatePage.attachEventListener(),
  });
  useAppUpdates({
    id: 'TradingCreatePage/FormEvents.OnUpdate',
    listener: app.memes.factory.newTokenForm.attachEventListener(
      FormEvents.OnUpdate,
    ),
  });

  const formConfig = useMemo(() => {
    return app.memes.factory.newTokenForm.fields.filter((f) =>
      targetFields.includes(f.key),
    );
  }, []);

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    // react will reload the page for some reason when submitting a form
    // this will prevent it to happen
    event.preventDefault();

    // close the keyboard
    // inputRef.current?.blur();
  };

  const [previewCounter] = useState(0);
  const [offchainTokenImage, setOffchainTokenImage] = useState<File>();

  useEffect(() => {}, []);

  const onInputChange =
    (key: (typeof app.memes.factory.newTokenForm.fields)[0]['key']) =>
    (value: string) => {
      app.memes.factory.newTokenForm.setField(key, value);
    };

  useEffect(() => {
    if (offchainTokenImage) {
      fileToB64(offchainTokenImage).then((img) => {
        app.memes.factory.newTokenForm.setField('image', img);
      });
      app.memes.factory.newTokenForm.validateForm();
    } else {
      app.memes.factory.newTokenForm.setField('image', '');
    }
  }, [offchainTokenImage]);

  const onContinue = async () => {
    if (!app.memes.factory.newTokenForm.isValid) {
      return;
    }
    app.nav.goTo('TradingCreateLinksPage');
  };

  if (!visible) {
    return null;
  }

  return (
    <Page id="trading-create" visible={visible}>
      <div className="trading-create-layout">
        <div className="trading-create-header">
          {/* user portrait - camera */}
          <div className="trading-create-image">
            <TradingTokenImageUploader
              onFileChange={setOffchainTokenImage}
              uploadState={previewCounter}
              preview={app.memes.factory.newTokenForm.data.image}
            />
          </div>
        </div>

        {/* form with several inputs */}
        <form onSubmit={onFormSubmit}>
          {formConfig.map((cfg, index) => {
            return (
              <TradingCreateInput
                key={index}
                title={cfg.title}
                placeholder={cfg.placeholder || ''}
                value={app.memes.factory.newTokenForm.data[cfg.key]}
                onChange={onInputChange(cfg.key)}
                isValid={app.memes.factory.newTokenForm.getIsFieldValid(
                  cfg.key,
                )}
                required={cfg.required}
              />
            );
          })}
        </form>

        <div
          className={`btn btn-normal button-continue ${
            !app.memes.factory.newTokenForm.isValid && 'disabled'
          }`}
          onClick={onContinue}
        >
          {t('trading_create_continue')}
        </div>
      </div>
    </Page>
  );
};
